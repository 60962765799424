import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IOnlineReportHorseInfo} from 'OnlineReport/models/shared/IOnlineReportHorseInfo';
import {IUpdateGeneticNote} from 'Admin/AdminDashboard/models/IUpdateGeneticNote';
import {IRequestChangeReviewStatus} from 'Admin/AdminDashboard/store/adminOrders/types';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {IOnlineReportCoatColor} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColor';
import {IOnlineReportCoatColorDetailed} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColorDetailed';
import {IOnlineReportHealthVariants} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariants';
import {IServerOnlineReportHealthVariants} from 'OnlineReport/services/models/IOnlineReportHealthVariantsServer';
import {convertServerHealthVariantsToClient} from 'OnlineReport/services/converters/healthVariant';
import {IOnlineReportHealthVariantDetailed} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariantDetailed';
import {IOnlineReportAbility} from 'OnlineReport/models/PerformanceAndAbilities/IOnlineReportAbility';
import {IOnlineReportAbilityDetailed} from 'OnlineReport/models/PerformanceAndAbilities/IOnlineReportAbilityDetailed';
import {IOnlineReportHorseDetails} from 'OnlineReport/models/shared/IOnlineReportHorseDetails';
import {IRequiredTest} from '../models/IRequiredTest';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import {getApiByReportType, getApiParamsByReportType} from 'OnlineReport/helpers/getApiByReportType';
import {IGeneticNote} from 'OnlineReport/models/Summary/IGeneticNote';
import {IOnlineReportSummary} from 'OnlineReport/models/Summary/IOnlineReportSummary';
import {IOnlineReportGeneticVariants} from 'OnlineReport/models/Summary/IOnlineReportGenotype';
import {withImage} from 'Common/helpers/withImage';
import {IServerHorseOwner} from './types/owner';
import {convertServerHorseOwnerToClient} from './converters/owner';
import {IServerOnlineReportHorseDetails} from 'OnlineReport/services/models/IServerOnlineReportHorseDetails';
import {convertOnlineReportHorseDetails} from 'OnlineReport/services/converters/horse';
import {withAvatar} from 'Common/helpers/withAvatar';

type AdminReportType =
  | OnlineReportType.Admin
  | OnlineReportType.AdminAssociation
  | OnlineReportType.ReviewAdmin
  | OnlineReportType.ReviewAdminAssociation;
type ApiByType = Record<AdminReportType, string>;

type AdminOnlyReportType = OnlineReportType.Admin | OnlineReportType.AdminAssociation;
type AdminApiByType = Record<AdminOnlyReportType, string>;

type ReviewReportType = OnlineReportType.ReviewAdmin | OnlineReportType.ReviewAdminAssociation;
type ReviewApiByType = Record<ReviewReportType, string>;
type ReviewParamsByType = Record<ReviewReportType, {}>;

const getSummaryMethod = async (
  animalId: number,
  orderId?: number,
  postfix?: string
): Promise<IOnlineReportSummary> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReport/Summary/${postfix}/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/Summary/${postfix}/${animalId}`,
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReportReview/Summary/${postfix}`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReportReview/Summary/${postfix}/${orderId}`,
  };

  const api = getApiByReportType(apis);

  const paramsByType: ReviewParamsByType = {
    [OnlineReportType.ReviewAdmin]: {animalId: animalId, orderId},
    [OnlineReportType.ReviewAdminAssociation]: {},
  };

  const apiParams = getApiParamsByReportType(paramsByType);

  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportSummary>>(api, apiParams);
  return result.data.data;
};

const getReviewSummaryMethod = async (
  animalId: number,
  orderId: number,
  postfix: string
): Promise<IOnlineReportSummary> => {
  const apis: AdminApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReportReview/Summary/${postfix}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReportReview/Summary/${postfix}`,
  };
  const api = getApiByReportType(apis);

  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportSummary>>(api, {
    params: {animalId: animalId, orderId},
  });
  return result.data.data;
};

const getHorseInfo = async (animalId: number): Promise<IOnlineReportHorseInfo> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReport/Horse/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/Horse/${animalId}`,
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReport/Horse/${animalId}`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReport/Horse/${animalId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportHorseInfo>>(api);
  return withAvatar(result.data.data);
};

const getHorseOwner = async (animalId: number): Promise<string> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReport/Owner/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/Owner/${animalId}`,
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReport/Owner/${animalId}`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReport/Owner/${animalId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IObjectResponse<IServerHorseOwner>>(api);
  return convertServerHorseOwnerToClient(result.data.data);
};

const getHorseDetails = async (animalId: number): Promise<IOnlineReportHorseDetails> => {
  const apis: AdminApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReport/HorseDetails/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/HorseDetails/${animalId}`,
  };
  const api = getApiByReportType(apis);

  const response = await axiosWrapper.get<IObjectResponse<IServerOnlineReportHorseDetails>>(api);
  return convertOnlineReportHorseDetails(response.data.data);
};

const getSummaryColors = async (animalId: number, orderId?: number): Promise<IOnlineReportSummary> => {
  return getSummaryMethod(animalId, orderId, 'Colors');
};

const getSummaryAbilities = async (animalId: number, orderId?: number): Promise<IOnlineReportSummary> => {
  return getSummaryMethod(animalId, orderId, 'Abilities');
};

const getSummaryHealthIssues = async (animalId: number, orderId?: number): Promise<IOnlineReportSummary> => {
  return getSummaryMethod(animalId, orderId, 'HealthIssues');
};

const getReviewSummaryColors = async (animalId: number, orderId: number): Promise<IOnlineReportSummary> => {
  return getReviewSummaryMethod(animalId, orderId, 'Colors');
};

const getReviewSummaryAbilities = async (animalId: number, orderId: number): Promise<IOnlineReportSummary> => {
  return getReviewSummaryMethod(animalId, orderId, 'Abilities');
};

const getReviewSummaryHealthIssues = async (animalId: number, orderId: number): Promise<IOnlineReportSummary> => {
  return getReviewSummaryMethod(animalId, orderId, 'HealthIssues');
};

const getGeneticVariants = async (animalId: number, orderId?: number): Promise<IOnlineReportGeneticVariants> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReport/GeneticVariants/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/GeneticVariants/${animalId}`,
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReportReview/GeneticVariants`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReportReview/GeneticVariants/${orderId}`,
  };
  const api = getApiByReportType(apis);

  const paramsByType: ReviewParamsByType = {
    [OnlineReportType.ReviewAdmin]: {animalId: animalId, orderId},
    [OnlineReportType.ReviewAdminAssociation]: {},
  };
  const apiParams = getApiParamsByReportType(paramsByType);
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportGeneticVariants>>(api, apiParams);
  return result.data.data;
};

const getHorseBreedPanel = async (animalId: number, orderId?: number): Promise<IHorseBreedPanel[]> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReport/Panels/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/Panels/${animalId}`,
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReportReview/Panels`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReportReview/Panels/${orderId}`,
  };
  const api = getApiByReportType(apis);

  const paramsByType: ReviewParamsByType = {
    [OnlineReportType.ReviewAdmin]: {animalId, orderId},
    [OnlineReportType.ReviewAdminAssociation]: {},
  };
  const apiParams = getApiParamsByReportType(paramsByType);

  const response = await axiosWrapper.get<IListResponse<IHorseBreedPanel>>(api, apiParams);
  return response.data.data;
};

const getRequiredTests = async (orderId: number, animalId: number): Promise<IRequiredTest[]> => {
  const apis: ReviewApiByType = {
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReportReview/RequiredTests`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReportReview/RequiredTests/${orderId}`,
  };
  const api = getApiByReportType(apis);

  const paramsByType: ReviewParamsByType = {
    [OnlineReportType.ReviewAdmin]: {animalId, orderId},
    [OnlineReportType.ReviewAdminAssociation]: {},
  };
  const apiParams = getApiParamsByReportType(paramsByType);

  const result = await axiosWrapper.get<IListResponse<IRequiredTest>>(api, apiParams);
  return result.data.data;
};

const getGeneticNotes = async (animalId: number): Promise<IGeneticNote[]> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReport/GeneticNotes/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/GeneticNotes/${animalId}`,
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReportReview/GeneticNotes/${animalId}`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReportReview/GeneticNotes/${animalId}`,
  };
  const api = getApiByReportType(apis);

  const result = await axiosWrapper.get<IListResponse<IGeneticNote>>(api);
  return result.data.data;
};

const updateGeneticNote = async (data: IUpdateGeneticNote): Promise<number> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReport/GeneticNotes`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/GeneticNotes`,
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReport/GeneticNotes`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReport/GeneticNotes`,
  };
  const api = getApiByReportType(apis);

  const result = await axiosWrapper.post<IObjectResponse<number>>(api, data);
  return result.data.data;
};

const deleteGeneticNote = async (noteId: number): Promise<void> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReport/GeneticNotes`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/GeneticNotes`,
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReport/GeneticNotes`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReport/GeneticNotes`,
  };
  const api = getApiByReportType(apis);

  await axiosWrapper.delete<IObjectResponse<null>>(api, {data: {noteId}});
};

const changeReviewStatus = async (data: IRequestChangeReviewStatus): Promise<void> => {
  const apis: ReviewApiByType = {
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReportReview/Status`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReportReview/Status`,
  };
  const api = getApiByReportType(apis);

  await axiosWrapper.post<IObjectResponse<null>>(api, data);
};

const getCoatColor = async (animalId: number, orderId?: number): Promise<IOnlineReportCoatColor[]> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReport/PartialColors/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/PartialColors/${animalId}`,
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReportReview/PartialColors/`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReportReview/PartialColors/${orderId}`,
  };
  const api = getApiByReportType(apis);

  const paramsByType: ReviewParamsByType = {
    [OnlineReportType.ReviewAdmin]: {animalId: animalId, orderId},
    [OnlineReportType.ReviewAdminAssociation]: {},
  };
  const apiParams = getApiParamsByReportType(paramsByType);

  const result = await axiosWrapper.get<IListResponse<IOnlineReportCoatColor>>(api, apiParams);
  return result.data.data.map(withImage);
};

const getCoatColorDetailed = async (colorId: number): Promise<IOnlineReportCoatColorDetailed> => {
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportCoatColorDetailed>>(
    `/OnlineReport/PartialColorDetails/${colorId}`
  );
  return result.data.data;
};

const getHealthVariants = async (animalId: number, orderId?: number): Promise<IOnlineReportHealthVariants[]> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReport/HealthIssues/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/HealthIssues/${animalId}`,
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReportReview/HealthIssues/`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReportReview/HealthIssues/${orderId}`,
  };
  const api = getApiByReportType(apis);

  const paramsByType: ReviewParamsByType = {
    [OnlineReportType.ReviewAdmin]: {animalId: animalId, orderId},
    [OnlineReportType.ReviewAdminAssociation]: {},
  };
  const apiParams = getApiParamsByReportType(paramsByType);

  const result = await axiosWrapper.get<IListResponse<IServerOnlineReportHealthVariants>>(api, apiParams);
  return result.data.data.map(convertServerHealthVariantsToClient);
};

const getHealthVariantDetailed = async (
  healthIssueId: number,
  isAggregated: boolean
): Promise<IOnlineReportHealthVariantDetailed> => {
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportHealthVariantDetailed>>(
    !isAggregated
      ? `/OnlineReportHealthIssues/${healthIssueId}`
      : `/OnlineReportHealthIssueAggregators/${healthIssueId}`
  );
  return result.data.data;
};

const getAbilities = async (animalId: number, orderId?: number): Promise<IOnlineReportAbility[]> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/Admin/OnlineReport/Abilities/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/Abilities/${animalId}`,
    [OnlineReportType.ReviewAdmin]: `/Admin/OnlineReportReview/Abilities`,
    [OnlineReportType.ReviewAdminAssociation]: `/Admin/AssociationOnlineReportReview/Abilities/${orderId}`,
  };
  const api = getApiByReportType(apis);

  const paramsByType: ReviewParamsByType = {
    [OnlineReportType.ReviewAdmin]: {animalId: animalId, orderId},
    [OnlineReportType.ReviewAdminAssociation]: {},
  };
  const apiParams = getApiParamsByReportType(paramsByType);

  const result = await axiosWrapper.get<IListResponse<IOnlineReportAbility>>(api, apiParams);
  return result.data.data.map(withImage);
};

const getAbilityDetailed = async (abilityId: number, isAggregated: boolean): Promise<IOnlineReportAbilityDetailed> => {
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportAbilityDetailed>>(
    !isAggregated ? `/OnlineReportAbilities/${abilityId}` : `/OnlineReportAbilityAggregators/${abilityId}`
  );
  return result.data.data;
};

export default {
  getHorseInfo,
  getHorseOwner,
  getHorseDetails,
  getSummaryColors,
  getSummaryAbilities,
  getSummaryHealthIssues,
  getCoatColor,
  getCoatColorDetailed,
  getHealthVariants,
  getHealthVariantDetailed,
  getAbilities,
  getAbilityDetailed,
  getReviewSummaryColors,
  getReviewSummaryAbilities,
  getReviewSummaryHealthIssues,
  getRequiredTests,
  getGeneticNotes,
  updateGeneticNote,
  deleteGeneticNote,
  changeReviewStatus,
  getHorseBreedPanel,
  getGeneticVariants,
};
