import {convertToFormData} from 'Common/helpers/convertToFormData';
import {IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';

const uploadDogDiagnosticsCsv = async (file: File): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(
    `/AnimalDiagnosticsReportDataUploadAdmin`,
    convertToFormData(file, 'diagnosticsFile')
  );
};

export default {
  uploadDogDiagnosticsCsv,
};
