export enum BreedType {
  CarriageHorse = 'carriageHorse',
  Exmoor = 'exmoor',
  HeavyHorse = 'heavyHorse',
  Iberian = 'iberian',
  NearEast = 'nearEast',
  NorthSea = 'northSea',
  Thoroughbred = 'thoroughbred',
}

export enum DogBreedType {
  Dingolike = 'Dingo like',
  FoundationStockServiceFSS = 'Foundation Stock Service (FSS)',
  HerdingGroup = 'Herding Group',
  HoundGroup = 'Hound Group',
  MiscellaneousClass = 'Miscellaneous Class',
  NonSportingGroup = 'Non-Sporting Group',
  Scenthound = 'Scent hound',
  SportingGroup = 'Sporting Group',
  TerrierGroup = 'Terrier Group',
  ToyGroup = 'Toy Group',
  Wolflike = 'Wolf like',
  WorkingGroup = 'Working Group',
}

export const MAP_BREED_COLOR: Record<BreedType, string> = {
  [BreedType.CarriageHorse]: '#74241a',
  [BreedType.Exmoor]: '#dc8343',
  [BreedType.HeavyHorse]: '#804aba',
  [BreedType.Iberian]: '#d7a440',
  [BreedType.NearEast]: '#d43b2a',
  [BreedType.NorthSea]: '#5aad1f',
  [BreedType.Thoroughbred]: '#0a2974',
};

export const MAP_DOG_BREED_COLOR: Record<DogBreedType, string> = {
  [DogBreedType.Dingolike]: '#74241a',
  [DogBreedType.FoundationStockServiceFSS]: '#dc8343',
  [DogBreedType.HerdingGroup]: '#804aba',
  [DogBreedType.HoundGroup]: '#d7a440',
  [DogBreedType.MiscellaneousClass]: '#d43b2a',
  [DogBreedType.NonSportingGroup]: '#5aad1f',
  [DogBreedType.Scenthound]: '#0a2974',
  [DogBreedType.SportingGroup]: '#74241a',
  [DogBreedType.TerrierGroup]: '#dc8343',
  [DogBreedType.ToyGroup]: '#804aba',
  [DogBreedType.Wolflike]: '#d7a440',
  [DogBreedType.WorkingGroup]: '#d43b2a',
};

export const MAP_BREED_NAME: Record<BreedType, string> = {
  [BreedType.CarriageHorse]: 'Carriage Horse',
  [BreedType.Exmoor]: 'Exmoor',
  [BreedType.HeavyHorse]: 'European Heavy Horse',
  [BreedType.Iberian]: 'Iberian',
  [BreedType.NearEast]: 'Near East',
  [BreedType.NorthSea]: 'North Sea',
  [BreedType.Thoroughbred]: 'Thoroughbred',
};

export const MAP_BREED_COUNTRIES: Record<BreedType, string[]> = {
  [BreedType.CarriageHorse]: ['FR', 'US'],
  [BreedType.Exmoor]: ['GB'],
  [BreedType.HeavyHorse]: [
    'FR',
    'GB',
    'DE',
    'BE',
    'NL',
    'LU',
    'AT',
    'CZ',
    'SK',
    'HU',
    'RO',
    'CH',
    'IT',
    'SI',
    'HR',
    'BA',
    'PL',
    'DK',
  ],
  [BreedType.Iberian]: ['ES', 'PT'],
  [BreedType.NearEast]: ['OM', 'AE', 'QA', 'SA', 'IQ', 'IR', 'KW', 'SY', 'JO', 'IL', 'PS', 'LB', 'EG', 'TR', 'YE'],
  [BreedType.NorthSea]: ['LU', 'NO', 'SE', 'FI', 'IS', 'FO', 'GB'],
  [BreedType.Thoroughbred]: ['GB', 'IE'],
};

export const MAP_DOG_BREED_COUNTRIES: Record<any, string[]> = {
  [DogBreedType.Dingolike]: ['FR'],
  [DogBreedType.FoundationStockServiceFSS]: ['SA'],
  [DogBreedType.HerdingGroup]: ['ES', 'PT'],
  [DogBreedType.HoundGroup]: ['EG'],
  [DogBreedType.MiscellaneousClass]: ['NO'],
  [DogBreedType.NonSportingGroup]: ['GB'],
  [DogBreedType.Scenthound]: ['US'],
  [DogBreedType.SportingGroup]: ['FR'],
  [DogBreedType.TerrierGroup]: ['DE'],
  [DogBreedType.ToyGroup]: ['IT'],
  [DogBreedType.Wolflike]: ['SK'],
  [DogBreedType.WorkingGroup]: ['CA'],
};

export const SORT_BREED_NAME: Record<BreedType, number> = {
  [BreedType.HeavyHorse]: 0,
  [BreedType.Thoroughbred]: 1,
  [BreedType.Iberian]: 2,
  [BreedType.CarriageHorse]: 3,
  [BreedType.Exmoor]: 4,
  [BreedType.NorthSea]: 5,
  [BreedType.NearEast]: 6,
};

export const SORT_DOG_BREED_NAME: Record<DogBreedType, number> = {
  [DogBreedType.Dingolike]: 7,
  [DogBreedType.FoundationStockServiceFSS]: 8,
  [DogBreedType.HerdingGroup]: 9,
  [DogBreedType.HoundGroup]: 10,
  [DogBreedType.MiscellaneousClass]: 11,
  [DogBreedType.NonSportingGroup]: 12,
  [DogBreedType.Scenthound]: 13,
  [DogBreedType.SportingGroup]: 14,
  [DogBreedType.TerrierGroup]: 15,
  [DogBreedType.ToyGroup]: 16,
  [DogBreedType.Wolflike]: 17,
  [DogBreedType.WorkingGroup]: 18,
};
