import React, {memo, useEffect} from 'react';
import {Helmet} from 'react-helmet';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {ITab} from 'Common/components/Tabs/ITab';
import AdminTabs from 'Admin/common/AdminTabs';
import AdminContent from 'Admin/common/AdminContent';
import Orders from './Orders/Orders';
import Users from './Users/Users';
import Coupons from './Coupons/Coupons';
import Reports from './Reports/Reports';
import Horses from './Horses/Horses';
import Packages from './Packages/Packages';
import Subscriptions from './Subscriptions/Subscriptions';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import useDistributorStrategy from 'Common/helpers/strategy/useDistributorStrategy';
import PackageGroups from './PackageGroups/PackageGroups';
import HorseTransfer from './HorseTransfer/HorseTransfer';
import ActivationCodes from './ActivationCodes/ActivationCodes';
import Dogs from './Dogs/Dogs';

const basePath = '/admin';

const data: ITab[] = [
  {section: 'orders', title: 'Orders', Component: Orders},
  {section: 'animals', title: 'Animals', Component: Horses},
  {section: 'users', title: 'Users', Component: Users},
  {section: 'transfer', title: 'Transfer', Component: HorseTransfer},
  {section: 'activation-codes', title: 'Activation Codes', Component: ActivationCodes},
  {section: 'coupons', title: 'Coupons', Component: Coupons},
  {section: 'reports', title: 'Reports', Component: Reports},
  {section: 'packages', title: 'Packages', Component: Packages},
  {section: 'package-groups', title: 'Package Groups', Component: PackageGroups},
  {section: 'subscriptions', title: 'Subscriptions', Component: Subscriptions},
];

const AdminDashboardLayout = () => {
  const {setStrategy} = useDistributorStrategy();

  useEffect(() => {
    setStrategy(SelectedStrategy.Admin);
  }, [setStrategy]);

  return (
    <BaseLayout withoutPaddings={true} widthByContent={true} isBackButtonDenied={true}>
      <Helmet>
        <title>Admin dashboard</title>
      </Helmet>

      <AdminTabs tabs={data} basePath={basePath} />
      <AdminContent tabs={data} basePath={basePath} />
    </BaseLayout>
  );
};

export default memo(AdminDashboardLayout);
