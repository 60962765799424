import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {IAppState} from 'Common/store/IAppState';
import {Pagination} from 'Common/components/Controls';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import ColorPalette from 'Common/constants/ColorPalette';
import Loading from 'Loading/components/Loading';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminHorses';
import {IHorseAdmin} from 'Admin/AdminDashboard/models/Horse';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';

import HorseDetails from 'Admin/AdminDashboard/components/Horses/HorseDetails/HorseDetails';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import AvatarCellHorse from 'Admin/AdminDashboard/components/Orders/shared/AvatarCellHorse';
import {isAvailableForBah} from 'Common/helpers/isAvailableForBah';
import {useUpdateUserModal} from 'Admin/AdminDashboard/helpers/hooks/useUserActions/parts/useUpdateUserModal';
import {useHorseActions} from 'Admin/AdminDashboard/helpers/hooks/useHorseActions/useHorseActions';
import withHorseActions, {
  getWithHorseActionsModules,
  IWithHorseActionsProps,
} from 'Admin/AdminDashboard/helpers/withHorseActions';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import HorseActions from 'Admin/AdminDashboard/components/Horses/HorseActions';
import {HORSE_GRANTED_MARKED_ICON, USER_SUBSCRIPTION_MARKED_ICON} from 'Common/constants/avatarMarkedIcon';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';

const CONFIRM_DELETE_HORSE = 'Are you sure you want to remove the horse?';

const BreedsCount = styled.span`
  color: ${ColorPalette.gray18};
`;

const HorsesTable = styled.div`
  margin-top: 50px;
`;

const SearchSection = styled.div`
  display: grid;
  grid-template-columns: auto 168px;
  grid-gap: 50px;
`;

const formatAnimalType = (type?: string) => {
  if (!type) return 'Horse';
  return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
};

const renderBreedsCell = ({breeds}: IHorseAdmin) => {
  if (!breeds || !breeds[0]) {
    return null;
  }
  const otherBreeds = breeds.length - 1;
  return (
    <>
      <span>{breeds[0].name}</span>
      {!!otherBreeds && <BreedsCount>{` (${otherBreeds} more)`}</BreedsCount>}
    </>
  );
};

type IConnected = ConnectedProps<typeof connector>;

type IProps = IConnected & IWithHorseActionsProps;

function Horses(props: IProps) {
  const {
    horses,
    horsesLoading,
    horseDeleting,
    horseArchiving,
    getHorses,
    pagination,
    deleteHorse,
    setArchiveHorse,
    getHorseDetails,
    horseUpdating,
    horseCreating,
  } = props;

  const {params, selectedId, changeSorting, sorting, handlePageSelect, handleCountPerPage, deleteModal, searchBar} =
    useCommonAdminPageData<IHorseAdmin>({
      getItems: getHorses,
      deleteParams: {communication: horseDeleting, action: deleteHorse, confirmDescription: CONFIRM_DELETE_HORSE},
      searchBarPlaceholder: 'Search for animals by any keyword',
    });

  const updateHorses = useCallback(() => {
    getHorses(params);
    if (selectedId) {
      getHorseDetails(selectedId);
    }
  }, [params, getHorses, getHorseDetails, selectedId]);

  const {
    openUpdateHorseModal,
    openCreateHorseModal,
    archiveHorse,
    horseActionsModal,
    openLiveReport,
    openChangeSampleIdModal,
    geneticDataVisibilityModal,
    openGeneticDataVisibilityModal,
  } = useHorseActions({
    onSuccess: updateHorses,
    data: {
      archiveHorseAction: {action: setArchiveHorse, communication: horseArchiving},
      createHorseAction: {communication: horseCreating},
      editHorseAction: {communication: horseUpdating},
    },
  });

  const {openUpdateUserModal, updateUserModal} = useUpdateUserModal({onSuccess: updateHorses});

  const renderExpandContent = useCallback(
    ({id, sample, owner, animalType}: IHorseAdmin) => (
      <HorseDetails
        sampleId={sample?.id}
        horseId={id}
        ownerId={owner.id}
        onEdit={(id) => openUpdateHorseModal(id, animalType)}
        onEditSampleId={openChangeSampleIdModal}
        onSuccess={updateHorses}
      />
    ),
    [openChangeSampleIdModal, openUpdateHorseModal, updateHorses]
  );

  const isNeedDisableRow = useCallback((horse: IHorseAdmin) => horse.isArchived, []);

  const isLoading = horsesLoading.isRequesting || horseDeleting.isRequesting || horseArchiving.isRequesting;

  const isShowPagination = horses.length > 0;

  return (
    <AdminPageLayout>
      {deleteModal}
      {updateUserModal}
      {horseActionsModal}
      {geneticDataVisibilityModal}
      <SearchSection>
        {searchBar}
        <PrimaryButton onClick={openCreateHorseModal}>+ Add Animal</PrimaryButton>
      </SearchSection>
      <HorsesTable className="position-relative">
        {isLoading && <Loading />}
        <Table<IHorseAdmin>
          data={horses}
          rowKey="id"
          renderExpandContent={renderExpandContent}
          sorting={sorting}
          onChangeSorting={changeSorting}
          expandable={true}
          disableRow={isNeedDisableRow}
        >
          <Cell<IHorseAdmin> header="ID" dataKey="id" render={({id}) => `#${id}`} expandOnClick={true} width={100} />
          <Cell<IHorseAdmin>
            header="Type"
            dataKey="animalType"
            render={({animalType}) => formatAnimalType(animalType)}
            width={100}
          />
          <Cell<IHorseAdmin>
            header="Animal"
            dataKey="name"
            render={({name, id, avatar, availabilityCriteria, isArchived, sample, isGeneticDataHidden, animalType}) => (
              <AvatarCellHorse
                type={animalType?.toLowerCase() || 'horse'}
                onLabelClick={() => openUpdateHorseModal(id, animalType)}
                label={name}
                avatarUrl={avatar?.url}
                profileUrl={`/admin/horse/${id}`}
                isArchived={isArchived}
                isAvailableArchivedProfile={true}
                isDeleted={false}
                isMarkedIcon={isAvailableForBah(availabilityCriteria)}
                markedIcon={{...HORSE_GRANTED_MARKED_ICON, size: 18}}
                sampleId={sample?.sampleId}
                isGeneticDataHidden={isGeneticDataHidden}
              />
            )}
            width="25%"
          />
          <Cell<IHorseAdmin>
            header="Owner"
            dataKey="owner"
            render={({owner}) => (
              <AvatarCell
                type="owner"
                onLabelClick={() => openUpdateUserModal(owner.id)}
                label={owner.name}
                avatarUrl={owner.avatar?.url}
                profileUrl={`/admin/user/user-profile/${owner.id}/horses`}
                isMarkedIcon={owner.hasSubscription}
                markedIcon={USER_SUBSCRIPTION_MARKED_ICON}
                hasOnlineReportAccess={owner.hasOnlineReportAccess}
              />
            )}
            width="25%"
          />
          <Cell<IHorseAdmin> header="Breeds" render={renderBreedsCell} width="25%" />
          <Cell<IHorseAdmin>
            header="Actions"
            align={CellAlign.Right}
            render={(horse) => (
              <HorseActions
                horse={horse}
                ownerName={horse.owner.name}
                editHandler={(id) => openUpdateHorseModal(id, horse.animalType)}
                archiveHandler={archiveHorse}
                openLiveReportHandler={openLiveReport}
                editSampleIdHandler={openChangeSampleIdModal}
                editHorseDataVisibilityHandler={openGeneticDataVisibilityModal}
              />
            )}
            width={100}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </HorsesTable>
    </AdminPageLayout>
  );
}

const mapStateToProps = (state: IAppState) => ({
  pagination: selectors.selectPagination(state),
  horses: selectors.selectHorses(state),
  shortHorse: selectors.selectShortHorse(state),
  horsesLoading: selectors.selectCommunication(state, 'horsesLoading'),
});

const mapDispatchToProps = {
  getHorses: actions.getHorses,
  getHorseDetails: actions.getShortHorse,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(withHorseActions(Horses)), getWithHorseActionsModules());
