import React, {memo} from 'react';

import CheckboxField from 'Common/components/FormFields/CheckboxField';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {FieldHint} from 'Common/components/StyledComponents/StyledComponents';

const fieldHintStyles = {marginLeft: 32, marginBottom: 16};

interface IProps {
  disabled: boolean;
}

function Privacy(props: IProps) {
  const {disabled} = props;

  return (
    <>
      <FormControlContainer>
        <CheckboxField
          name="isPublicProfile"
          label="Enable diagnostic public profile"
          style={{marginBottom: 8}}
          disabled={disabled}
        />
        <FieldHint style={fieldHintStyles}>
          Enabling this option will allow other users to see this horse profile including the variant summary for
          diagnostic, health and coat color data. You may also share it using the link.
        </FieldHint>
        <CheckboxField
          name="isAncestryPublicProfile"
          label="Enable ancestry public profile"
          style={{marginBottom: 8}}
          disabled={disabled}
        />
        <FieldHint style={fieldHintStyles}>
          Ancestry profiles are public by default. Enabling this option will allow other users to see this horse's
          Ancestry profile. You may also share it by using the link.
        </FieldHint>
      </FormControlContainer>
    </>
  );
}

export default memo(Privacy);
