import React, {memo, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import ImgPlaceHolder from 'Icon/etalon-horse-logo.svg';
import HorseProfileForm from 'HorseProfile/components/HorseProfileForm/HorseProfileForm';
import {FormType} from 'Common/constants/FormType';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import {ICommunication} from 'Common/store/utils/communication/types';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {Actions} from './Actions';
import {getCurrentUrl} from 'Common/helpers/getCurrentUrl';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {breakpoints, size} from 'Common/constants/Breakpoints';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import {IAvailabilityCriteria} from 'Common/models/IAvailabilityCriteria';
import {isAvailableForBah} from 'Common/helpers/isAvailableForBah';
import {getHorseInfo} from 'HorseProfile/helpers/getHorseInfo';

import HorseGallery from '../HorseGallery/HorseGallery';
import UnavailableForBah from '../ProfileHeader/UnavailableForBah';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import FavoriteHorseIconButton from 'FavoriteHorse/components/FavoriteHorseIconButton/FavoriteHorseIconButton';
import BlurredImage from 'Common/components/BlurredImage/BlurredImage';
import ShadowContainer from 'Common/components/Containers/ShadowContainer';
import {IMediaResource, MediaType} from 'Common/models/IMediaResource';
import {getYouTubeThumbnailUrl} from 'Common/helpers/getYouTubeThumbnailUrl';
import VideoPlayer from 'Video/components/VideoPlayer';
import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {isYouTubeUrl} from 'Common/helpers/isYouTubeUrl';
import {IHorseProfile} from 'Horse/models/IHorseProfile';
import usePermissions from 'Permissions/hooks/usePermissions';
import {VisitorType} from 'Common/constants/VisitorType';
import {Nullable} from 'Common/types';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';
import {getHorseSharedProfileUrl} from '../../../../utils/getHorseSharedProfileUrl';
import {AnimalType} from 'Common/constants/AnimalType';

const ProfileImageWrapper = styled.div`
  height: auto;
  padding-bottom: 53%;
  overflow: hidden;

  @media ${breakpoints.sm} {
    border-radius: 10px;
  }
`;

const FindPartnerButton = styled(LinkButton)`
  align-self: start;
  width: 120px;
  font-size: ${Typography.size.size13};

  @media ${breakpoints.sm} {
    align-self: unset;
    margin-left: auto;
    width: 212px;
    font-size: ${Typography.size.size16};
  }
`;

const ActionsWrapper = styled.div<{isShownShadow?: boolean}>`
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 2;
`;

const BottomSection = styled.div<{isRowDirection: boolean}>`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  margin-top: 24px;

  ${(p) =>
    p.isRowDirection
      ? `
  flex-direction: row;
  align-items: flex-end;
  `
      : `flex-direction: column`}

  @media ${breakpoints.sm} {
    margin: 0;
    padding: 0;
    position: absolute;
    left: 34px;
    right: 24px;
    bottom: 30px;
  }
`;

const InfoWrapper = styled.div`
  overflow: hidden;
`;

const MainInfo = styled.div`
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size13};
  line-height: 18px;
  color: ${ColorPalette.white0};
  max-width: 250px;

  @media ${breakpoints.sm} {
    max-width: 300px;
    font-size: ${Typography.size.size20};
    line-height: 24px;
  }
`;

const HorseName = styled.h1`
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${ColorPalette.white0};
  max-width: 250px;

  @media ${breakpoints.sm} {
    max-height: 300px;
    max-width: 400px;
    font-size: ${Typography.size.size50};
    letter-spacing: -1.2px;
    line-height: 48px;
  }
`;

const ProfileHeaderBase = styled.div<{background?: string}>`
  width: 100%;
  height: 100%;
  z-index: 1;
  ${(prop) =>
    !!prop.background
      ? `
      background-image: 
      linear-gradient(360deg, ${ColorPalette.transparent4} 0%, ${ColorPalette.transparent1} 52.47%), 
      url('${prop.background}'); 
    `
      : `
      background-image: url(${ImgPlaceHolder});
      background-color: ${ColorPalette.gray3};
  `};
  background-position: center;
  background-repeat: no-repeat;
`;

const HorseProfileHeaderWrapper = styled(ProfileHeaderBase)<{background?: string}>`
  background-size: ${(prop) => (!!prop.background ? 'contain' : '80%')};
  @media ${breakpoints.sm} {
    border-radius: 10px;
  }
`;

const HorseGalleryWrapper = styled.div`
  margin-top: 28px;
`;

const Breed = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const UnavailableForBahWrapper = styled.div`
  @media (max-width: ${size.sm}px) {
    margin-top: 16px;
    padding: 8px 0;
    background: ${hexToRgba(ColorPalette.white0, 0.05)};
    box-shadow: 0px 6px 14px ${hexToRgba(ColorPalette.black0, 0.1)};
    border-radius: 10px;
  }
`;

const PlayerWrapper = styled.div`
  z-index: 2;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${hexToRgba(ColorPalette.black1, 0.3)};
  border-radius: 50%;
`;

interface IExternalProps {
  horse: IHorseProfile;
  horseMedia: Nullable<IMediaResource[]>;
  horseMediaLoading: ICommunication;
  horseLoading: ICommunication;
  isOwnHorse: boolean;
  hasAccessToBah: boolean;
  criteriaToBah: IAvailabilityCriteria;
  onSuccessEdit(): void;
  hideShare?: boolean;
}

function HorseProfileHeader(props: IExternalProps) {
  const {horse, horseMedia, horseMediaLoading, onSuccessEdit, isOwnHorse, hasAccessToBah, criteriaToBah, hideShare} =
    props;

  const {isMobile} = useMediaQuery();
  const {isVisitorType} = usePermissions();
  const {currentVisitorType} = useVisitorTypeService();

  const isSimpleUser = isVisitorType(VisitorType.User);

  const [selectedMedia, setSelectedMedia] = useState<IMediaResource | null>(null);

  const [isEditHorseOpen, setIsEditHorseOpen] = useState(false);
  const openEditModalHorse = useCallback(() => setIsEditHorseOpen(true), []);
  const closeEditModalHorse = useCallback(() => setIsEditHorseOpen(false), []);
  const onSuccessEditHorse = useCallback(() => {
    closeEditModalHorse();
    onSuccessEdit();
  }, [closeEditModalHorse, onSuccessEdit]);

  const [isPreviewVideoOpen, setIsPreviewVideoOpen] = useState(false);
  const openPreviewVideoModal = useCallback(() => setIsPreviewVideoOpen(true), []);
  const closePreviewVideoModal = useCallback(() => setIsPreviewVideoOpen(false), []);

  const horseImage = useMemo(() => {
    if (selectedMedia?.type === MediaType.Video) {
      const thumb = getYouTubeThumbnailUrl(selectedMedia.url);
      return thumb || '';
    }

    return selectedMedia?.url || horse.avatar?.url;
  }, [horse.avatar, selectedMedia]);

  const images = useMemo(
    () =>
      ([] as IMediaResource[])
        .concat(horse.avatar ? {id: 0, type: MediaType.Image, url: horse.avatar.url} : [])
        .concat(horseMedia || []),
    [horse.avatar, horseMedia]
  );

  const isDisplayGallery = horseMedia && horseMedia.length !== 0;
  const isVideoMedia = selectedMedia?.type === MediaType.Video;

  const {breed, age} = getHorseInfo(horse);
  const horseInfo = [horse.gender, age].filter(Boolean).join(', ');

  const isBahAvailable = isAvailableForBah(criteriaToBah);

  const renderFindPartnerButton = () => {
    if (!hasAccessToBah) {
      return null;
    }

    if (!isBahAvailable) {
      return (
        <UnavailableForBahWrapper>
          <UnavailableForBah criteria={criteriaToBah} />
        </UnavailableForBahWrapper>
      );
    }

    return (
      <FindPartnerButton size="small" to={`${PREFIX_VISITOR_TYPE[currentVisitorType]}/build-a-horse/${horse.id}`}>
        Find a partner
      </FindPartnerButton>
    );
  };

  const renderInfo = () => {
    return (
      <BottomSection isRowDirection={!isMobile || isBahAvailable}>
        <InfoWrapper>
          <HorseName>{horse.name}</HorseName>
          <MainInfo className="d-flex flex-wrap">
            {breed && <Breed>{`${breed},${Typography.symbols.whitespace}`}</Breed>}
            {horseInfo}
          </MainInfo>
        </InfoWrapper>
        {renderFindPartnerButton()}
      </BottomSection>
    );
  };
  const [hover, setHover] = useState<boolean>(false);

  const enableHover = useCallback(() => setHover(true), [setHover]);
  const disableHover = useCallback(() => setHover(false), [setHover]);
  const videoHeight = isMobile ? '200px' : isYouTubeUrl(selectedMedia?.url) ? '553px' : '100%';

  return (
    <div onMouseEnter={enableHover} onMouseLeave={disableHover}>
      <ModalWindow isOpen={isEditHorseOpen} onClose={closeEditModalHorse}>
        <HorseProfileForm
          type={FormType.edit}
          horseId={horse.id}
          onSuccess={onSuccessEditHorse}
          isAdmin={false}
          resetHorseAfterCloseModal={false}
        />
      </ModalWindow>

      <ModalWindow isOpen={isPreviewVideoOpen} onClose={closePreviewVideoModal} maxWidth="900px" isLightbox={true}>
        <VideoPlayer url={selectedMedia?.url} width="100%" height={videoHeight} />
      </ModalWindow>

      <ProfileImageWrapper className="position-relative">
        {horseImage && <BlurredImage className="position-absolute" src={horseImage} />}
        {isVideoMedia && (
          <PlayerWrapper className="position-absolute d-flex justify-content-center align-items-center">
            <IconButton
              name={IconName.PlayIconPreview}
              size={48}
              color={ColorPalette.white0}
              hoverColor={ColorPalette.gray1}
              fill={true}
              stroke={false}
              onClick={openPreviewVideoModal}
            />
          </PlayerWrapper>
        )}
        <HorseProfileHeaderWrapper className="position-absolute" background={horseImage}>
          <ShadowContainer isShowShadow={hover} position="Left" style={{paddingLeft: 24, height: 70}}>
            {isSimpleUser && <FavoriteHorseIconButton horse={horse} position="Left" isShowLabel={hover && !isMobile} />}
            {(isOwnHorse || horse.animalType === AnimalType.Dog) && (
              <ActionsWrapper>
                <Actions
                  editHandler={openEditModalHorse}
                  horseId={horse.id}
                  horseName={horse.name}
                  urlToCopy={getHorseSharedProfileUrl(horse.id)}
                  hideShare={hideShare || horse.animalType === AnimalType.Dog}
                />
              </ActionsWrapper>
            )}
            {!isMobile && renderInfo()}
          </ShadowContainer>
        </HorseProfileHeaderWrapper>
      </ProfileImageWrapper>
      {isDisplayGallery && (
        <HorseGalleryWrapper>
          <HorseGallery
            onSelect={setSelectedMedia}
            horseMedia={images}
            selectedUrl={selectedMedia?.url || horse.avatar?.url || null}
          />
        </HorseGalleryWrapper>
      )}
      {isMobile && <div className="position-relative">{renderInfo()}</div>}
      {horseMediaLoading.error && (getCommonErrors(horseMediaLoading.error) || 'IconedLoading gallery error')}
    </div>
  );
}

export default memo(HorseProfileHeader);
