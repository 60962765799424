import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IHorseReport, IUserHorseOrder} from 'Horse/models/IUserHorse';
import {IServerUserHorseOrderShort} from './types/horse';
import {convertHorseLastOrderToClient} from './converters/horse';
import {IShortAbility} from 'Dictionaries/models/IAbilities';
import {IGenotypeGroup} from 'Genotype/models/IGenotypeGroup';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {IHorseOwner} from 'Horse/models/IHorseOwner';
import {IOwnerHorse} from 'Shared/models/IOwnerHorse';
import {withImage} from 'Common/helpers/withImage';
import {IServerHorseOwner} from 'Horse/services/types/owner';
import {withUserName} from 'Common/helpers/withUserName';
import {withBucketBaseUrl} from 'Common/helpers/withBucketBaseUrl';
import {withAvatar} from 'Common/helpers/withAvatar';

const getHorseParantageReport = async (animalId: number): Promise<IHorseReport> => {
  const response = await axiosWrapper.get<IObjectResponse<IHorseReport>>(
    `/HorseParentageReportAssociation/${animalId}`
  );
  return withBucketBaseUrl(response.data.data);
};

const getHorseAbilities = async (animalId: number): Promise<IListResponse<IShortAbility>> => {
  const response = await axiosWrapper.get<IListResponse<IShortAbility>>(`/AssociationHorseAbilities/${animalId}`);
  return response.data;
};

const getHorseGenotypeGroups = async (animalId: number): Promise<IListResponse<IGenotypeGroup>> => {
  const response = await axiosWrapper.get<IListResponse<IGenotypeGroup>>(`/AssociationHorseGenotypeGroups/${animalId}`);
  return {...response.data, data: response.data.data.map(withImage)};
};

const getHorseBreedPanel = async (animalId: number): Promise<IListResponse<IHorseBreedPanel>> => {
  const response = await axiosWrapper.get<IListResponse<IHorseBreedPanel>>(`/AssociationHorsePanels/${animalId}`);
  return response.data;
};

const getHorseLastOrder = async (animalId: number): Promise<IUserHorseOrder> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerUserHorseOrderShort>>(
    `/AssociationHorseLastOrder/${animalId}`
  );
  return convertHorseLastOrderToClient(response.data.data);
};

const getHorseOwner = async (animalId: number, isAdmin?: boolean): Promise<IHorseOwner> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerHorseOwner>>(`/HorseOwnerAssociation/${animalId}`);
  return withUserName(withAvatar(response.data.data));
};

/**
 * Get owner horses
 * @param animalId
 * @returns
 */
const getOwnerHorses = async (animalId: number): Promise<IListResponse<IOwnerHorse>> => {
  const response = await axiosWrapper.get<IListResponse<IOwnerHorse>>(`/HorseProfileHorsesAssociation/${animalId}`);
  return {...response.data, data: response.data.data.map(withBirthDate)};
};

export default {
  getHorseAbilities,
  getHorseParantageReport,
  getHorseGenotypeGroups,
  getHorseBreedPanel,
  getHorseLastOrder,
  getHorseOwner,
  getOwnerHorses,
};
