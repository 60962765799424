import React, {memo} from 'react';
import {useFormikContext} from 'formik';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {TextAreaField} from 'Common/components/FormFields';
import {Hint} from 'Common/components/StyledComponents/StyledComponents';
import HorseMapField from 'Common/components/FormFields/HorseMapField/HorseMapField';
import {AnimalType} from '../../../../Common/constants/AnimalType';

const AdditionalInformation: React.FC = () => {
  const {values} = useFormikContext<{animalType: AnimalType}>();
  const isDog = values.animalType === AnimalType.Dog;

  return (
    <FormControlContainer>
      {/* Only show comments section for horses */}
      {!isDog && (
        <>
          <Hint>Additional information can appear in public profile if you choose to share</Hint>
          <TextAreaField name="comments" placeholder="Add any additional relevant information" memoized={true} />
        </>
      )}
    </FormControlContainer>
  );
};

export default memo(AdditionalInformation);
