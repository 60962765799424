import React, {useCallback, useMemo} from 'react';
import {FieldArray, FieldArrayRenderProps} from 'formik';
import styled from 'styled-components';
import {useFormikContext} from 'formik';

import {IOrderHorse} from 'Order/models/IOrderHorse';
import {IconName} from 'Icon/components/Icon';
import {SelectField} from 'Common/components/FormFields/index';
import {castToOption} from 'Common/helpers/OptionHelper';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {MutableField, MutableFieldsGroup} from 'Common/components/StyledComponents/StyledComponents';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';
import {sortByName} from 'Common/helpers/sortByName';
import {INamedEntity} from 'Common/models/INamedEntity';
import {IHorseOrder} from 'BusinessPortal/models/order/createOrder/IHorseOrder';
import {RequiredBy} from 'Common/types';

const initialHorseTest: RequiredBy<IHorseOrder, 'tests' | 'packages'> = {horseId: 0, packages: [], tests: []};

const AddHorseButton = styled(PrimaryButton)`
  margin-bottom: 24px;
`;

const Divider = styled.div`
  margin: 10px 0;
  border: 1px solid ${ColorPalette.white9};
`;

interface IProps {
  horses: IOrderHorse[];
  packages: INamedEntity[];
  orders: IHorseOrder[];
  tests: INamedEntity[];
}

const PackageSection = ({horses = [], packages = [], tests = [], orders}: IProps) => {
  const {values, setFieldValue} = useFormikContext<IFormValues>();

  const handleAddHorse = useCallback(() => {
    const newOrders = [...values.orders];
    newOrders.push({
      horseId: null,
      packages: [],
      tests: [],
    });
    setFieldValue('orders', newOrders);
  }, [setFieldValue, values.orders]);

  const handleRemoveHorse = useCallback(
    (index: number) => {
      const newOrders = [...values.orders];
      newOrders.splice(index, 1);
      setFieldValue('orders', newOrders);
    },
    [setFieldValue, values.orders]
  );

  const renderRemoveButton = useCallback((arrayHelper: FieldArrayRenderProps, index: number) => {
    return (
      <IconButton
        onClick={() => arrayHelper.remove(index)}
        name={IconName.Clear}
        color={ColorPalette.gray48}
        size={16}
        fill={true}
        stroke={false}
        hoverColor={ColorPalette.red7}
      />
    );
  }, []);

  const uniqueHorses = useMemo(() => {
    if (!horses || !orders) return [];

    return castToOption(
      horses
        .map((horse) => {
          const isUsed = orders.some((order) => order.horseId === horse.id);
          return isUsed ? {...horse, isInvisible: true} : horse;
        })
        .map((horse) => ({...horse, name: `${horse.name} (${horse.ownerName})`}))
        .sort(sortByName)
    );
  }, [horses, orders]);

  const packagesOptions = useMemo(() => castToOption(packages.sort(sortByName)), [packages]);
  const testsOptions = useMemo(() => castToOption(tests.sort(sortByName)), [tests]);

  return (
    <div>
      <FieldArray
        name="orders"
        render={(arrayHelper) => (
          <div className="flex-column">
            {orders.map((_, index, arr) => (
              <div className="d-flex flex-column" key={index}>
                <MutableFieldsGroup className="flex-row" indent="30px">
                  <MutableField width={100}>
                    <SelectField
                      isRequired={true}
                      name={`orders[${index}].horseId`}
                      options={uniqueHorses}
                      label="Horse"
                    />
                  </MutableField>
                  {arr.length > 1 && (
                    <MutableField width={5} className="align-self-center" style={{marginTop: 20}}>
                      <div>{renderRemoveButton(arrayHelper, index)}</div>
                    </MutableField>
                  )}
                </MutableFieldsGroup>
                <MutableField width={100}>
                  <SelectField
                    isRequired={true}
                    isMulti={true}
                    name={`orders[${index}].packages`}
                    options={packagesOptions}
                    label="Packages"
                  />
                </MutableField>
                <MutableField width={100}>
                  <SelectField
                    isMulti={true}
                    name={`orders[${index}].tests`}
                    options={testsOptions}
                    label="Additional Tests"
                  />
                </MutableField>
                <Divider />
              </div>
            ))}
            <AddHorseButton type="button" size="small" onClick={handleAddHorse}>
              Add horse
            </AddHorseButton>
          </div>
        )}
      />
    </div>
  );
};

export default PackageSection;
