import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IHorseAdmin, IHorseAdminDetails, IHorseDetails, IHorseFullInfo} from 'Admin/AdminDashboard/models/Horse';
import {IHorseRequestParams} from 'Horse/models/IHorseRequest';

import {convertHorseToRequest} from 'Horse/services/converters/horse';
import {convertHorseDetailsToClient, convertHorseToClient} from './converters/horse';
import {IServerHorseAdmin, IServerHorseDetailsAdmin} from 'Admin/AdminDashboard/services/types/horse';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {IHorseSample} from '../models/Order/IHorseSample';
import {withAvatar} from 'Common/helpers/withAvatar';

const getHorses = async (params?: IRequestParams): Promise<IListResponse<IHorseAdmin>> => {
  const response = await axiosWrapper.get<IListResponse<IServerHorseAdmin>>(`/AnimalsAdmin`, {params});
  return {...response.data, data: response.data.data.map(convertHorseToClient)};
};

const getShortHorse = async (id: number): Promise<IHorseDetails> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerHorseDetailsAdmin>>(`/AnimalsDetailsInfoAdmin/${id}`);
  return convertHorseDetailsToClient(response.data.data);
};

const getHorse = async (id: number): Promise<IHorseAdminDetails> => {
  const response = await axiosWrapper.get<IObjectResponse<IHorseAdminDetails>>(`/AnimalsDetailsAdmin/${id}`);
  return withAvatar(withBirthDate(response.data.data));
};

const createHorse = async (horseRequest: IHorseRequestParams): Promise<number> => {
  const response = await axiosWrapper.post<IObjectResponse<number>>(`/AnimalsCreateAdmin`, {
    ...convertHorseToRequest(horseRequest),
  });
  return response.data.data;
};

const updateHorse = async (horseRequest: IHorseRequestParams): Promise<void> => {
  await axiosWrapper.put<void>(`/AnimalsUpdateAdmin/`, convertHorseToRequest(horseRequest));
};

const deleteHorse = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/AnimalsDeleteAdmin`, {
    data: {id},
  });
};

const archiveHorse = async (id: number): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AnimalArchiveAdmin`, {id});
};

const restoreHorse = async (id: number): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AnimalRestoreAdmin`, {id});
};

const hideGeneticData = async (horseId: number, isHidden: boolean): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AnimalGeneticDataVisibilityAdmin`, {horseId, isHidden});
};

const updateSampleId = async (id: number, sampleId: string): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/SampleUpdateAdmin`, {id, sampleId});
};

const activateSampleId = async (sampleId: string): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/SampleActivateAdmin`, {sampleId});
};

const getHorseSamples = async (horseId: number): Promise<IListResponse<IHorseSample>> => {
  const response = await axiosWrapper.get<IListResponse<IHorseSample>>(`/SampleAdmin/${horseId}`);
  return response.data;
};

const createSample = async (sampleId: string, horseId: number): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/SampleCreateAdmin`, {sampleId, horseId});
};

const hideAncestryReport = async (horseId: number, isHidden: boolean): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AnimalAncestryReportVisibilityAdmin`, {horseId, isHidden});
};

export default {
  getHorses,
  getShortHorse,
  getHorse,
  createHorse,
  updateHorse,
  deleteHorse,
  archiveHorse,
  restoreHorse,
  hideGeneticData,
  updateSampleId,
  activateSampleId,
  getHorseSamples,
  createSample,
  hideAncestryReport,
};
