import {HeightLimits, HeightUnits} from 'HorseProfile/constants/HeightUnits';

export const isValidHeight = (height: number, heightUnit: HeightUnits): boolean => {
  if (!height || !heightUnit) return true;

  const limits = HeightLimits[heightUnit];
  if (!limits) return true;

  const {min, max} = limits;

  if (heightUnit === HeightUnits.Hands) {
    // For hands, check if the decimal part is valid (0.1, 0.2, or 0.3)
    const decimal = height % 1;
    const validDecimals = [0, 0.1, 0.2, 0.3];
    return height >= min && height <= max && validDecimals.includes(Number(decimal.toFixed(1)));
  }

  return height >= min && height <= max;
};
