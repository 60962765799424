import * as Yup from 'yup';

import {ICreateHorseBP} from 'BusinessPortal/models/horse/IHorseBP';
import {Gender} from 'Common/constants/Gender';
import {HeightLimits, HeightUnits} from 'HorseProfile/constants/HeightUnits';
import {Parentage} from 'HorseProfile/constants/Parentage';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {FRONTEND_DATE} from 'Common/constants/Date';
import {isValidDate} from 'Common/helpers/DateHelper';
import {IHeight} from 'Common/models/IHeight';
import {isValidHeight} from 'Common/helpers/HeightHelper';
import {IImage} from 'Image/models/IImage';
import {IAddressWithNullableField} from 'Common/models/IAddress';
import {ICoordinatedLocation} from 'Common/models/ICoordinatedLocation';
import {IWithHorseMapFieldProps} from 'Common/components/FormFields/HorseMapField/HorseMapField';
import {Nullable} from 'Common/types';
import {IRegistryRequest} from 'Common/models/IRegistryRequest';
import {IUpdateHorseParentBP} from 'BusinessPortal/models/horse/IHorseParentageBP';
import {IMediaResource} from 'Common/models/IMediaResource';

export type ICreateHorseBPFormValues = ICreateHorseBP &
  IWithHorseMapFieldProps & {avatar: Nullable<IImage>; gallery: IMediaResource[]};

export interface IFormValues extends Omit<ICreateHorseBPFormValues, 'ownerId' | 'profileType' | 'ancestryProfileType'> {
  ownerId?: number;
  ownerFirstName?: string;
  ownerLastName?: string;
  ownerEmail?: string;
  organizationId: number;
  isPublicProfile: boolean;
  isAncestryPublicProfile: boolean;
}

export const initialValue: IFormValues = {
  organizationId: 0,
  name: '',
  gender: Gender.Stallion,
  height: {
    height: 0,
    heightUnit: HeightUnits.Hands,
  },
  dateOfBirth: '',
  location: null,
  address: {
    country: '',
    state: '',
    city: '',
    street: '',
    zip: '',
  },
  disciplines: [],
  breeds: [],
  colors: [],
  markings: [],
  registries: [],
  parentage: [
    {name: '', parent: Parentage.Sire, registries: []},
    {name: '', parent: Parentage.Dam, registries: []},
  ],
  healthIssues: [],
  additionalInformation: '',
  participateInEtalonProjects: false,
  isPublicProfile: false,
  microchipNumber: '',
  passportNumber: '',
  registryNumber: '',
  horseAddress: '',
  isManualAddressMode: false,
  avatar: null,
  gallery: [],
  isAncestryPublicProfile: true,
};

const registryShape = Yup.array().of(
  Yup.object().shape<IRegistryRequest>({
    registrationNumber: Yup.string().nullable(),
    associationId: Yup.number().nullable(),
  })
);

const parentageShape = Yup.array().of(
  Yup.object().shape<IUpdateHorseParentBP>({
    registries: registryShape,
    name: Yup.string(),
    parent: Yup.mixed<Parentage>(),
  })
);

export const validationSchema = Yup.object().shape<IFormValues>({
  organizationId: Yup.number().required(REQUIRED_FIELD),
  ownerId: Yup.number().test('ownerId', REQUIRED_FIELD, function (value: number) {
    return (
      (!!value && value !== 0) ||
      (!!this.parent.ownerFirstName && !!this.parent.ownerLastName && !!this.parent.ownerEmail)
    );
  }),
  ownerFirstName: Yup.string().test('ownerFirstName', REQUIRED_FIELD, function (value: string) {
    return this.parent.ownerId || value;
  }),
  ownerLastName: Yup.string().test('ownerLastName', REQUIRED_FIELD, function (value: string) {
    return this.parent.ownerId || value;
  }),
  ownerEmail: Yup.string()
    .test('ownerEmail', REQUIRED_FIELD, function (value: string) {
      return this.parent.ownerId || value;
    })
    .email('Email not valid'),
  name: Yup.string().required(REQUIRED_FIELD),
  dateOfBirth: Yup.string()
    .nullable()
    .test('dateOfBirth', `Please enter a valid date: ${FRONTEND_DATE}`, function (value: string) {
      return value ? isValidDate(value, FRONTEND_DATE) : null;
    }),
  breeds: Yup.array().of(Yup.number()).required(REQUIRED_FIELD),
  colors: Yup.array().of(Yup.number()),
  disciplines: Yup.array().of(Yup.number()),
  markings: Yup.array().of(Yup.number()),
  gender: Yup.mixed<Gender>().required(REQUIRED_FIELD),
  height: Yup.object().shape<IHeight>({
    height: Yup.number().test('height checking', '', function (height: number) {
      if (height) {
        const currentUnitLimits = HeightLimits[this.parent.heightUnit];
        const baseErrorMessage = `'Height' must be between ${currentUnitLimits.min} and ${currentUnitLimits.max}. `;
        const additionalErrorMessage = `You entered ${height}. `;
        const additionalHandsErrorMessage = `Also the fractional part must be 1, 2 or 3. ${additionalErrorMessage}. `;

        const isValidHeightUnit = isValidHeight(height, this.parent.heightUnit);
        if (!isValidHeightUnit) {
          if (this.parent.heightUnit === HeightUnits.Hands) {
            return this.createError({message: baseErrorMessage + additionalHandsErrorMessage});
          }

          return this.createError({message: baseErrorMessage + additionalErrorMessage});
        }
      }

      return true;
    }),
    heightUnit: Yup.mixed<HeightUnits>(),
  }),
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
  microchipNumber: Yup.string(),
  passportNumber: Yup.string(),
  registryNumber: Yup.string(),
  address: Yup.object()
    .shape<IAddressWithNullableField>({
      country: Yup.string(),
      state: Yup.string(),
      city: Yup.string(),
      street: Yup.string(),
      zip: Yup.string(),
    })
    .nullable(),
  location: Yup.object()
    .shape<ICoordinatedLocation>({
      id: Yup.number(),
      latitude: Yup.number(),
      longitude: Yup.number(),
    })
    .nullable(),
  horseAddress: Yup.string().test('horseAddress', 'Unknown address', function () {
    return this.parent.address;
  }),
  isManualAddressMode: Yup.boolean(),
  registries: registryShape,
  parentage: parentageShape,
  additionalInformation: Yup.string(),
  healthIssues: Yup.array().of(Yup.number()),
  participateInEtalonProjects: Yup.boolean(),
  isPublicProfile: Yup.boolean(),
  gallery: Yup.array().of(Yup.mixed<IMediaResource>()),
  isAncestryPublicProfile: Yup.boolean(),
});
