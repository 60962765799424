import * as Yup from 'yup';

import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues {
  userId: number;
  animalId: number;
  providedFirstName: string;
  providedLastName: string;
  providedEmail: string;
  providedPhone: string;
  signature: string;
}

export const initialValue: IFormValues = {
  userId: 0,
  animalId: 0,
  providedFirstName: '',
  providedLastName: '',
  providedEmail: '',
  providedPhone: '',
  signature: '',
};

export const validationSchema = Yup.object().shape<IFormValues>({
  userId: Yup.number().required(REQUIRED_FIELD),
  animalId: Yup.number().required(REQUIRED_FIELD),
  providedFirstName: Yup.string().required(REQUIRED_FIELD),
  providedLastName: Yup.string().required(REQUIRED_FIELD),
  providedEmail: Yup.string().email('Email not valid').required(REQUIRED_FIELD),
  providedPhone: Yup.string().required(REQUIRED_FIELD),
  signature: Yup.string().required(REQUIRED_FIELD),
});
