import {IFormValues} from './validation';
import {IUploadTestReportRequest} from 'Admin/shared/models/IUploadTestReportRequest';

export function convertFormValues(values: IFormValues, horseId: number, orderId: number): IUploadTestReportRequest {
  const {uploadedFile, ...rest} = values;

  return {
    ...rest,
    animalId: horseId,
    orderId,
    uploadedFile: uploadedFile!,
  };
}
