import * as Yup from 'yup';

import {PaymentMethod} from 'Common/constants/PaymentMethod';
import {IHorseOrder} from 'BusinessPortal/models/order/createOrder/IHorseOrder';
import {ICreateOrderRequest} from 'Admin/AdminAssociations/models/Order/createOrder/ICreateOrderRequest';

import {CouponError} from 'BusinessPortal/services/constants/CouponError';
import {FieldSpecificErrors} from 'Common/models/FieldSpecificErrors';
import {IResponseError} from 'Common/models/IError';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {ITestSummaryRequest} from 'Admin/AdminAssociations/services/types/summary';
import {IUseAssociationCreateOrderDiscountFormValues} from 'Shared/components/AssociationCreateOrderDiscount/hooks/useAssociationCreateOrderDiscount';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues {
  organizationId: number | null;
  employeeId: number | null;
  paymentMethod: string;
  isPaid: boolean;
  sendEmailToUser: boolean;
  orders: Array<{
    horseId: number | null;
    packages: number[];
    tests: number[];
  }>;
  coupons: string[];
}

export const initialValues: IFormValues = {
  organizationId: null,
  employeeId: null,
  paymentMethod: '',
  isPaid: false,
  sendEmailToUser: true,
  orders: [],
  coupons: [],
};

export const validationSchema = Yup.object().shape({
  organizationId: Yup.number().required('Organization is required'),
  employeeId: Yup.number().required('Employee is required'),
  paymentMethod: Yup.string().required('Payment method is required'),
  orders: Yup.array().of(
    Yup.object().shape({
      horseId: Yup.number().required('Horse is required'),
      packages: Yup.array().of(Yup.number()),
      tests: Yup.array().of(Yup.number()),
    })
  ),
});

export const convertFormValuesToRequest = (values: IFormValues): ICreateOrderRequest => {
  return {
    organizationId: values.organizationId!,
    employeeId: values.employeeId!,
    paymentMethod: values.paymentMethod,
    isPaid: values.isPaid,
    sendEmailToUser: values.sendEmailToUser,
    orders: values.orders.map((order) => ({
      horseId: order.horseId!,
      packages: order.packages || [],
      tests: order.tests || [],
    })),
  };
};

export const convertTestSummaryToServer = (
  orders: IFormValues['orders'],
  coupons: IFormValues['coupons']
): Omit<ITestSummaryRequest, 'organizationId'> => {
  return {
    orders: orders.map((order) => ({
      horseId: order.horseId!,
      packages: order.packages || [],
      tests: order.tests || [],
    })),
    coupons: coupons || [],
  };
};

const couponErrors: Record<CouponError, string> = {
  [CouponError.EntityNotFound]: `Coupon not found.`,
  [CouponError.CannotBeAppliedCoupon]: `Coupon doesn't contain selected packages and can’t be used. You can use another coupon or change packages.`,
  [CouponError.CouponIsDisabled]: `Coupon is inactive.`,
  [CouponError.ExpiryCoupon]: `Coupon is expired.`,
};

const DEFAULT_COUPON_ERROR = 'Error on loading coupon.';

export function getCouponError(errorResponse: IResponseError): string {
  const error = errorResponse?.errors?.[0];
  if (error?.errorCode) {
    return couponErrors[error.errorCode] || DEFAULT_COUPON_ERROR;
  }
  return error?.description || DEFAULT_COUPON_ERROR;
}

export type ServerErrors = Record<string, string>;
export type FormErrors = IFormValues;

export function convertErrors(
  serverErrors: FieldSpecificErrors<ServerErrors> | undefined
): FieldSpecificErrors<IFormValues> {
  if (!serverErrors) {
    return {paymentMethod: '', orders: ''};
  }

  const convertedErrors = {};
  Object.keys(serverErrors).forEach((key) => {
    const newKey = key.replace('horses', 'orders');
    convertedErrors[newKey] = serverErrors[key];
  });

  return convertedErrors;
}
