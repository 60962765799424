import {action} from 'typesafe-actions';

import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {uploadDogDiagnosticsUploadActions, uploadDogDiagnosticsUploadActionTypes} from './types';
import DogDiagnosticsCsvUploadService from '../../services/DogDiagnosticsCsvUploadService';

export const uploadDogDiagnosticsUpload =
  (file: File): ActionResult<IAppState, void, uploadDogDiagnosticsUploadActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadDogDiagnosticsUploadActionTypes.REQUEST));
      await DogDiagnosticsCsvUploadService.uploadDogDiagnosticsCsv(file);
      dispatch(action(uploadDogDiagnosticsUploadActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadDogDiagnosticsUploadActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
