import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';
import {IUploadTestReportRequest} from 'Admin/shared/models/IUploadTestReportRequest';
import {convertToFormData} from 'Common/helpers/convertToFormData';

const uploadReport = async (params: IUploadTestReportRequest): Promise<void> => {
  const {orderId, animalId, isSendReport, uploadedFile, reportType} = params;

  const requestParams = convertToFormData(uploadedFile);
  requestParams.append('orderId', `${orderId}`);
  requestParams.append('animalId', `${animalId}`);
  requestParams.append('isSendReport', `${isSendReport}`);
  requestParams.append('reportType', `${reportType}`);

  await axiosWrapper.post<IObjectResponse<null>>('/AnimalReportsUpdateAdmin', requestParams);
};

const deleteReport = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>('/AnimalReportsDeleteAdmin', {
    data: {reportId: id},
  });
};

export default {
  uploadReport,
  deleteReport,
};
