import {selectors as userSelectors} from 'UserProfile/store/currentUser';
import {IAppState} from 'Common/store/IAppState';
import {SubmissionFormModule} from 'Order/store/submissionForm/submissionFormModule';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {Nullable} from 'Common/types';
import {actions as horseActions, selectors as horseSelectors} from 'Horse/store/horse';
import {actions as genotypesActions, selectors as genotypesSelectors} from 'Genotype/store/genotypeList';
import {actions as horseBreedPanelActions, selectors as horseBreedPanelSelectors} from 'BreedPanel/store';
import {HorseModule} from 'Horse/store/horse/horseModule';
import {GenotypesModule} from 'Genotype/store/genotypeList/GenotypesModule';
import {HorseBreedPanelsModule} from 'BreedPanel/store/horseBreedPanelsModule';
import {
  actions as adminAssociationHorseActions,
  AdminAssociationHorseModule,
  selectors as adminAssociationHorseSelectors,
} from 'Admin/AdminAssociations/store/adminHorses';
import {
  actions as adminAssociationHorseProfileActions,
  selectors as adminAssociationHorseProfileSelectors,
  AdminAssociationHorseProfileModule,
} from 'Admin/AdminAssociations/store/adminHorseProfile';
import {
  actions as businessPortalHorseActions,
  selectors as businessPortalHorseSelectors,
  BusinessPortalHorseModule,
} from 'BusinessPortal/store/horse';
import {
  actions as businessPortalHorseProfileActions,
  selectors as businessPortalHorseProfileSelectors,
  BusinessPortalHorseProfileModule,
} from 'BusinessPortal/store/horseProfile';
import {actions as galleryActions, selectors as gallerySelectors} from 'Gallery/store';
import {IHorseReport, IUserHorseOrder} from 'Horse/models/IUserHorse';
import {IGenotypeGroup} from 'Genotype/models/IGenotypeGroup';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {IShortAbility} from 'Dictionaries/models/IAbilities';
import {IHorse} from 'Horse/models/IHorse';
import {ProfileType} from 'Common/constants/ProfileType';
import {ICommunication} from 'Common/store/utils/communication';
import {IHorseDetailsBP} from 'BusinessPortal/models/horse/IHorseDetailsBP';
import {IHorseParentageBP} from 'BusinessPortal/models/horse/IHorseParentageBP';
import {IMediaResource} from 'Common/models/IMediaResource';
import {GalleryModule} from 'Gallery/store/galleryModule';
import {VisitorType} from 'Common/constants/VisitorType';

export interface IState {
  currentUserId: Nullable<number>;
  horse: Nullable<IHorse>;
  parantageReport: Nullable<IHorseReport>;
  abilities: IShortAbility[];
  genotypeGroups: IGenotypeGroup[];
  horseBreedPanels: IHorseBreedPanel[];
  horseLastOrder: Nullable<IUserHorseOrder>;

  horseDetails: Nullable<IHorseDetailsBP>;
  horseParentage: Nullable<IHorseParentageBP[]>;
  horseMedia: Nullable<IMediaResource[]>;

  horseMediaLoading: ICommunication;
  horseLastOrderLoading: ICommunication;
  horseLoading: ICommunication;
  genotypesLoading: ICommunication;
  abilitiesLoading: ICommunication;
  horseParantageReportLoading: ICommunication;
  horseBreedPanelLoading: ICommunication;
}

export interface IDispatch {
  getHorse(horseId: number): void;
  resetHorse(): void;

  getHorseDetails(horseId: number): void;
  getHorseMedia(horseId: number, isAdmin?: boolean): void;
  getHorseParentage(horseId: number): void;
  getHorseParantageReport(horseId: number): void;
  getHorseAbilities(horseId: number): void;
  getGenotypeGroups(horseId: number): void;
  getHorseBreedPanel(horseId: number): void;
  getHorseLastOrder(horseId: number): void;

  resetHorseDetails(): void;
  resetHorseMedia(): void;
  resetHorseParentage(): void;
  resetHorseParantageReport(): void;
  resetHorseAbilities(): void;
  resetGenotypeGroups(): void;
  resetHorseBreedPanel(): void;
  resetHorseLastOrder(): void;

  updateProfileType(type: {animalId: number; profileType: ProfileType}): void;
  updateAncestryProfileType(type: {animalId: number; profileType: ProfileType}): void;
  updateParentageProfileType(type: {animalId: number; profileType: ProfileType}): void;
}

const getHorseSelectors = (state: IAppState, isAdmin?: boolean): Nullable<IHorse> => {
  const {selectHorseDetails, selectHorseParentage} = isAdmin
    ? adminAssociationHorseSelectors
    : businessPortalHorseSelectors;

  const horseDetails = selectHorseDetails(state);
  const horseParentage = selectHorseParentage(state);

  if (!horseDetails) {
    return null;
  }

  return {
    ...horseDetails,
    height: horseDetails.height?.height || undefined,
    heightUnits: horseDetails.height?.heightUnit,
    location: horseDetails.location ? {...horseDetails.location, id: 0} : null,
    address: horseDetails.address || null,
    availabilityCriteria: horseDetails.availabilityCriteria,
    comments: '',
    commercialTypes: [],
    healthProfile: [],
    isArchived: false,
    isFavorite: false,
    isGeneticDataHidden: false,
    isAncestryReportHidden: false,
    order: null,
    packages: [],
    preclinicalNotes: null,
    profileType: horseDetails.profileType,
    registries: [],
    reports: [],
    temperament: 0,
    parentage: horseParentage?.map((i) => ({...i, horseId: 0})) || [],
    ancestryProfileType: horseDetails.ancestryProfileType,
    parentageProfileType: horseDetails.parentageProfileType,
  };
};

const associationEmployeeDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    currentUserId: userSelectors.selectCurrentAssociationId(state),
    horseDetails: businessPortalHorseSelectors.selectHorseDetails(state),
    horseParentage: null,
    horseLoading: businessPortalHorseSelectors.selectCommunication(state, 'horseDetailsLoading'),

    horse: getHorseSelectors(state),
    horseMedia: businessPortalHorseSelectors.selectHorseMedia(state),
    parantageReport: businessPortalHorseProfileSelectors.selectHorseParantageReport(state),
    abilities: businessPortalHorseProfileSelectors.selectHorseAbilities(state),
    genotypeGroups: businessPortalHorseProfileSelectors.selectHorseGenotypeGroups(state),
    horseBreedPanels: businessPortalHorseProfileSelectors.selectHorseBreedPanel(state),
    horseLastOrder: businessPortalHorseProfileSelectors.selectHorseLastOrder(state),

    horseMediaLoading: businessPortalHorseSelectors.selectCommunication(state, 'horseMediaLoading'),
    horseParantageReportLoading: businessPortalHorseProfileSelectors.selectCommunication(
      state,
      'horseParantageReportLoading'
    ),
    abilitiesLoading: businessPortalHorseProfileSelectors.selectCommunication(state, 'horseAbilitiesLoading'),
    genotypesLoading: businessPortalHorseProfileSelectors.selectCommunication(state, 'horseGenotypeGroupsLoading'),
    horseBreedPanelLoading: businessPortalHorseProfileSelectors.selectCommunication(state, 'horseBreedPanelLoading'),
    horseLastOrderLoading: businessPortalHorseProfileSelectors.selectCommunication(state, 'horseLastOrderLoading'),
  }),
  dispatch: {
    getHorse: () => console.log,
    resetHorse: () => console.log,

    updateProfileType: businessPortalHorseActions.updateHorseProfileType,
    updateAncestryProfileType: businessPortalHorseActions.updateHorseAncestryProfileType,
    updateParentageProfileType: businessPortalHorseActions.updateHorseParentageProfileType,
    getHorseDetails: businessPortalHorseActions.getHorseDetails,
    getHorseParentage: businessPortalHorseActions.getHorseParentage,
    getHorseMedia: businessPortalHorseActions.getHorseMedia,
    getHorseParantageReport: businessPortalHorseProfileActions.getHorseReports,
    getHorseAbilities: businessPortalHorseProfileActions.getHorseAbilities,
    getGenotypeGroups: businessPortalHorseProfileActions.getHorseGenotypeGroups,
    getHorseBreedPanel: businessPortalHorseProfileActions.getHorseBreedPanel,
    getHorseLastOrder: businessPortalHorseProfileActions.getHorseLastOrder,

    resetHorseDetails: businessPortalHorseActions.resetHorseDetails,
    resetHorseMedia: businessPortalHorseActions.resetHorseMedia,
    resetHorseParentage: businessPortalHorseActions.resetHorseParentage,
    resetGenotypeGroups: businessPortalHorseProfileActions.resetHorseGenotypeGroups,
    resetHorseParantageReport: businessPortalHorseProfileActions.resetHorseParantageReport,
    resetHorseAbilities: businessPortalHorseProfileActions.resetHorseAbilities,
    resetHorseBreedPanel: businessPortalHorseProfileActions.resetHorseBreedPanel,
    resetHorseLastOrder: businessPortalHorseProfileActions.resetHorseLastOrder,
  },
  modules: [BusinessPortalHorseModule, BusinessPortalHorseProfileModule, SubmissionFormModule],
};

const associationAdminEmployeeDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    currentUserId: null,
    horseDetails: null,
    horseParentage: null,
    horseLoading: adminAssociationHorseSelectors.selectCommunication(state, 'horseDetailsLoading'),

    horse: getHorseSelectors(state, true),
    horseMedia: adminAssociationHorseSelectors.selectHorseMedia(state),
    parantageReport: adminAssociationHorseProfileSelectors.selectHorseReports(state),
    abilities: adminAssociationHorseProfileSelectors.selectHorseAbilities(state),
    genotypeGroups: adminAssociationHorseProfileSelectors.selectHorseGenotypeGroups(state),
    horseBreedPanels: adminAssociationHorseProfileSelectors.selectHorseBreedPanel(state),
    horseLastOrder: adminAssociationHorseProfileSelectors.selectHorseLastOrder(state),

    horseMediaLoading: adminAssociationHorseSelectors.selectCommunication(state, 'horseMediaLoading'),
    horseParantageReportLoading: adminAssociationHorseProfileSelectors.selectCommunication(
      state,
      'horseParantageReportLoading'
    ),
    abilitiesLoading: adminAssociationHorseProfileSelectors.selectCommunication(state, 'horseAbilitiesLoading'),
    genotypesLoading: adminAssociationHorseProfileSelectors.selectCommunication(state, 'horseGenotypeGroupsLoading'),
    horseBreedPanelLoading: adminAssociationHorseProfileSelectors.selectCommunication(state, 'horseBreedPanelLoading'),
    horseLastOrderLoading: adminAssociationHorseProfileSelectors.selectCommunication(state, 'horseLastOrderLoading'),
  }),
  dispatch: {
    updateProfileType: () => console.log,
    getHorse: () => console.log,
    resetHorse: () => console.log,
    updateAncestryProfileType: () => console.log,
    updateParentageProfileType: () => console.log,

    getHorseDetails: adminAssociationHorseActions.getHorseDetails,
    getHorseMedia: adminAssociationHorseActions.getHorseMedia,
    getHorseParentage: adminAssociationHorseActions.getHorseParentage,
    getHorseParantageReport: adminAssociationHorseProfileActions.getHorseParantageReport,
    getHorseAbilities: adminAssociationHorseProfileActions.getHorseAbilities,
    getGenotypeGroups: adminAssociationHorseProfileActions.getHorseGenotypeGroups,
    getHorseBreedPanel: adminAssociationHorseProfileActions.getHorseBreedPanel,
    getHorseLastOrder: adminAssociationHorseProfileActions.getHorseLastOrder,

    resetHorseDetails: adminAssociationHorseActions.resetHorseDetails,
    resetHorseMedia: adminAssociationHorseActions.resetHorseMedia,
    resetHorseParentage: adminAssociationHorseActions.resetHorseParentage,
    resetGenotypeGroups: adminAssociationHorseProfileActions.resetHorseGenotypeGroups,
    resetHorseParantageReport: adminAssociationHorseProfileActions.resetHorseReports,
    resetHorseAbilities: adminAssociationHorseProfileActions.resetHorseAbilities,
    resetHorseBreedPanel: adminAssociationHorseProfileActions.resetHorseBreedPanel,
    resetHorseLastOrder: adminAssociationHorseProfileActions.resetHorseLastOrder,
  },
  modules: [AdminAssociationHorseModule, AdminAssociationHorseProfileModule, SubmissionFormModule],
};

const userDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    horseDetails: null,
    horseParentage: null,

    currentUserId: userSelectors.selectCurrentUserId(state),
    horse: horseSelectors.selectHorse(state),
    horseLoading: horseSelectors.selectCommunication(state, 'horseLoading'),
    horseMedia: gallerySelectors.selectGallery(state),
    parantageReport: horseSelectors.selectHorseParantageReport(state),
    abilities: horseSelectors.selectHorseAbilities(state),
    genotypeGroups: genotypesSelectors.selectGenotypes(state),
    horseBreedPanels: horseBreedPanelSelectors.selectHorseBreedPanels(state),
    horseLastOrder: horseSelectors.selectHorseLastOrder(state),
    horseMediaLoading: gallerySelectors.selectCommunication(state, 'galleryLoading'),
    horseParantageReportLoading: horseSelectors.selectCommunication(state, 'horseParantageReportLoading'),
    abilitiesLoading: horseSelectors.selectCommunication(state, 'horseAbilitiesLoading'),
    genotypesLoading: genotypesSelectors.selectCommunication(state, 'genotypeLoading'),
    horseBreedPanelLoading: horseBreedPanelSelectors.selectCommunication(state, 'horseBreedPanelsLoading'),
    horseLastOrderLoading: horseSelectors.selectCommunication(state, 'horseLastOrderLoading'),
  }),
  dispatch: {
    getHorseDetails: () => console.log,
    getHorseParentage: () => console.log,
    resetHorseDetails: () => console.log,
    resetHorseMedia: () => console.log,
    resetHorseParentage: () => console.log,
    resetHorseAbilities: () => console.log,
    resetHorseLastOrder: () => console.log,

    getHorse: horseActions.getHorse,
    resetHorse: horseActions.resetHorseResults,
    updateProfileType: horseActions.updateHorseProfileType,
    updateAncestryProfileType: horseActions.updateHorseAncestryProfileType,
    updateParentageProfileType: horseActions.updateHorseParentageProfileType,
    getHorseMedia: galleryActions.getGallery,
    getHorseParantageReport: horseActions.getHorseParantageReport,
    getHorseAbilities: horseActions.getHorseAbilities,
    getGenotypeGroups: genotypesActions.getGenotypes,
    getHorseBreedPanel: horseBreedPanelActions.getHorseBreedPanels,
    getHorseLastOrder: horseActions.getHorseLastOrder,
    resetGenotypeGroups: genotypesActions.resetGenotypesResults,
    resetHorseBreedPanel: horseBreedPanelActions.resetHorseBreedPanelsResults,
    resetHorseParantageReport: horseActions.resetHorseParantageReport,
  },
  modules: [HorseModule, GenotypesModule, SubmissionFormModule, HorseBreedPanelsModule, GalleryModule],
};

const adminDistributor: IDistributor<any, any> = {
  state: (state: IAppState) => ({
    ...userDistributor.state(state),
    reports: [],
  }),
  dispatch: {
    ...userDistributor.dispatch,
  },
  modules: userDistributor.modules,
};

export const horseProfileVisitorDistributor: Record<VisitorType, IDistributor<IState, IDispatch>> = {
  [VisitorType.Admin]: adminDistributor,
  [VisitorType.User]: userDistributor,
  [VisitorType.AssociationAdmin]: associationAdminEmployeeDistributor,
  [VisitorType.AssociationEmployee]: associationEmployeeDistributor,
};
