import React, {useCallback} from 'react';
import styled from 'styled-components';

import OrderHorse from 'Admin/AdminDashboard/components/Orders/OrderHorses/OrderHorse';
import useOnUpdateOrder from 'Admin/AdminDashboard/components/Orders/OrderHorses/useOnUpdateOrder';
import PriceDetails from 'Admin/AdminDashboard/components/Orders/OrdersDetails/PriceDetails';
import {useHorseModal} from 'Admin/AdminDashboard/helpers/hooks/useHorseActions/parts/useHorseModal';
import {IOrderHorseDetailsAdmin} from 'Admin/AdminDashboard/models/Order/IOrderHorseDetailsAdmin';
import {IOrderPriceSummaryAdmin} from 'Admin/AdminDashboard/models/Order/IOrderPriceSummaryAdmin';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {Cell, ITableProps, Table} from 'Common/components/Table/Table';
import {ExpandedRowStyles} from 'Common/components/Table/view/Row';
import {HORSE_GRANTED_MARKED_ICON} from 'Common/constants/avatarMarkedIcon';
import ColorPalette from 'Common/constants/ColorPalette';
import {isAvailableForBah} from 'Common/helpers/isAvailableForBah';
import {withCurrency} from 'Common/helpers/withCurrency';
import withDate from 'Common/helpers/withDate';
import AvatarCellHorse from '../shared/AvatarCellHorse';
import OrderMenuItem from './parts/OrderMenuItem';
import OrderStatusDetails from './parts/OrderStatusDetails';
import {IOrderActivationCode} from 'ActivationCode/models/IOrderActivationCode';
import {acStatusToBadgeColor} from 'Common/helpers/activationStatusToBadgeColor';

const expandedRowStyles: ExpandedRowStyles = {
  toggle: {backgroundColor: 'transparent', marginLeft: '60px'},
  expandedRow: {boxShadow: 'none'},
  content: {paddingLeft: '85px'},
};

const OwnerCell = styled.span`
  color: ${ColorPalette.gray32};
`;

const PriceDetailsSingleHorse = styled.div`
  margin-top: 28px;
`;

interface IProps {
  orderId: number;
  priceDetails?: IOrderPriceSummaryAdmin;
  horsesDetails: IOrderHorseDetailsAdmin[];
  createDate: string;
  withRightPadding?: boolean;
  tableProps?: Partial<ITableProps<IOrderHorseDetailsAdmin>>;
  isClearStatuses?: boolean;
}

function OrderHorses(props: IProps) {
  const {orderId, createDate, priceDetails, horsesDetails, withRightPadding, tableProps, isClearStatuses} = props;

  const {onUpdateOrder} = useOnUpdateOrder();

  const renderExpandContent = useCallback(
    (orderHorse: IOrderHorseDetailsAdmin) => {
      return (
        <OrderHorse
          horseDetails={orderHorse}
          orderId={orderId}
          ownerName={`${orderHorse.owner?.firstName} ${orderHorse.owner?.lastName}`}
        />
      );
    },
    [orderId]
  );

  const {updateHorseModal, openUpdateHorseModal} = useHorseModal({onSuccess: onUpdateOrder});

  if (horsesDetails.length === 1) {
    return (
      <>
        <div style={{...tableProps?.expandedRowStyles?.content}}>
          <OrderHorse
            horseDetails={horsesDetails[0]}
            orderId={orderId}
            ownerName={`${horsesDetails[0]?.owner?.firstName} ${horsesDetails[0]?.owner?.lastName}`}
            isClearStatuses={true}
          />
        </div>

        {priceDetails && (
          <PriceDetailsSingleHorse>
            <PriceDetails orderId={orderId} {...priceDetails} />
          </PriceDetailsSingleHorse>
        )}
      </>
    );
  }

  return (
    <>
      {updateHorseModal}
      <Table<IOrderHorseDetailsAdmin>
        expandable={true}
        renderExpandContent={renderExpandContent}
        isHideHeader={true}
        expandedRowStyles={expandedRowStyles}
        multipleExpand={true}
        {...tableProps}
        data={horsesDetails}
      >
        <Cell<IOrderHorseDetailsAdmin>
          width="10%"
          render={() => <span style={{wordBreak: 'break-word'}}>{withDate(createDate)}</span>}
        />
        <Cell<IOrderHorseDetailsAdmin>
          width="15%"
          render={({owner}) => <OwnerCell>{owner ? `${owner?.firstName} ${owner?.lastName}` : 'N/A'}</OwnerCell>}
        />
        <Cell<IOrderHorseDetailsAdmin>
          width="15%"
          render={({id, name, avatar, isArchived, isDeleted, availabilityCriteria, sample, isGeneticDataHidden}) =>
            !name ? (
              <AvatarCellHorse
                label="N/A"
                type="horse"
                isArchived={false}
                isDeleted={false}
                isGeneticDataHidden={false}
              />
            ) : (
              <AvatarCellHorse
                label={name}
                type="horse"
                profileUrl={`/admin/horse/${id}`}
                avatarUrl={avatar?.url}
                onLabelClick={() => openUpdateHorseModal(id)}
                isArchived={isArchived}
                isDeleted={isDeleted}
                isMarkedIcon={isAvailableForBah(availabilityCriteria)}
                markedIcon={HORSE_GRANTED_MARKED_ICON}
                sampleId={sample?.sampleId}
                isGeneticDataHidden={isGeneticDataHidden}
              />
            )
          }
        />
        <Cell<IOrderHorseDetailsAdmin>
          width="10%"
          render={({packagesDetails}) =>
            packagesDetails.packages.length > 1 ? (
              `${packagesDetails.packages.length} packages`
            ) : (
              <span style={{wordBreak: 'break-word'}}>{packagesDetails.packages[0]?.name}</span>
            )
          }
        />
        <Cell<IOrderHorseDetailsAdmin>
          width="15%"
          render={({packagesDetails}) =>
            packagesDetails.packages[0]?.activationCode?.code ? (
              <div className="d-flex align-items-center">
                <span className="m-0 mr-2 ">{packagesDetails.packages[0].activationCode?.code}</span>
                <div
                  className={`badge rounded-pill ${acStatusToBadgeColor(
                    packagesDetails.packages[0].activationCode?.status
                  )}`}
                  style={{height: 'fit-content'}}
                >
                  {packagesDetails.packages[0].activationCode?.status}
                </div>
              </div>
            ) : packagesDetails.packages.length > 1 ? (
              `${packagesDetails.packages.length} packages`
            ) : (
              packagesDetails.packages[0]?.name
            )
          }
        />
        <Cell<IOrderHorseDetailsAdmin>
          width="10%"
          render={({id}) => (
            <OrderStatusDetails
              orderId={orderId}
              horseId={id}
              onChange={onUpdateOrder}
              isClearStatuses={isClearStatuses}
            />
          )}
        />
        <Cell<IOrderHorseDetailsAdmin>
          width="10%"
          render={({packagesDetails}) => withCurrency(packagesDetails.totalPrice)}
          align={withRightPadding ? CellAlign.Left : CellAlign.Right}
        />
        <Cell<IOrderHorseDetailsAdmin>
          header="Actions"
          width="100px"
          align={CellAlign.Right}
          render={(horse) => <OrderMenuItem horse={horse} orderId={orderId} />}
        />
        {withRightPadding && (
          <Cell<IOrderHorseDetailsAdmin> align={CellAlign.Right} width="100px" render={() => <div />} />
        )}
      </Table>
      {priceDetails && <PriceDetails orderId={orderId} {...priceDetails} />}
    </>
  );
}

OrderHorses.defaultProps = {
  withRightPadding: false,
};

export default React.memo(OrderHorses);
