import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IOrderHorse} from 'Order/models/IOrderHorse';
import {IAdminCreateUserOrderRequest} from 'Admin/AdminDashboard/models/Order/IAdminCreateUserOrderRequest';
import {convertCreateUserOrderRequestToServer} from 'Order/services/converters/createUserOrder';
import {isAdminVisitor} from 'Common/helpers/isAdminVisitor';
import {withPrice} from 'Common/helpers/withPrice';
import {IOrderPackage} from '../models/Order/IOrderPackage';
import {IDog} from 'Dogs/models/IDog';

const createUserOrder = async (request: IAdminCreateUserOrderRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<void>>(`/OrdersCreateAdmin`, convertCreateUserOrderRequestToServer(request));
};

const getUserHorses = async (userId: number): Promise<IOrderHorse[]> => {
  const response = await axiosWrapper.get<IListResponse<IOrderHorse>>(
    isAdminVisitor() ? `/UserAnimalsAdmin/${userId}` : `/UserAnimals/${userId}`
  );
  return response.data.data || [];
};

const getUserDogs = async (userId: number): Promise<IDog[]> => {
  const response = await axiosWrapper.get<IListResponse<IDog>>(
    isAdminVisitor() ? `/UserAnimalsAdmin/${userId}?animalType=Dog` : `/UserDogs/${userId}`
  );
  return response.data.data || [];
};

const getOrderPackages = async (): Promise<IOrderPackage[]> => {
  const response = await axiosWrapper.get<IListResponse<IOrderPackage>>(`/OrderPackagesAdmin`);
  return response.data.data.map(withPrice);
};

export default {
  createUserOrder,
  getUserHorses,
  getUserDogs,
  getOrderPackages,
};
