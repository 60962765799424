import React, {memo, useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {IAppState} from 'Common/store/IAppState';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {Pagination} from 'Common/components/Controls';
import Loading from 'Loading/components/Loading';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminHorses';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAdminAssociationHorse} from 'Admin/AdminAssociations/models/IAdminAssociationHorse';
import Actions from 'Common/components/Actions/Actions';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import usePermissions from 'Permissions/hooks/usePermissions';
import {VisitorType} from 'Common/constants/VisitorType';
import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import {IMenuItemValued} from 'Common/models/IMenuItemValued';
import {AdminAssociationHorseModule} from 'Admin/AdminAssociations/store/adminHorses/adminAssociationHorseModule';
import {useAdminHorseModal} from 'Admin/AdminAssociations/helpers/hooks/useAdminHorseModal';
import {useAdminOwnerModal} from 'Admin/AdminAssociations/helpers/hooks/useAdminOwnerModal';
import {AdminAssociationOwnerModule} from 'Admin/AdminAssociations/store/adminOwners';
import AvatarCellHorse from 'Admin/AdminDashboard/components/Orders/shared/AvatarCellHorse';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared';
import {AdminAssociationOrdersModule} from 'Admin/AdminAssociations/store/adminOrders/adminAssociationOrdersModule';

const CONFIRM_DELETE_HORSE = 'Are you sure you want to remove the horse?';

const iconProps = {color: ColorPalette.red7};

enum HorseActionValue {
  EditSampleId = 'editSampleId',
  UpdateHorse = 'updateHorse',
  DeleteHorse = 'deleteHorse',
}

const menuItems: IMenuItemValued<HorseActionValue>[] = [
  {
    value: HorseActionValue.EditSampleId,
    label: 'Edit sample ID',
    icon: {name: IconName.PenOutline, ...iconProps},
  },
  {
    value: HorseActionValue.UpdateHorse,
    label: 'Edit Animal',
    icon: {name: IconName.Edit, ...iconProps},
  },
  {
    value: HorseActionValue.DeleteHorse,
    label: 'Delete Horse',
    icon: {name: IconName.Garbage, ...iconProps},
  },
];

const AddButton = styled(PrimaryButton)`
  height: 50px;
  margin-left: 55px;
`;

const TableWrapper = styled.div`
  margin-top: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

const Horses = (props: IConnected) => {
  const {
    horses,
    horsesLoading,
    getHorses,
    deleteHorse,
    horseDeleting,
    getHorseSample,
    horseSample,
    horseSampleLoading,
    pagination,
  } = props;

  const {isVisitorType} = usePermissions();

  const {
    params,
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    searchBar,
    deleteModal,
    openDeleteModal,
  } = useCommonAdminPageData<IAdminAssociationHorse>({
    getItems: getHorses,
    searchBarPlaceholder: 'Search for animals by any keyword',
    deleteParams: {communication: horseDeleting, confirmDescription: CONFIRM_DELETE_HORSE, action: deleteHorse},
  });

  const reloadAdminHorses = useCallback(() => {
    getHorses(params);
  }, [getHorses, params]);

  const {
    createAdminHorseModal,
    updateAdminHorseModal,
    openCreateAdminHorseModal,
    openUpdateAdminHorseModal,
    changeSampleFormModal,
    openEditSampleModal,
  } = useAdminHorseModal({
    sample: horseSample || undefined,
    onSuccess: reloadAdminHorses,
  });

  const {updateAdminOwnerModal, openUpdateAdminOwnerModal} = useAdminOwnerModal({
    onSuccess: reloadAdminHorses,
  });

  const openChangeHorseSample = useCallback(
    async (id: number) => {
      await getHorseSample(id);
      await openEditSampleModal();
    },
    [getHorseSample, openEditSampleModal]
  );

  const isLoading = horsesLoading.isRequesting || horseSampleLoading.isRequesting;
  const isAdminVisitor = isVisitorType(VisitorType.Admin);
  const isShowPagination = horses.length > 0;

  return (
    <AdminPageLayout>
      {createAdminHorseModal}
      {updateAdminHorseModal}
      {updateAdminOwnerModal}
      {changeSampleFormModal}
      {deleteModal}
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
        {isAdminVisitor && <AddButton onClick={openCreateAdminHorseModal}>+ Create Horse</AddButton>}
      </div>
      <TableWrapper className="position-relative">
        {isLoading && <Loading />}
        <Table<IAdminAssociationHorse> data={horses} rowKey="id" sorting={sorting} onChangeSorting={changeSorting}>
          <Cell<IAdminAssociationHorse>
            header="ID"
            dataKey="id"
            render={({id}) => `#${id}`}
            expandOnClick={true}
            width={100}
          />
          <Cell<IAdminAssociationHorse>
            header="Name"
            dataKey="name"
            render={({id, name, avatar, isArchived, isDeleted, sampleId}) => (
              <AvatarCellHorse
                type="horse"
                avatarUrl={avatar?.url}
                label={name}
                onLabelClick={() => openUpdateAdminHorseModal(id)}
                profileUrl={`/admin-associations/horse/${id}`}
                isArchived={isArchived}
                isDeleted={isDeleted}
                isGeneticDataHidden={false}
                sampleId={sampleId}
              />
            )}
            width="20%"
          />
          <Cell<IAdminAssociationHorse>
            header="Owner"
            dataKey="owner"
            render={({owner}) => (
              <AvatarCell
                type="owner"
                avatarUrl={owner.avatar?.url}
                profileUrl={`/admin-associations/user/user-profile/${owner.id}`}
                label={owner.name}
                onLabelClick={() => openUpdateAdminOwnerModal(owner.id)}
              />
            )}
            width="30%"
          />
          <Cell<IAdminAssociationHorse>
            header="Registry number"
            dataKey="registryNumber"
            render={({registryNumber}) => registryNumber}
            width="15%"
          />
          <Cell<IAdminAssociationHorse>
            header="Organization"
            dataKey="organization"
            render={({organization}) => {
              const abbreviation = organization?.abbreviation ? ` (${organization.abbreviation})` : '';
              return (
                <>
                  {organization?.name}
                  {abbreviation}
                </>
              );
            }}
            width="15%"
          />

          <Cell<IAdminAssociationHorse>
            header="Actions"
            align={CellAlign.Right}
            width={100}
            render={({id}) => (
              <Actions<HorseActionValue>
                menuItems={menuItems}
                editSampleId={() => openChangeHorseSample(id)}
                updateHorse={() => openUpdateAdminHorseModal(id)}
                deleteHorse={() => openDeleteModal(id)}
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </TableWrapper>
    </AdminPageLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  horseSample: selectors.selectHorseSample(state),
  horseSampleLoading: selectors.selectCommunication(state, 'horseSampleLoading'),
  horses: selectors.selectHorses(state),
  horsesLoading: selectors.selectCommunication(state, 'horsesLoading'),
  horseDeleting: selectors.selectCommunication(state, 'horseDeleting'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getHorseSample: actions.getHorseSample,
  getHorses: actions.getHorses,
  deleteHorse: actions.deleteHorse,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(Horses));
export default withDynamicModules(Connected, [
  AdminAssociationHorseModule,
  AdminAssociationOrdersModule,
  AdminAssociationOwnerModule,
]);
