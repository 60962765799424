import React, {memo} from 'react';
import styled from 'styled-components';

import {ProfileInfoActions} from 'Shared/components/UserProfile/parts/ProfileInfo/parts/ProfileInfoActions';
import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import {breakpoints} from 'Common/constants/Breakpoints';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {IUser} from 'UserProfile/models/IUser';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import StatisticsSection from 'Shared/components/UserProfile/parts/ProfileInfo/parts/StatisticsSection';
import {convertUserName} from 'Common/helpers/convertUserName';

const ActionsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  @media ${breakpoints.sm} {
    margin-right: 28px;
  }
`;

const Root = styled.div`
  padding-right: 40px;
  position: static;
  flex-direction: row;

  @media ${breakpoints.sm} {
    position: relative;
    flex-direction: column;
  }

  @media ${breakpoints.lg} {
    padding-right: 0;
  }
`;

const MainInfo = styled.div`
  align-self: start;

  @media ${breakpoints.sm} {
    align-items: center;
    align-self: center;
  }
`;

const AvatarWrapper = styled.div`
  margin-right: 9px;

  @media ${breakpoints.sm} {
    margin-right: 0;
  }
`;

const UserName = styled.div`
  text-align: left;
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${ColorPalette.black1};

  @media ${breakpoints.sm} {
    text-align: center;
    font-size: ${Typography.size.size32};
    line-height: 40px;
  }
`;

const UserStatus = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.gray44};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size20};
    line-height: 32px;
  }
`;

interface IProps {
  user: IUser;
  isCurrentUser: boolean;
}

const MainSection = (props: IProps) => {
  const {user, isCurrentUser} = props;
  const {isMobile} = useMediaQuery();

  const ownerName = user.name || convertUserName(user.firstName, user.lastName);

  return (
    <Root className="d-flex align-items-center">
      {isCurrentUser && (
        <ActionsWrapper>
          <ProfileInfoActions />
        </ActionsWrapper>
      )}
      <AvatarWrapper className="d-flex flex-row justify-content-center">
        <Avatar
          avatarSize={isMobile ? AvatarSize.Custom : AvatarSize.Big}
          customSizeAvatar={88}
          defaultAvatarProfile={DefaultAvatarProfile.User}
          url={user.avatar?.url}
        />
      </AvatarWrapper>
      <MainInfo className="d-flex flex-column">
        <UserName>{ownerName}</UserName>
        <UserStatus>Animal owner</UserStatus>
        {isMobile && isCurrentUser && (
          <StatisticsSection countHorses={user.countHorses} countVerifiedTests={user.countVerifiedTests} />
        )}
      </MainInfo>
    </Root>
  );
};

export default memo(MainSection);
