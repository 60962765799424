import {memo, useMemo} from 'react';
import {PieChart} from 'react-minimal-pie-chart';
import styled, {css} from 'styled-components';

import BlurredImage from 'Common/components/BlurredImage/BlurredImage';
import CommonLink from 'Common/components/Controls/Links/CommonLink';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {breakpoints} from 'Common/constants/Breakpoints';
import {ProfileType} from 'Common/constants/ProfileType';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import useIsImageExist from 'Common/helpers/hooks/useIsImageExist';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {Nullable} from 'Common/types';
import ColoredIcon from 'Icon/components/ColoredIcon';
import Icon, {IconName} from 'Icon/components/Icon';
import {IImage} from 'Image/models/IImage';
import {BreedType, DogBreedType, MAP_BREED_COLOR, MAP_DOG_BREED_COLOR} from 'OnlineReport/constants/BreedType';
import {sortBreeds} from 'OnlineReport/helpers/sortBreeds';
import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import ExampleHorse from '../../parts/common/img/example-horse.png';
import ContactButton from '../common/ContactRequest/ContactButton/ContactButton';
import CompositionPieLegend from './CompositionPieLegend';
import {IAncestryRelatedHorse} from 'OnlineReport/models/Ancestry/IAncestryRelatedHorse';
import Image from 'Common/components/Image/Image';

const getSize = (size: PieSize) => {
  switch (size) {
    case 'big':
      return '250px';
    case 'medium':
      return '200px';
    case 'small':
      return '150px';
    case 'smaller':
      return '120px';

    default:
      return '350px';
  }
};

const Root = styled.div`
  flex-direction: column;

  @media (min-width: 700px) {
    flex-direction: row;
  }
`;

const ChartRoot = styled.div<{size: PieSize}>`
  max-width: ${(props) => getSize(props.size)};
`;

const pieChartLabelStyle = {
  fontFamily: Theme.font.secondary,
  fontSize: '4px',
};

const PieWrapper = styled.div<{size: PieSize; scale: number}>`
  max-height: ${(props) => getSize(props.size)};
  max-width: ${(props) => getSize(props.size)};
  width: ${(props) => props.scale * 240 + 'px'};
  height: ${(props) => props.scale * 240 + 'px'};
`;

const PieImage = styled(Image)`
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 160px;
  user-select: none;
`;

const pieAvatarstyles = css`
  width: 57%;
  height: 57%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
`;

const PieAvatarImage = styled.div<{background?: string}>`
  background-size: contain;
  background-image: url(${(prop) => prop.background});
  background-position: center;
  background-repeat: no-repeat;

  ${pieAvatarstyles}
`;

const PieAvatarBlurred = styled(BlurredImage)`
  overflow: hidden;
  ${pieAvatarstyles}
`;

const LockIcon = styled(Icon)`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const TitleWrapper = styled.div<{size: PieSize}>`
  font-family: ${Theme.font.primary};
  margin-top: ${(props) => {
    switch (props.size) {
      case 'big':
        return '-20px';
      case 'medium':
      case 'small':
        return '-18px';
      case 'smaller':
        return '-16px';

      default:
        return '-30px';
    }
  }};
  z-index: 1;
  text-align: center;
`;

const Title = styled.div<{size: PieSize; titleSize?: PieSize}>`
  width: ${(props) => {
    switch (props.titleSize) {
      case 'big':
        return '280px';
      case 'medium':
        return '230px';
      case 'small':
        return '180px';
      case 'smaller':
        return '120px';

      default:
        return '280px';
    }
  }};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: ${Typography.weight.medium500};
  font-size: ${(props) => {
    switch (props.size) {
      case 'big':
        return Typography.size.size28;
      case 'medium':
        return Typography.size.size24;
      case 'small':
        return Typography.size.size20;
      case 'smaller':
        return Typography.size.size16;

      default:
        return Typography.size.size30;
    }
  }};
`;

const Details = styled.div<{size: PieSize}>`
  font-weight: ${Typography.weight.normal400};
  font-size: ${(props) => {
    switch (props.size) {
      case 'big':
        return Typography.size.size18;
      case 'medium':
        return Typography.size.size16;
      case 'small':
        return Typography.size.size14;
      case 'smaller':
        return Typography.size.size14;

      default:
        return Typography.size.size20;
    }
  }};
`;

export const ActionIconWrapper = styled.div<{size: string}>`
  top: ${(props) => {
    switch (props.size) {
      case 'big':
        return '28px';
      case 'medium':
        return '18px';
      case 'small':
        return '8px';
      case 'smaller':
        return '2px';

      default:
        return '8px';
    }
  }};

  right: ${(props) => {
    switch (props.size) {
      case 'big':
        return '28px';
      case 'medium':
        return '18px';
      case 'small':
        return '8px';
      case 'smaller':
        return '2px';

      default:
        return '8px';
    }
  }};
`;

const Numeration = styled(Title)`
  width: auto;
  top: 10px;
  left: 10px;
`;

const PrivateSticker = styled.span<{size?: string}>`
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size12};
  font-weight: ${Typography.weight.medium500};
  z-index: 1;
  bottom: 24px;
  right: -36px;
  bottom: ${(props) => {
    switch (props.size) {
      case 'big':
        return '34px';
      case 'medium':
        return '28px';
      case 'small':
        return '20px';
      case 'smaller':
        return '16px';

      default:
        return '8px';
    }
  }};
  right: ${(props) => {
    switch (props.size) {
      case 'big':
        return '28px';
      case 'medium':
        return '8px';
      case 'small':
        return '-16px';
      case 'smaller':
        return '-32px';

      default:
        return '8px';
    }
  }};
`;

const LegendWrapper = styled.div`
  margin-left: 16px;
`;

export type PieSize = 'default' | 'big' | 'medium' | 'small' | 'smaller';

interface IProps {
  population: IAncestryPopulation[];
  avatar?: Nullable<IImage>;
  title?: string;
  size?: PieSize;
  scale?: number;
  isNumerationShow?: boolean;
  currentNumber?: number;
  profileUrl?: string;
  isPrintable?: boolean;
  isShowLabels?: boolean;
  isShowLegend?: boolean;
  isShowLegendPercentage?: boolean;
  customIamge?: string;
  relatedHorse?: IAncestryRelatedHorse;
  titleSize?: PieSize;
}

const getHorseImageUrl = (url: string) => {
  if (url.endsWith('.max.jpg')) {
    // Use low-resolution image for better performance
    return url.replace('.max.jpg', '.min.jpg');
  }
  return url;
};

function CompositionPie(props: IProps) {
  const {
    avatar,
    population,
    title,
    currentNumber,
    profileUrl = '',
    size = 'default',
    scale = 1,
    isNumerationShow = false,
    isPrintable,
    isShowLabels = false,
    isShowLegend = false,
    isShowLegendPercentage = false,
    customIamge,
    relatedHorse,
    titleSize,
  } = props;

  const {isVisitorAdmin} = useVisitorTypeService();
  const avatarUrl = getHorseImageUrl(avatar?.url || '');
  const isAvatarExists = useIsImageExist(avatarUrl);

  const sortedPopulation = sortBreeds<IAncestryPopulation>(population);

  const pieChartData = sortedPopulation.map((item) => ({
    title: item.type,
    value: item.percentage,
    color: MAP_BREED_COLOR[item.type as BreedType] ?? MAP_DOG_BREED_COLOR[item.type as DogBreedType],
  }));

  const isAncestryPublicHorse = relatedHorse ? relatedHorse.ancestryProfileType === ProfileType.Public : true;
  const isOwnHorse = relatedHorse ? !!relatedHorse?.isOwned : true;

  const isPublicHorseOrVisitorAdmin = isAncestryPublicHorse || isVisitorAdmin || isOwnHorse;
  const isClicable = isPublicHorseOrVisitorAdmin && !!profileUrl;

  const percentDetail = relatedHorse?.percent ? `${(relatedHorse.percent * 100).toFixed(0)}% similar` : '';
  const horseName = isAncestryPublicHorse || isVisitorAdmin || isOwnHorse ? title : 'Private Account';

  const breedDetails =
    relatedHorse?.horseBreeds && relatedHorse.horseBreeds.length > 0
      ? relatedHorse.horseBreeds.slice(0, 2).join(', ')
      : '';
  const breedDetailsTooltip =
    relatedHorse?.horseBreeds && relatedHorse.horseBreeds.length > 0 ? relatedHorse.horseBreeds.join(', ') : '';

  const renderAvatar = useMemo(() => {
    return avatar && isAvatarExists ? (
      <>
        <PieAvatarBlurred className="position-absolute" src={avatarUrl} />
        {isPublicHorseOrVisitorAdmin && <PieAvatarImage className="position-absolute" background={avatarUrl} />}
      </>
    ) : (
      <>
        <PieAvatarBlurred className="position-absolute" src={ExampleHorse} />
        {isPublicHorseOrVisitorAdmin && <PieImage className="position-absolute w-50" src={ExampleHorse} />}
      </>
    );
  }, [avatar, isAvatarExists, isPublicHorseOrVisitorAdmin]);

  const renderCustomImage = useMemo(() => {
    return customIamge ? (
      <>
        <PieAvatarBlurred className="position-absolute" src={customIamge} />
        {isPublicHorseOrVisitorAdmin && <PieAvatarImage className="position-absolute" background={customIamge} />}
      </>
    ) : (
      ''
    );
  }, [customIamge, isPublicHorseOrVisitorAdmin]);

  return (
    <Root className="d-flex align-items-center">
      <ChartRoot className="d-flex flex-column align-items-center" size={size}>
        <PieWrapper className="position-relative" size={size} scale={scale}>
          <PieChart
            data={pieChartData}
            labelStyle={pieChartLabelStyle}
            rounded
            lineWidth={20}
            radius={36}
            labelPosition={120}
            label={
              isShowLabels
                ? ({x, y, dx, dy, dataEntry}) => (
                    <text
                      x={x}
                      y={y}
                      dx={dx}
                      dy={dy}
                      dominant-baseline="central"
                      text-anchor="middle"
                      style={pieChartLabelStyle}
                    >
                      {(Math.round((dataEntry.value + Number.EPSILON) * 10) / 10).toFixed(1)} %
                    </text>
                  )
                : undefined
            }
          />

          {isClicable ? <CommonLink to={profileUrl}>{renderAvatar}</CommonLink> : renderAvatar}
          {customIamge && renderCustomImage}

          {!isPublicHorseOrVisitorAdmin && (
            <LockIcon
              className="position-absolute"
              name={IconName.Lock}
              size={size === 'default' ? 80 : size === 'big' ? 60 : size === 'medium' ? 50 : size === 'small' ? 40 : 30}
            />
          )}

          {isNumerationShow && (
            <Numeration className="position-absolute" size={size} titleSize={titleSize || size}>
              #{currentNumber}
            </Numeration>
          )}

          {!isAncestryPublicHorse && !isVisitorAdmin && !isOwnHorse && !isPrintable && relatedHorse?.animalId && (
            <ActionIconWrapper className="position-absolute" size={size}>
              <ContactButton animalId={relatedHorse.animalId} />
            </ActionIconWrapper>
          )}

          {isClicable && !isPrintable && (
            <ActionIconWrapper className="position-absolute" size={size}>
              <CommonLink to={profileUrl}>
                <ColoredIcon name={IconName.Eye} size={30} color={Theme.color.primary} fill={true} stroke={false} />
              </CommonLink>
            </ActionIconWrapper>
          )}

          {isVisitorAdmin && !isAncestryPublicHorse && !isPrintable && (
            <PrivateSticker className="position-absolute badge badge-danger" size={size}>
              {/* <ColoredIcon name={IconName.Star} size={30} color={Theme.color.primary} fill={true} /> */}
              Private account
            </PrivateSticker>
          )}
        </PieWrapper>

        {title && (
          <TitleWrapper className="d-flex flex-column align-items-center" size={size}>
            <Tooltip content={horseName}>
              <Title size={size} titleSize={titleSize || size}>
                {horseName}
              </Title>
            </Tooltip>

            <Details size={size}>{percentDetail}</Details>

            {breedDetails && breedDetails.length > 0 && (
              <Details size={size}>
                <Tooltip content={breedDetailsTooltip}>
                  <div>{breedDetails}</div>
                </Tooltip>
              </Details>
            )}
          </TitleWrapper>
        )}
      </ChartRoot>

      {isShowLegend && (
        <LegendWrapper>
          <CompositionPieLegend population={sortedPopulation} isShowPercentage={isShowLegendPercentage} />
        </LegendWrapper>
      )}
    </Root>
  );
}

export default memo(CompositionPie);
