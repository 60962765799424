import React, {useCallback} from 'react';
import styled from 'styled-components';

import MapField from 'Common/components/FormFields/MapField';
import {InputField} from 'Common/components/FormFields';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {IFieldProps} from 'Common/models/IFieldProps';
import withField from 'Common/components/FormFields/withField';
import {IAddressWithNullableField} from 'Common/models/IAddress';
import {ILocation} from 'Common/models/ICoordinatedLocation';
import {Nullable} from 'Common/types';

export interface IWithHorseMapFieldProps {
  horseAddress?: string;
  address: Nullable<IAddressWithNullableField>;
  isManualAddressMode?: boolean;
  location: Nullable<ILocation>;
}

const MissingAddressButton = styled.div`
  width: fit-content;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 12px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.primary};
`;

type AllProps<T> = IFieldProps<T>;

function HorseMapField<T extends IWithHorseMapFieldProps>(props: AllProps<T>) {
  const {setFieldValue, values} = props.form;

  const onUnknownAddress = useCallback(() => {
    setFieldValue('isManualAddressMode', !values.isManualAddressMode);
  }, [setFieldValue, values.isManualAddressMode]);

  if (!values.isManualAddressMode) {
    return (
      <>
        <MapField
          name="horseAddress"
          componentsName="address"
          mapName="location"
          label="Horse location"
          memoized={true}
          autoComplete="off"
          placeholder="Start typing user location"
        />
        <MissingAddressButton onClick={onUnknownAddress}>Can’t find your address?</MissingAddressButton>
      </>
    );
  }

  return (
    <>
      <InputField name="address.street" label="Address" placeholder="Enter your address" />
      <InputField name="address.city" label="City" placeholder="City" />
      <InputField name="address.state" label="State" placeholder="State" />
      <InputField name="address.zip" label="ZIP / POSTAL" placeholder="ZIP / POSTAL" />
      <InputField name="address.country" label="Country" placeholder="Country" />
      <MissingAddressButton onClick={onUnknownAddress}>Back to the map view</MissingAddressButton>
    </>
  );
}

type ExternalProps<T> = Omit<AllProps<T>, keyof IFieldProps<T>>;

export default withField(HorseMapField) as <T extends IWithHorseMapFieldProps>(
  props: ExternalProps<T>
) => React.ReactElement<T>;
