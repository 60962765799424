import {IServerUserHorse} from '../types/horse';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {parseOrderStatus} from 'Order/helpers/parseOrderStatus';
import {convertPriceToClient} from 'Common/helpers/convertPrices';
import {convertToServerDate, convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {IHorseRequest, IHorseRequestParams, IParentRequest} from 'Horse/models/IHorseRequest';
import {IRegistryRequest} from 'Common/models/IRegistryRequest';
import {ProfileType} from 'Common/constants/ProfileType';

import {HeightUnits} from 'HorseProfile/constants/HeightUnits';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {withAvatar} from 'Common/helpers/withAvatar';
import {AnimalType} from '../../../Common/constants/AnimalType';

export function convertServerUserHorse(horse: IServerUserHorse): IUserHorse {
  return {
    ...withAvatar(withBirthDate(horse)),
    order: horse.order
      ? {
          ...parseOrderStatus(horse.order),
          id: horse.order.id,
          createDate: convertUTCToClientDate(horse.order.createDate) || '',
          amount: convertPriceToClient(horse.order.amount),
          packages: horse.order.packages,
          reports: horse.order.reports,
          token: horse.order.token,
          animalType: horse.animalType,
        }
      : null,
  };
}

export function convertHorseToRequest({horseId, fields}: IHorseRequestParams): IHorseRequest {
  const {
    parentage,
    registries,
    height,
    heightUnit,
    isPublicProfile,
    dateOfBirth,
    passportNumber,
    microchipNumber,
    isAncestryPublicProfile,
    commercialTypes,
    disabledCommercialTypes,
    ...rest
  } = fields;

  const convertRegistries = (r: IRegistryRequest) => !!r.associationId || !!r.registrationNumber;
  const convertedParentage: IParentRequest[] | undefined =
    parentage &&
    (Object.values(parentage) as IParentRequest[]).map((parent) => ({
      ...parent,
      registries: parent.registries?.filter(convertRegistries),
    }));

  const convertedRegistries: IRegistryRequest[] | null = registries ? registries.filter(convertRegistries) : null;

  // Remove disabled commercial types before saving, because they depend on Gender
  const commercialTypesWithoutDisabled = commercialTypes
    ? disabledCommercialTypes
      ? commercialTypes.filter((x) => !disabledCommercialTypes.includes(x))
      : commercialTypes
    : [];

  return {
    ...rest,
    id: horseId,
    comments: fields.comments || '',
    gender: fields.gender || null,
    parentage: convertedParentage,
    registries: convertedRegistries,
    heightRequest: fields.height ? convertHeightToRequest(fields.height, fields.heightUnit) : (undefined as any),
    birthDate: {
      birthDate: convertToServerDate(dateOfBirth),
    },
    profileType: isPublicProfile ? ProfileType.Public : ProfileType.Private,
    passportNumber: passportNumber || null,
    microchipNumber: microchipNumber || null,
    ancestryProfileType: isAncestryPublicProfile ? ProfileType.Public : ProfileType.Private,
    commercialTypes: commercialTypesWithoutDisabled,
    animalType: fields.animalType,
  };
}

function convertHeightToRequest(height: number, heightUnit?: HeightUnits) {
  return {
    height: height,
    heightUnit: heightUnit || HeightUnits.Hands,
  };
}
