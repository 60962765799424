import {BreedType, DogBreedType, SORT_BREED_NAME, SORT_DOG_BREED_NAME} from 'OnlineReport/constants/BreedType';

export function sortBreeds<T extends {type: BreedType | DogBreedType}>(breeds: T[]): T[] {
  return breeds.sort((a, b) => {
    if (
      (SORT_BREED_NAME[a.type as BreedType] ?? SORT_DOG_BREED_NAME[a.type as DogBreedType]) >
      (SORT_BREED_NAME[b.type as BreedType] ?? SORT_DOG_BREED_NAME[b.type as DogBreedType])
    ) {
      return 1;
    }
    return -1;
  });
}
