import React from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Icon, {IconName} from 'Icon/components/Icon';

const Card = styled.div`
  width: 250px;
  min-width: 250px;
  height: 281px;
  background: ${ColorPalette.transparent6};
  border: 2px dashed ${ColorPalette.gray43};
  box-sizing: border-box;
  box-shadow: 0px 6px 14px ${ColorPalette.transparent5};
  border-radius: 10px;
  cursor: pointer;

  :hover {
    background: ${ColorPalette.transparent7};
  }
`;

const Label = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size16};
  line-height: 18px;
  color: ${ColorPalette.white0};
  margin-top: 16px;
`;

interface IProps {
  onClick(): void;
}

function EmptyHorse(props: IProps) {
  return (
    <Card className="d-flex flex-column justify-content-center align-items-center" onClick={props.onClick}>
      <Icon name={IconName.AddCircleStroked} size={32} />
      <Label>Add new animal</Label>
    </Card>
  );
}

export default EmptyHorse;
