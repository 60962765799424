import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IOnlineReportCoatColor} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColor';
import {IOnlineReportCoatColorDetailed} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColorDetailed';
import {IOnlineReportHealthVariantDetailed} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariantDetailed';
import {IOnlineReportAbility} from 'OnlineReport/models/PerformanceAndAbilities/IOnlineReportAbility';
import {IOnlineReportAbilityDetailed} from 'OnlineReport/models/PerformanceAndAbilities/IOnlineReportAbilityDetailed';
import {IOnlineReportHorseInfo} from 'OnlineReport/models/shared/IOnlineReportHorseInfo';
import {downloadResponse} from 'Common/helpers/downloadHelper';
import {IServerOnlineReportHealthVariants} from 'OnlineReport/services/models/IOnlineReportHealthVariantsServer';
import {convertServerHealthVariantsToClient} from 'OnlineReport/services/converters/healthVariant';
import {IOnlineReportHealthVariants} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariants';
import {IOnlineReportHorseDetails} from 'OnlineReport/models/shared/IOnlineReportHorseDetails';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import {getApiByReportType} from 'OnlineReport/helpers/getApiByReportType';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {IOnlineReportSummary} from 'OnlineReport/models/Summary/IOnlineReportSummary';
import {IOnlineReportGeneticVariants} from 'OnlineReport/models/Summary/IOnlineReportGenotype';
import {withImage} from 'Common/helpers/withImage';
import {IServerHorseOwner} from './models/owner';
import {convertServerHorseOwnerToClient} from './converters/owner';
import {IServerOnlineReportHorseDetails} from './models/IServerOnlineReportHorseDetails';
import {convertOnlineReportHorseDetails} from './converters/horse';
import {withAvatar} from 'Common/helpers/withAvatar';

type AdminReportType =
  | OnlineReportType.User
  | OnlineReportType.Association
  | OnlineReportType.Admin
  | OnlineReportType.AdminAssociation;
type ApiByType = Record<AdminReportType, string>;

const getSummaryMethod = async (animalId: number, postfix: string): Promise<IOnlineReportSummary> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/Summary/${postfix}/${animalId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/Summary/${postfix}/${animalId}`,
    [OnlineReportType.Admin]: `/Admin/OnlineReport/Summary/${postfix}/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/Summary/${postfix}/${animalId}`,
  };

  const api = getApiByReportType(apis);

  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportSummary>>(api);
  return result.data.data;
};

const getHorseInfo = async (animalId: number): Promise<IOnlineReportHorseInfo> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/Horse/${animalId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/Horse/${animalId}`,
    [OnlineReportType.Admin]: `/Admin/OnlineReport/Horse/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/Horse/${animalId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportHorseInfo>>(api);
  return withAvatar(result.data.data);
};

const getHorseOwner = async (animalId: number): Promise<string> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/Owner/${animalId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/Owner/${animalId}`,
    [OnlineReportType.Admin]: `/Admin/OnlineReport/Owner/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/Owner/${animalId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IObjectResponse<IServerHorseOwner>>(api);
  return convertServerHorseOwnerToClient(result.data.data);
};

const getHorseDetails = async (animalId: number): Promise<IOnlineReportHorseDetails> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/HorseDetails/${animalId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/HorseDetails/${animalId}`,
    [OnlineReportType.Admin]: `/Admin/OnlineReport/HorseDetails/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/HorseDetails/${animalId}`,
  };
  const api = getApiByReportType(apis);
  const response = await axiosWrapper.get<IObjectResponse<IServerOnlineReportHorseDetails>>(api);
  return convertOnlineReportHorseDetails(response.data.data);
};

const getSummaryColors = async (animalId: number): Promise<IOnlineReportSummary> => {
  return getSummaryMethod(animalId, 'Colors');
};

const getSummaryAbilities = async (animalId: number): Promise<IOnlineReportSummary> => {
  return getSummaryMethod(animalId, 'Abilities');
};

const getSummaryHealthIssues = async (animalId: number): Promise<IOnlineReportSummary> => {
  return getSummaryMethod(animalId, 'HealthIssues');
};

const getGeneticVariants = async (animalId: number): Promise<IOnlineReportGeneticVariants> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/GeneticVariants/${animalId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/GeneticVariants/${animalId}`,
    [OnlineReportType.Admin]: `/Admin/OnlineReport/GeneticVariants/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/GeneticVariants/${animalId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportGeneticVariants>>(api);
  return result.data.data;
};

const getHorseBreedPanel = async (animalId: number, orderId?: number): Promise<IHorseBreedPanel[]> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/Panels/${animalId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/Panels/${animalId}`,
    [OnlineReportType.Admin]: `/Admin/OnlineReport/Panels/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/Panels/${animalId}`,
  };
  const api = getApiByReportType(apis);

  const response = await axiosWrapper.get<IListResponse<IHorseBreedPanel>>(api);
  return response.data.data;
};

const getCoatColor = async (animalId: number): Promise<IOnlineReportCoatColor[]> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/PartialColors/${animalId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/PartialColors/${animalId}`,
    [OnlineReportType.Admin]: `/Admin/OnlineReport/PartialColors/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/PartialColors/${animalId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IListResponse<IOnlineReportCoatColor>>(api);
  return result.data.data.map(withImage);
};

const getCoatColorDetailed = async (colorId: number): Promise<IOnlineReportCoatColorDetailed> => {
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportCoatColorDetailed>>(
    `/OnlineReport/PartialColorDetails/${colorId}`
  );
  return result.data.data;
};

const getHealthVariants = async (animalId: number): Promise<IOnlineReportHealthVariants[]> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/HealthIssues/${animalId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/HealthIssues/${animalId}`,
    [OnlineReportType.Admin]: `/Admin/OnlineReport/HealthIssues/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/HealthIssues/${animalId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IListResponse<IServerOnlineReportHealthVariants>>(api);
  return result.data.data.map(convertServerHealthVariantsToClient);
};

const getHealthVariantDetailed = async (
  healthIssueId: number,
  isAggregated: boolean
): Promise<IOnlineReportHealthVariantDetailed> => {
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportHealthVariantDetailed>>(
    !isAggregated
      ? `/OnlineReportHealthIssues/${healthIssueId}`
      : `/OnlineReportHealthIssueAggregators/${healthIssueId}`
  );
  return result.data.data;
};

const getAbilities = async (animalId: number): Promise<IOnlineReportAbility[]> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/Abilities/${animalId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/Abilities/${animalId}`,
    [OnlineReportType.Admin]: `/Admin/OnlineReport/Abilities/${animalId}`,
    [OnlineReportType.AdminAssociation]: `/Admin/AssociationOnlineReport/Abilities/${animalId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IListResponse<IOnlineReportAbility>>(api);
  return result.data.data.map(withImage);
};

const getAbilityDetailed = async (abilityId: number, isAggregated: boolean): Promise<IOnlineReportAbilityDetailed> => {
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportAbilityDetailed>>(
    !isAggregated ? `/OnlineReportAbilities/${abilityId}` : `/OnlineReportAbilityAggregators/${abilityId}`
  );
  return result.data.data;
};

// TODO: delete?
const downloadDiagnosticReport = async (animalId: number): Promise<void> => {
  const response = await axiosWrapper.get(`/OnlineReport/DiagnosticReport/pdf/${animalId}`, {responseType: 'blob'});
  downloadResponse(response, 'diagnostic-report');
};

export default {
  getHorseInfo,
  getHorseOwner,
  getHorseDetails,
  getSummaryAbilities,
  getSummaryColors,
  getSummaryHealthIssues,
  getCoatColor,
  getCoatColorDetailed,
  getHealthVariants,
  getHealthVariantDetailed,
  getAbilities,
  getAbilityDetailed,
  downloadDiagnosticReport,
  getGeneticVariants,
  getHorseBreedPanel,
};
