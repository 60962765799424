import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import {Pagination} from 'Common/components/Controls';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Loading from 'Loading/components/Loading';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {IPackage} from 'Dictionaries/models/IPackage';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminPackages';
import {AdminPackagesModule} from 'Admin/AdminDashboard/store/adminPackages/adminPackagesModule';
import {FormType} from 'Common/constants/FormType';
import PackageLayout from './PackageLayout';
import {TestsMoreDropDown} from './parts/TestsMoreDropDown';
import {PackageName} from './parts/PackageName';
import {PackageActions} from './parts/PackageActions';
import {SwitchButton} from 'Common/components/Controls/Buttons/SwitchButton';
import {OrderingType} from 'Common/constants/OrderingType';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import PackageCartSettingsForm from './PackageCartSettings/PackageCartSettingsForm';
import {FacetFilterModule} from 'FacetFilter/store/module';
import {actions as facetActions, selectors as facetSelectors} from 'FacetFilter/store';
import {useFacetSearchBar} from 'FacetFilter/hooks/useFacetSearchBar';
import {PackageStatus} from 'Dictionaries/models/IPackageSimple';
import {DictionaryToProperty, EnumToProperty} from 'FacetFilter/models/types';
import {useDictionaries} from 'Common/store/useDictionaries';

const AddButton = styled(PrimaryButton)`
  width: 176px;
  height: 50px;
  margin-left: 55px;
`;

const PackageTable = styled.div`
  margin-top: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const Packages = (props: AllProps) => {
  const {
    getPackages,
    packagesLoading,
    changePackageStatus,
    changePackageStatusRequesting,
    packages,
    getPackagesFilters,
    packagesFilters,
  } = props;

  const {
    selectedId,
    setSelectedId,
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    reloadItems,
    // searchBar,
  } = useCommonAdminPageData<IPackage>({
    sortingAfterFilteringParams: {orderBy: 'name', orderingType: OrderingType.Ascending},
    // getItems: getPackages,
  });

  const {reportTypes, tests, packageGroups} = useDictionaries();

  const mapDictionaryToProperty: DictionaryToProperty = {
    'Package.ReportType': reportTypes,
    'Package.Group': packageGroups,
    'Package.Test': tests,
  };

  const mapEnumToProperty: EnumToProperty = {
    'Package.Status': PackageStatus,
  };

  const {searchBar: facetSearchBar} = useFacetSearchBar({
    filterItems: packagesFilters,
    mapEnumToProperty: mapEnumToProperty,
    mapDictionaryToProperty: mapDictionaryToProperty,
    getFilterItems: getPackagesFilters,
    getItems: getPackages,
  });

  const [isWorkWithPackageOpen, setIsWorkWithPackageOpen] = useState(false);
  const [isEditPackageCartSettingsOpen, setIsEditPackageCartSettingsOpen] = useState(false);
  const [formType, setFormType] = useState<FormType>(FormType.create);

  const isRequesting = [packagesLoading, changePackageStatusRequesting].some((i) => i.isRequesting);

  const handleAddPackage = () => {
    setFormType(FormType.create);
    setSelectedId(null);
    setIsWorkWithPackageOpen(true);
  };

  const handleAddBased = (id: number) => {
    setFormType(FormType.createBased);
    setSelectedId(id);
    setIsWorkWithPackageOpen(true);
  };

  const handleEditPackage = (id: number) => {
    setFormType(FormType.edit);
    setSelectedId(id);
    setIsWorkWithPackageOpen(true);
  };

  const handleShowPackage = (id: number) => {
    setFormType(FormType.show);
    setSelectedId(id);
    setIsWorkWithPackageOpen(true);
  };

  const handleEditCartSettings = (id: number) => {
    setSelectedId(id);
    setIsEditPackageCartSettingsOpen(true);
  };

  const closeCreateOrEditPackageModal = useCallback(() => {
    setIsWorkWithPackageOpen(false);
  }, []);

  const onSuccessCreateOrEditPackage = useCallback(() => {
    closeCreateOrEditPackageModal();
    reloadItems();
  }, [closeCreateOrEditPackageModal, reloadItems]);

  const closeEditPackageCartSettingsModal = useCallback(() => {
    setIsEditPackageCartSettingsOpen(false);
  }, []);

  const onSuccessPackageCartSettings = useCallback(() => {
    closeEditPackageCartSettingsModal();
    reloadItems();
  }, [closeEditPackageCartSettingsModal, reloadItems]);

  const changeStatus = useCallback(
    (value: boolean, packageId: number) => {
      changePackageStatus(packageId, value);
    },
    [changePackageStatus]
  );

  const isShowPagination = packages.length > 0;

  return (
    <AdminPageLayout>
      <ModalWindow isOpen={isWorkWithPackageOpen} onClose={closeCreateOrEditPackageModal}>
        <PackageLayout packageId={selectedId || undefined} type={formType} onSuccess={onSuccessCreateOrEditPackage} />
      </ModalWindow>
      <ModalWindow isOpen={isEditPackageCartSettingsOpen} onClose={closeEditPackageCartSettingsModal}>
        <PackageCartSettingsForm packageId={selectedId!} onSuccess={onSuccessPackageCartSettings} />
      </ModalWindow>

      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{facetSearchBar}</div>
        <AddButton onClick={handleAddPackage}>+ Add item</AddButton>
      </div>
      <PackageTable className="position-relative">
        {isRequesting && <Loading />}
        <Table<IPackage>
          data={packages}
          disableRow={(item) => !item.isEnabled || item.isArchived}
          rowKey="id"
          sorting={sorting}
          onChangeSorting={changeSorting}
        >
          <Cell<IPackage>
            header="Enable"
            dataKey="isEnabled"
            render={({id, isEnabled}) => (
              <SwitchButton itemId={id} onChangeHandler={changeStatus} checked={isEnabled} />
            )}
            width="7%"
          />
          <Cell<IPackage> header="ID" dataKey="id" render={({id}) => `#${id}`} width={100} />
          <Cell<IPackage>
            header="Name"
            dataKey="name"
            render={({name, isArchived, statusChangeDate}) => (
              <PackageName name={name} isArchived={isArchived} statusChangeDate={statusChangeDate} />
            )}
            width="20%"
          />
          <Cell<IPackage>
            header="Abbreviation"
            dataKey="abbreviation"
            render={({abbreviation}) => abbreviation}
            width="8%"
          />
          <Cell<IPackage>
            header="Tests"
            dataKey="tests"
            sortable={false}
            render={({tests}) => <TestsMoreDropDown tests={tests} />}
            width="10%"
          />
          <Cell<IPackage>
            header="Reports"
            dataKey="reportTypes"
            sortable={false}
            render={({reportTypes}) => reportTypes.map((x) => x.name).join(', ')}
            width="10%"
          />
          <Cell<IPackage>
            header="Group"
            dataKey="groups"
            sortable={false}
            render={({groups}) => groups.map((x) => x.name).join(', ')}
            width="10%"
          />
          <Cell<IPackage>
            header="Association"
            dataKey="association"
            sortable={false}
            render={({association}) => association?.name}
            width="15%"
          />
          <Cell<IPackage> header="Price" dataKey="price" render={({price}) => price} width="5%" />
          <Cell<IPackage> header="Animal Type" dataKey="animalType" render={({animalType}) => animalType} width="5%" />
          <Cell<IPackage>
            header="Actions"
            align={CellAlign.Right}
            width={100}
            render={(item) => (
              <PackageActions
                packageInfo={item}
                showAction={handleShowPackage}
                editAction={handleEditPackage}
                addBased={handleAddBased}
                editCartSettings={handleEditCartSettings}
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={props.pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </PackageTable>
    </AdminPageLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  packages: selectors.selectPackages(state),
  packagesLoading: selectors.selectCommunication(state, 'packagesLoading'),
  changePackageStatusRequesting: selectors.selectCommunication(state, 'changePackageStatusRequesting'),
  pagination: selectors.selectPagination(state),
  packagesFilters: facetSelectors.selectPackagesFilters(state),
});

const mapDispatchToProps = {
  // getPackages: actions.getPackages,
  getPackages: actions.getPackagesByFilter,
  changePackageStatus: actions.changePackageStatus,
  getPackagesFilters: facetActions.getPackagesFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(Packages);

export default withDynamicModules(Connected, [AdminPackagesModule, FacetFilterModule]);
