import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  AdminDogDiagnosticsUploadActions,
  IAdminDogDiagnosticsUploadState,
  uploadDogDiagnosticsUploadActionTypes,
} from '../types';

export const communicationDogDiagnosticsUploadReducer = combineReducers<
  IAdminDogDiagnosticsUploadState['communications'],
  AdminDogDiagnosticsUploadActions
>({
  dogDiagnosticsUploadUploading: makeCommunicationReducerFromEnum(uploadDogDiagnosticsUploadActionTypes),
});
