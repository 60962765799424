import React, {memo, useCallback} from 'react';
import styled from 'styled-components';

import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import {
  BreedType,
  DogBreedType,
  MAP_BREED_COLOR,
  MAP_BREED_NAME,
  MAP_DOG_BREED_COLOR,
} from 'OnlineReport/constants/BreedType';
import Theme from 'Common/constants/Theme';
import ColorPalette from 'Common/constants/ColorPalette';
import {AncestryReportSection} from 'OnlineReport/components/Ancestry/parts/common/styled';
import Typography from 'Common/constants/Typography';
import {MutableFieldsGroupBase} from 'Common/components/StyledComponents/StyledComponents';
import {breakpoints} from 'Common/constants/Breakpoints';
import {IAncesryComponentProps} from 'OnlineReport/models/Ancestry/IAncesryComponentProps';

export const BarWrapper = styled.div`
  padding: 16px 0;
`;

export const SectionWrapper = styled.div<{width: number}>`
  position: relative;
  min-width: 180px;
  width: 100%;
  padding: 4px 4px 16px 0;

  &:first-child {
    padding-left: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    right: calc(100% + 8px);
    top: 50%;
    width: 24px;
    height: 2px;
    background-color: ${ColorPalette.white9};
  }

  @media ${breakpoints.sm} {
    width: 80%;
  }

  @media ${breakpoints.md}, print {
    width: ${({width}) => `${width}%`};
    padding: 12px 4px 16px 0;
  }
`;

export const LineWrapper = styled.div`
  margin-left: 6px;
  padding-left: 32px;
  border-left: 2px solid ${ColorPalette.white9};

  &:before {
    content: '';
    background-color: ${ColorPalette.white9};
    width: 12px;
    height: 12px;
    border-radius: 6px;
    position: absolute;
    left: -7.5px;
  }
`;

export const BarSection = styled.div<{color?: string}>`
  display: flex;
  align-items: center;
  color: ${Theme.color.white};
  background-color: ${({color}) => color || ColorPalette.white9};
  height: 48px;
  padding: 0 20px;
  border-radius: 24px;

  &:hover {
    cursor: pointer;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
`;

export const BarLabel = styled.span`
  font-weight: bold;
  color: ${Theme.color.white};
  font-size: 0.8rem;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: visible;
  text-overflow: ellipsis;
`;

export const PercentageLabel = styled.span`
  font-size: ${Typography.size.size10};
  font-weight: ${Typography.weight.bold700};
  color: ${Theme.color.white};
  opacity: 0.75;
`;

export const MutableChildWrapper = styled(MutableFieldsGroupBase)<{borderColor: string}>`
  padding: 0 32px;

  /* Hack to make the line extend further for children */
  *:before {
    width: 48px;
    right: calc(100% + 16px);
  }

  ${BarSection} {
    background-color: ${ColorPalette.white9};
    border: 2px solid ${({borderColor}) => borderColor};

    ${BarLabel}, ${PercentageLabel} {
      color: ${ColorPalette.black9};
    }
  }

  @media print {
    flex-direction: row;
  }
`;

interface IProps {
  population: IAncestryPopulation[];
}

type AllProps = IProps & IAncesryComponentProps;

const BreakdownView = (props: AllProps) => {
  const {population, withoutPaddings = false} = props;

  const renderBreedItem = useCallback(
    (type: string, percentage: number, total: number = 100, isChild?: boolean) => (
      <SectionWrapper key={type} width={percentage}>
        <BarSection color={MAP_BREED_COLOR[type as BreedType] ?? MAP_DOG_BREED_COLOR[type as DogBreedType]}>
          <LabelWrapper>
            <BarLabel>{isChild ? type : MAP_BREED_NAME[type as BreedType] ?? type}</BarLabel>
            <PercentageLabel>{(percentage * (total / 100)).toFixed()}%</PercentageLabel>
          </LabelWrapper>
        </BarSection>
      </SectionWrapper>
    ),
    []
  );

  return (
    <AncestryReportSection className="w-100" withoutPaddings={withoutPaddings}>
      <LineWrapper className="position-relative">
        <BarWrapper className="d-flex flex-column">
          {population.map((parentBreed, i) => (
            <React.Fragment key={i}>
              {renderBreedItem(parentBreed.type, parentBreed.percentage)}
              <MutableChildWrapper
                pointOfChange="md"
                className="d-flex"
                borderColor={
                  MAP_BREED_COLOR[parentBreed.type as BreedType] ??
                  MAP_DOG_BREED_COLOR[parentBreed.type as DogBreedType]
                }
              >
                <div className="d-flex flex-wrap">
                  {parentBreed.children.map((childBreed) =>
                    renderBreedItem(childBreed.type, parentBreed.percentage, childBreed.percentage, true)
                  )}
                </div>
              </MutableChildWrapper>
            </React.Fragment>
          ))}
        </BarWrapper>
      </LineWrapper>
    </AncestryReportSection>
  );
};
export default memo(BreakdownView);
