import {IUserHorse, IUserHorseOrder} from 'Horse/models/IUserHorse';
import {IServerUserHorse} from 'Horse/services/types/horse';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {parseOrderStatus} from 'Order/helpers/parseOrderStatus';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {convertPriceToClient} from 'Common/helpers/convertPrices';
import {IServerUserHorseOrderShort} from 'BusinessPortal/services/types/horse';
import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';

export function convertHorseLastOrderToClient(horse: IServerUserHorseOrderShort): IUserHorseOrder {
  return {
    ...horse,
    ...parseOrderStatus(horse.status),
    packages: horse.packagesAndTests,
    createDate: '',
    amount: 0,
    reports: [],
    token: '',
    hasNotResults: false,
  };
}

export function convertServerUserHorse(horse: IServerUserHorse): IUserHorse {
  return {
    ...withBirthDate(horse),
    order: horse.order
      ? {
          ...parseOrderStatus(horse.order),
          id: horse.order.id,
          createDate: convertUTCToClientDate(horse.order.createDate) || '',
          amount: convertPriceToClient(horse.order.amount),
          packages: horse.order.packages,
          reports: horse.order.reports,
          token: horse.order.token,
          animalType: horse.animalType,
        }
      : null,
    avatar: horse.avatar && withImageBaseUrl(horse.avatar),
  };
}
