import * as Yup from 'yup';
import moment from 'moment';

import {HeightLimits, HeightUnits} from 'HorseProfile/constants/HeightUnits';
import {IImage} from 'Image/models/IImage';

import {Gender} from 'Common/constants/Gender';
import {IRegistryRequest} from 'Common/models/IRegistryRequest';
import {IParentRequest} from 'Horse/models/IHorseRequest';
import {Parentage} from 'HorseProfile/constants/Parentage';

import {initialParentage} from './constants/initialParentage';
import {Nullable} from 'Common/types';
import {isValidHeight} from 'Common/helpers/HeightHelper';
import {FRONTEND_DATE} from 'Common/constants/Date';
import {isValidDate} from 'Common/helpers/DateHelper';
import {IHorse} from 'Horse/models/IHorse';
import {IWithBirthDate} from 'Common/models/IWithBirthDate';
import {ICoordinatedLocation} from 'Common/models/ICoordinatedLocation';
import {IMediaResource} from 'Common/models/IMediaResource';
import {IAvatared} from 'Common/models/IAvatar';
import {IAddressWithNullableField} from 'Common/models/IAddress';
import {IWithHorseMapFieldProps} from 'Common/components/FormFields/HorseMapField/HorseMapField';
import {AnimalType} from '../../../Common/constants/AnimalType';

export interface IFormValues extends IHorseEditFormFields {
  gallery: IMediaResource[];
  animalType: AnimalType;
}

export interface IParentageField {
  sire?: IParentRequest;
  dam?: IParentRequest;
}

export interface IEditHorseFields {
  id: number;
  animalType: AnimalType;
  name: string;
  gender: Gender | null;
  breed?: string;
  breeds: number[];
  disciplines?: number[];
  colors?: number[];
  markings?: number[];
  dateOfBirth?: Date | null;
  height?: number;
  heightUnit: HeightUnits;
  temperament: number;
  avatar: IImage | null;
  // ... other fields
}

export interface IHorseEditFormFields extends IWithBirthDate, IAvatared, IWithHorseMapFieldProps {
  userId?: number;
  name: string;
  gender: Gender | null;
  commercialTypes?: number[];
  disabledCommercialTypes?: number[];
  comments: string;
  height?: number;
  heightUnit: HeightUnits;
  healthProfile?: number[];
  breeds: number[];
  colors?: number[];
  markings?: number[];
  disciplines?: number[];
  registries?: IRegistryRequest[];
  parentage?: IParentageField;
  temperament: number;
  isPublicProfile: boolean;
  passportNumber: Nullable<string>;
  microchipNumber: Nullable<string>;
  preclinicalNotes: Nullable<string>;
  isAncestryPublicProfile: boolean;
  isCelebrity?: boolean;
  animalType: AnimalType;
}

export const initialHorse: IHorseEditFormFields = {
  name: '',
  parentage: initialParentage,
  height: '' as any as number,
  heightUnit: HeightUnits.Hands,
  comments: '',
  commercialTypes: [],
  disabledCommercialTypes: [],
  gender: null,
  temperament: 0,
  breeds: [],
  isPublicProfile: false,
  avatar: null,
  dateOfBirth: null,
  microchipNumber: '',
  passportNumber: '',
  userId: undefined,
  location: null,
  address: {
    country: '',
    state: '',
    city: '',
    street: '',
    zip: '',
  },
  preclinicalNotes: null,
  isAncestryPublicProfile: true,
  isManualAddressMode: false,
};

const registryShape = Yup.array().of(
  Yup.object().shape({
    registrationNumber: Yup.string().nullable(),
    associationId: Yup.number().nullable(),
  })
);

const parentageShape = Yup.object().shape({
  registries: registryShape,
  name: Yup.string().nullable(),
  parent: Yup.mixed<Parentage>(),
});

export const getValidationScheme = (isAdminCreate: boolean = false) =>
  Yup.object().shape({
    animalType: Yup.string().required('Animal type is required'),
    name: Yup.string().required('Name is required'),
    gender: Yup.string().nullable().required('Gender is required'),

    // Make these fields conditional based on animal type
    breeds: Yup.array()
      .of(Yup.number())
      .when('animalType', {
        is: AnimalType.Horse,
        then: Yup.array().min(1, 'At least one breed is required'),
        otherwise: Yup.array(),
      }),

    // Fix height validation by passing the current heightUnit
    height: Yup.number()
      .nullable()
      .test('height', 'Invalid height', function (value) {
        if (!value) return true;
        const currentUnit = this.parent.heightUnit;
        return isValidHeight(value, currentUnit);
      }),

    heightUnit: Yup.string().when('animalType', {
      is: AnimalType.Horse,
      then: Yup.string().required('Height unit is required'),
      otherwise: Yup.string().nullable(),
    }),

    temperament: Yup.number().when('animalType', {
      is: AnimalType.Horse,
      then: Yup.number().required('Temperament is required'),
      otherwise: Yup.number().nullable(),
    }),

    // Make other horse-specific fields conditional
    commercialTypes: Yup.array().when('animalType', {
      is: AnimalType.Horse,
      then: Yup.array().of(Yup.number()),
      otherwise: Yup.array().nullable(),
    }),

    registries: Yup.array().when('animalType', {
      is: AnimalType.Horse,
      then: Yup.array().of(
        Yup.object().shape({
          registrationNumber: Yup.string().nullable(),
          associationId: Yup.number().nullable(),
        })
      ),
      otherwise: Yup.array().nullable(),
    }),

    // Keep common fields required for all animal types
    dateOfBirth: Yup.date()
      .nullable()
      .transform((curr, orig) => {
        // Handle empty values properly
        if (orig === null || orig === undefined || orig === '') return null;

        // If it's already a valid Date object, return it
        if (orig instanceof Date && !isNaN(orig.getTime())) {
          return orig;
        }

        // Try parsing with moment using multiple formats
        const parsed = moment(orig, ['YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MM-YYYY'], true);
        return parsed.isValid() ? parsed.toDate() : null;
      })
      .test('dateOfBirth', 'Invalid date', function (value) {
        if (value === null) return true; // Allow null due to `.nullable()`
        return moment(value).isValid() && moment(value).isBefore(moment());
      }),

    isPublicProfile: Yup.boolean(),
    isAncestryPublicProfile: Yup.boolean(),

    // Admin-specific fields
    ...(isAdminCreate && {
      userId: Yup.number().required('Owner is required'),
    }),
  });

export const initialValue: IFormValues = {
  ...initialHorse,
  gallery: [],
  animalType: AnimalType.Horse,
};

export const getHorseEditFields = (horse: IEditHorseFields): IHorseEditFormFields => ({
  animalType: horse.animalType || AnimalType.Horse,
  name: horse.name,
  gender: horse.gender,
  breed: horse.breed,
  breeds: horse.breeds,
  disciplines: horse.disciplines,
  colors: horse.colors,
  markings: horse.markings,
  dateOfBirth: horse.dateOfBirth,
  height: horse.height,
  heightUnit: horse.heightUnit,
  temperament: horse.temperament,
  avatar: horse.avatar,
  // ... other fields
});
