import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse} from 'Common/models/IResponse';
import {IGenotypeGroup} from 'Genotype/models/IGenotypeGroup';
import {isAdminVisitor} from 'Common/helpers/isAdminVisitor';
import {withImage} from 'Common/helpers/withImage';

const getGenotypes = async (id: number): Promise<IListResponse<IGenotypeGroup>> => {
  const response = await axiosWrapper.get<IListResponse<IGenotypeGroup>>(
    (isAdminVisitor() ? `/AnimalGenotypeGroupsAdmin` : `/AnimalGenotypeGroups`) + `/${id}`
  );
  return {...response.data, data: response.data.data.map(withImage)};
};

export default {
  getGenotypes,
};
