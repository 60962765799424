import * as Yup from 'yup';

import {IEntity} from 'Common/models/IEntity';
import {Nullable} from 'Common/types';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import {AnimalType} from '../../../../../Common/constants/AnimalType';

export interface IFormValues {
  id?: IEntity['id'];
  name: string;
  abbreviation: Nullable<string>;
  animalType: AnimalType;
}

export const initialValue: IFormValues = {
  abbreviation: null,
  name: '',
  animalType: AnimalType.Horse,
};

export const validationSchema = Yup.object().shape<Partial<IFormValues>>({
  abbreviation: Yup.string().nullable(),
  name: Yup.string().required('Field is required'),
});

export const convertToServer = (values: IFormValues): Omit<IAssociation, 'id'> => {
  return {
    ...values,
    abbreviation: values.abbreviation && values.abbreviation.length > 0 ? values.abbreviation : null,
  };
};

export const convertToServerUpdating = (values: IFormValues): IAssociation => {
  return {
    ...convertToServer(values),
    id: values.id!,
  };
};
