import {ICommunication, MakeCommunicationActions} from 'Common/store/utils/communication/types';

import {makeCommunicationActionType} from 'Common/store/utils/communication/index';

const STATE_NAME = 'adminDogDiagnosticsUpload';

export interface IAdminDogDiagnosticsUploadState {
  communications: {
    dogDiagnosticsUploadUploading: ICommunication;
  };
}

export const uploadDogDiagnosticsUploadActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPLOAD_DOG_DIAGNOSTICS_UPLOAD'
);

export type uploadDogDiagnosticsUploadActions = MakeCommunicationActions<
  typeof uploadDogDiagnosticsUploadActionTypes,
  {}
>;

export type AdminDogDiagnosticsUploadActions = uploadDogDiagnosticsUploadActions;
