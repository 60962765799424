import {Form, FormikProps, withFormik} from 'formik';
import {memo, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import UserSearchField from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearchField';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminActivationCodes';
import {AdminCreateUserOrderModule} from 'Admin/AdminDashboard/store/adminOrders/createUserOrder/adminCreateUserOrderModule';
import {
  actions as createOrderActions,
  selectors as createOrderSelectors,
} from 'Admin/AdminDashboard/store/adminOrders/createUserOrder/index';
import {Divider} from 'Admin/shared/components/styled';
import {InputField, SelectField} from 'Common/components/FormFields';
import Nebula from 'Common/components/Layout/Nebula';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage, MutableField, MutableFieldsGroup} from 'Common/components/StyledComponents/StyledComponents';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useSuccessCommunicationToToast} from 'Common/helpers/hooks/useSuccessCommunicationToToast';
import {castToOption} from 'Common/helpers/OptionHelper';
import {sortByName} from 'Common/helpers/sortByName';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import {IActivationCodeActivateRequest, IFormValues, initialValue, validationSchema} from './validation';
import {IActivationCode} from 'ActivationCode/models/IActivationCode';
import {IDog} from 'Dogs/models/IDog';
import {AnimalType} from 'Common/constants/AnimalType';

interface IExternalProps {
  activationCodeId?: number;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const DROPDOWN_ITEM_HEIGHT = 37;

function ActivateCodeForm(props: AllProps) {
  const {
    activationCodeActivating,
    horses,
    dogs,
    activationCode,
    getHorses,
    getDogs,
    onSuccess,
    getActivationCodeDetails,
    activationCodeId,
  } = props;
  const {setErrors, setStatus, status, values, isValid} = props;

  const errorInfo = activationCodeActivating.error;

  (window as any).currentActivationCode = activationCode;

  useEffect(() => {
    if (values.userId) {
      getHorses(values.userId);
    }
  }, [getHorses, values.userId]);

  useEffect(() => {
    if (values.userId) {
      getDogs(values.userId);
    }
  }, [getDogs, values.userId]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  useEffect(() => {
    const fetchData = async () => {
      if (activationCodeId) {
        await getActivationCodeDetails(activationCodeId);
      }
    };

    fetchData();
  }, [activationCodeId, getActivationCodeDetails]);

  useOnSuccessCommunication(activationCodeActivating, onSuccess);
  useSuccessCommunicationToToast(activationCodeActivating, 'Code activation request has been created');

  const userNotFound = !values.userId || values.userId === 0;

  const animalsOptions = useMemo(() => {
    const animals = activationCode?.animalType === AnimalType.Dog ? dogs.sort(sortByName) : horses.sort(sortByName);
    return animals.map((animal) => ({label: animal.name, value: animal.id || ''}));
  }, [horses, dogs]);

  const isLoading = activationCodeActivating.isRequesting;

  return (
    <>
      <ModalWindowHeader>Activate Code: {activationCode?.code}</ModalWindowHeader>
      <Form>
        <ModalWindowFormContent>
          <UserSearchField name="userId" label="Current owner" isRequired={true} />
          {userNotFound && <Divider />}
          <Nebula active={userNotFound} style={{opacity: 0.5, marginTop: -5}}>
            {!userNotFound && (
              <SelectField
                isRequired={true}
                name="animalId"
                options={animalsOptions}
                label={activationCode?.animalType || 'Animal'}
                maxMenuHeight={DROPDOWN_ITEM_HEIGHT * 4}
              />
            )}
          </Nebula>

          <div style={{minHeight: '150px'}}></div>
        </ModalWindowFormContent>

        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" disabled={!isValid} isLoading={isLoading}>
            Submit
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const ActivateCodeFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {activateCode} = formikBag.props;
    formikBag.setSubmitting(true);
    const activationCode: IActivationCode = (window as any).currentActivationCode;
    const params: IActivationCodeActivateRequest = {
      activationCodeId: activationCode.id,
      animalId: values.animalId,
    };
    await activateCode(params);
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(ActivateCodeForm);

const mapStateToProps = (state: IAppState) => ({
  activationCode: selectors.selectActivationCodeDetails(state),
  horses: createOrderSelectors.selectUserOrderHorses(state),
  dogs: createOrderSelectors.selectUserOrderDogs(state),
  activationCodeActivating: selectors.selectCommunication(state, 'activationCodeActiviating'),
});

const mapDispatchToProps = {
  getActivationCodeDetails: actions.getActivationCodeById,
  activateCode: actions.activateActivationCode,
  getHorses: createOrderActions.getUserHorses,
  getDogs: createOrderActions.getUserDogs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(ActivateCodeFormFormik));

export default withDynamicModules(memo(Connected), AdminCreateUserOrderModule);
