export enum Gender {
  Stallion = 'Stallion',
  Gelding = 'Gelding',
  Mare = 'Mare',
}

export enum DogGender {
  Male = 'Male',
  Female = 'Female',
}

export const MergedGenders = {
  Horse: Gender,
  Dog: DogGender,
};
