import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {IHorse} from 'Horse/models/IHorse';

import {ProfileType} from 'Common/constants/ProfileType';
import {IShortAbility} from 'Dictionaries/models/IAbilities';
import {IHorseReport, IUserHorseOrder} from 'Horse/models/IUserHorse';
import {Nullable} from 'Common/types';
import {IHorseProfile} from 'Horse/models/IHorseProfile';
import IFoundHorse from 'Horse/models/IFoundHorse';
import {IHorseOwner} from 'Horse/models/IHorseOwner';
import {IOwnerHorse} from 'Shared/models/IOwnerHorse';

export interface IHorseProfileTypeRequest {
  animalId: number;
  profileType: ProfileType;
}

const STATE_NAME = 'horse';

export interface IHorseState {
  data: {
    horse: Nullable<IHorse>;
    horseAbilities: IShortAbility[];
    horseParantageReport: Nullable<IHorseReport>;
    horseLastOrder: Nullable<IUserHorseOrder>;
    foundHorse: Nullable<IFoundHorse>;
    horseOwner: Nullable<IHorseOwner>;
    ownerHorses: IOwnerHorse[];
  };
  communications: {
    horseLoading: ICommunication;
    horseCreating: ICommunication;
    horseUpdating: ICommunication;
    horseDeleting: ICommunication;
    horseAbilitiesLoading: ICommunication;
    horseParantageReportLoading: ICommunication;
    horseLastOrderLoading: ICommunication;
    foundHorseSearching: ICommunication;
    horseOwnerLoading: ICommunication;
    ownerHorsesLoading: ICommunication;
    diagnosticsProfileTypeUpdating: ICommunication;
    ancestryProfileTypeUpdating: ICommunication;
    parentageProfileTypeUpdating: ICommunication;
  };
}

export const getHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE');
export type getHorseActions = MakeCommunicationActions<typeof getHorseActionTypes, {success: Nullable<IHorseProfile>}>;

export const createHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_HORSE');
export type createHorseActions = MakeCommunicationActions<typeof createHorseActionTypes, {}>;

export const updateHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_HORSE');
export type updateHorseActions = MakeCommunicationActions<typeof updateHorseActionTypes, {}>;

export const deleteHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_HORSE');
export type deleteHorseActions = MakeCommunicationActions<typeof deleteHorseActionTypes, {}>;

export const resetHorseActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE');
export type resetHorseActions = MakeResetCommunicationActions<typeof resetHorseActionTypes>;

export const updateHorseProfileTypeActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_HORSE_PROFILE_TYPE');
export type updateHorseProfileTypeActions = MakeCommunicationActions<
  typeof updateHorseProfileTypeActionTypes,
  {success: ProfileType}
>;

export const resetHorseProfileTypeActionTypes = makeResetCommunicationActionType(STATE_NAME, 'HORSE_PROFILE_TYPE');
export type resetHorseProfileTypeActions = MakeResetCommunicationActions<typeof resetHorseProfileTypeActionTypes>;

export const resetHorseParentageProfileTypeActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'HORSE_PARENTAGE_PROFILE_TYPE'
);
export type resetHorseParentageProfileTypeActions = MakeResetCommunicationActions<
  typeof resetHorseParentageProfileTypeActionTypes
>;

export const updateHorseAncestryProfileTypeActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_HORSE_ANCESTRY_PROFILE_TYPE'
);
export type updateHorseAncsetryProfileTypeActions = MakeCommunicationActions<
  typeof updateHorseAncestryProfileTypeActionTypes,
  {success: ProfileType}
>;

export const updateHorseParentageProfileTypeActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_HORSE_PARENTAGE_PROFILE_TYPE'
);
export type updateHorseParentageProfileTypeActions = MakeCommunicationActions<
  typeof updateHorseParentageProfileTypeActionTypes,
  {success: ProfileType}
>;

export const resetHorseAncestryProfileTypeActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'HORSE_ANCESTRY_PROFILE_TYPE'
);
export type resetHorseAncestryProfileTypeActions = MakeResetCommunicationActions<
  typeof resetHorseAncestryProfileTypeActionTypes
>;

export const getHorseAbilitiesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_ABILITIES');
export type getHorseAbilitiesActions = MakeCommunicationActions<
  typeof getHorseAbilitiesActionTypes,
  {success: IShortAbility[]}
>;

export const getHorseParantageReportActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_PARANTAGE_REPORT');
export type getHorseParantageReportActions = MakeCommunicationActions<
  typeof getHorseParantageReportActionTypes,
  {success: IHorseReport}
>;

export const getHorseLastOrderActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_LAST_ORDER');
export type getHorseLastOrderActions = MakeCommunicationActions<
  typeof getHorseLastOrderActionTypes,
  {success: IUserHorseOrder}
>;

export const searchHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'SEARCH_HORSE_BY_ID');
export type searchHorseActions = MakeCommunicationActions<
  typeof searchHorseActionTypes,
  {success: Nullable<IFoundHorse>}
>;

export const resetSearchHorseActionTypes = makeResetCommunicationActionType(STATE_NAME, 'SEARCH_HORSE');
export type resetSearchHorseActions = MakeResetCommunicationActions<typeof resetSearchHorseActionTypes>;

export const getHorseOwnerActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_OWNER');
export type getHorseOwnerActions = MakeCommunicationActions<typeof getHorseOwnerActionTypes, {success: IHorseOwner}>;

export const getOwnerHorsesAtionTypes = makeCommunicationActionType(STATE_NAME, 'GET_OWNER_HORSES');
export type getOwnerHorsesActions = MakeCommunicationActions<typeof getOwnerHorsesAtionTypes, {success: IOwnerHorse[]}>;

export const resetHorseParantageReportActionTypes = makeResetCommunicationActionType(STATE_NAME, 'GET_HORSE_REPORTS');
export type resetHorseParantageReportActions = MakeResetCommunicationActions<
  typeof resetHorseParantageReportActionTypes
>;

export type HorseActions =
  | getHorseActions
  | createHorseActions
  | updateHorseActions
  | deleteHorseActions
  | resetHorseActions
  | updateHorseProfileTypeActions
  | resetHorseProfileTypeActions
  | getHorseAbilitiesActions
  | getHorseParantageReportActions
  | getHorseLastOrderActions
  | searchHorseActions
  | resetSearchHorseActions
  | getHorseOwnerActions
  | getOwnerHorsesActions
  | resetHorseParantageReportActions
  | updateHorseAncsetryProfileTypeActions
  | resetHorseAncestryProfileTypeActions
  | updateHorseParentageProfileTypeActions
  | resetHorseParentageProfileTypeActions;
