import React, {memo} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {IUserHorseOrder} from 'Horse/models/IUserHorse';
import {breakpoints} from 'Common/constants/Breakpoints';
import {mediumMdSmBreakpoint} from 'UserProfile/components/TabsData/common/constants';
import OrderOverview from 'UserProfile/components/TabsData/common/OrderOverview';
import {ContainerBase, MissingOrderContainer} from './styled';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import {VisitorType} from 'Common/constants/VisitorType';
import {AnimalType} from 'Common/constants/AnimalType';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';

const ExistingOrderContainer = styled(ContainerBase)`
  margin-bottom: 16px;
  @media ${breakpoints.sm} {
    width: 60%;
  }

  @media ${mediumMdSmBreakpoint} {
    width: 168px;
    align-items: center;
    margin-top: 0;
  }
`;

const OrderButton = styled(LinkButton)`
  width: 100%;
  min-width: unset;

  @media ${breakpoints.sm} {
    width: 168px;
  }
`;

const ExistingOrderButton = styled(OrderButton)`
  margin-top: 8px;

  @media ${breakpoints.sm} {
    margin-top: 15px;
  }

  @media ${mediumMdSmBreakpoint} {
    align-self: center;
  }
`;

const OrderWrapper = styled.div`
  justify-content: center;

  @media ${breakpoints.sm} {
    justify-content: flex-start;
  }

  @media ${mediumMdSmBreakpoint} {
    justify-content: center;
  }
`;

const MissingOrder = styled(MissingOrderContainer)`
  margin-bottom: 16px;
`;

interface IProps {
  horseId: number;
  order: IUserHorseOrder | null;
  visitorType: VisitorType;
  animalType?: AnimalType;
}

const ALPHA_DOG_DNA_URL = 'https://www.alphadogdna.com/';

function HorseOrderButtons({horseId, order, visitorType, animalType}: IProps) {
  const handleDogOrderClick = () => {
    window.open(ALPHA_DOG_DNA_URL, '_blank');
  };

  const orderPath = `${PREFIX_VISITOR_TYPE[visitorType]}/order-tests/${horseId}`;

  if (order) {
    return (
      <ExistingOrderContainer className="d-flex flex-column h-100 justify-content-start">
        <OrderWrapper className="d-flex w-100">
          <OrderOverview order={order} />
        </OrderWrapper>
        {animalType === AnimalType.Dog ? (
          <ExistingOrderButton size="small" variant="outlined" onClick={handleDogOrderClick}>
            Order DNA Test
          </ExistingOrderButton>
        ) : (
          <ExistingOrderButton size="small" variant="outlined" to={orderPath}>
            Order Testing
          </ExistingOrderButton>
        )}
      </ExistingOrderContainer>
    );
  }

  return (
    <MissingOrder className="d-flex w-100">
      {animalType === AnimalType.Dog ? (
        <OrderButton size="small" onClick={handleDogOrderClick}>
          Order DNA Test
        </OrderButton>
      ) : (
        <OrderButton size="small" to={orderPath}>
          Order Testing
        </OrderButton>
      )}
    </MissingOrder>
  );
}

export default memo(HorseOrderButtons);
