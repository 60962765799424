import React, {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'UserProfile/store/userPrivacySettings/index';
import {actions as horseActions, selectors as horseSelectors} from 'Horse/store/horse';
import {UserPrivacySettingsModule} from 'UserProfile/store/userPrivacySettings/userPrivacySettingsModule';
import Loading from 'Loading/components/Loading';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import PersonalPrivacySettings from './parts/PersonalPrivacySettings';
import UserHorsesPrivacySettings from './parts/UserHorsesPrivacySettings';
import {SettingsTitle} from '../styled';
import {SettingType} from 'UserProfile/services/UserService';
import {HorseModule} from 'Horse/store/horse/horseModule';
import {ProfileType} from 'Common/constants/ProfileType';

const Root = styled.div`
  max-width: 800px;
`;

interface IExternalProps {
  onSuccess?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = OuterProps;

const PrivacySetting = (props: AllProps) => {
  const {
    privacySettingsLoading,
    privacySettingsUpdating,
    onSuccess,
    getPrivacySettings,
    privacySettings,
    updatePrivacySettings,
    ancestryProfileTypeUpdating,
    diagnosticsProfileTypeUpdating,
    parentageProfileTypeUpdating,
    updateAncestryProfileType,
    updateProfileType,
    updateParentageProfileType,
  } = props;
  useOnSuccessCommunication(privacySettingsUpdating, () => onSuccess && onSuccess());

  const errorInfo = privacySettingsLoading.error || privacySettingsUpdating.error;
  const isUserPrivacyRequesting = [privacySettingsLoading, privacySettingsUpdating].some(
    (value) => value?.isRequesting
  );
  const isHorsePrivacyRequesting = [
    privacySettingsLoading,
    ancestryProfileTypeUpdating,
    parentageProfileTypeUpdating,
    diagnosticsProfileTypeUpdating,
  ].some((value) => value?.isRequesting);

  useEffect(() => {
    getPrivacySettings();
  }, [getPrivacySettings]);

  const onChange = useCallback(
    (isPublic: boolean, settings: SettingType) => {
      updatePrivacySettings(isPublic, settings);
    },
    [updatePrivacySettings]
  );

  const onUpdateDiagnosticsProfileType = useCallback(
    (horseId: number, isPublic: boolean) => {
      updateProfileType({
        animalId: horseId,
        profileType: isPublic ? ProfileType.Public : ProfileType.Private,
      });
    },
    [updateProfileType]
  );

  const onUpdateAncestryProfileType = useCallback(
    (horseId: number, isPublic: boolean) => {
      // setIsTriggeredUpdateAncestryProfileType(true);
      updateAncestryProfileType({
        animalId: horseId,
        profileType: isPublic ? ProfileType.Public : ProfileType.Private,
      });
    },
    [updateAncestryProfileType]
  );

  const onUpdateParentageProfileType = useCallback(
    (horseId: number, isPublic: boolean) => {
      updateParentageProfileType({
        animalId: horseId,
        profileType: isPublic ? ProfileType.Public : ProfileType.Private,
      });
    },
    [updateParentageProfileType]
  );

  return (
    <Root>
      <SettingsTitle>Visibility and privacy settings</SettingsTitle>

      <div className="d-flex flex-column justify-content-center">
        <div className="position-relative">
          {isUserPrivacyRequesting && <Loading />}
          {privacySettings && (
            <PersonalPrivacySettings
              title="Contact Information"
              personalPrivacySettings={{
                isPublicEmail: privacySettings.isPublicEmail,
                isPublicPhone: privacySettings.isPublicPhone,
                isPublicLocation: privacySettings.isPublicLocation,
              }}
              onSuccess={onChange}
            />
          )}
        </div>

        <div className="position-relative">
          {isHorsePrivacyRequesting && <Loading />}
          {privacySettings?.horses && (
            <UserHorsesPrivacySettings
              title="My Horses"
              horses={privacySettings.horses}
              onUpdateAncestryProfileType={onUpdateAncestryProfileType}
              onUpdateDiagnosticsProfileType={onUpdateDiagnosticsProfileType}
              onUpdateParentageProfileType={onUpdateParentageProfileType}
            />
          )}
        </div>

        {errorInfo && <ErrorMessage>{getCommonErrors(errorInfo)}</ErrorMessage>}
      </div>
    </Root>
  );
};

const mapStateToProps = (state: IAppState) => ({
  privacySettings: selectors.selectUserPrivacySettings(state),
  privacySettingsLoading: selectors.selectCommunication(state, 'userPrivacySettingsLoading'),
  privacySettingsUpdating: selectors.selectCommunication(state, 'userPrivacySettingsUpdating'),
  diagnosticsProfileTypeUpdating: horseSelectors.selectCommunication(state, 'diagnosticsProfileTypeUpdating'),
  ancestryProfileTypeUpdating: horseSelectors.selectCommunication(state, 'ancestryProfileTypeUpdating'),
  parentageProfileTypeUpdating: horseSelectors.selectCommunication(state, 'parentageProfileTypeUpdating'),
});

const mapDispatchToProps = {
  getPrivacySettings: actions.getUserPrivacySettings,
  updatePrivacySettings: actions.updateUserPrivacySettings,
  updateProfileType: horseActions.updateHorseProfileType,
  updateAncestryProfileType: horseActions.updateHorseAncestryProfileType,
  updateParentageProfileType: horseActions.updateHorseParentageProfileType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(PrivacySetting));

export default withDynamicModules(Connected, [UserPrivacySettingsModule, HorseModule]);
