import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {DynamicModuleLoader} from 'redux-dynamic-modules';

import ColorPalette from 'Common/constants/ColorPalette';
import UserHorse from 'Shared/components/UserProfile/parts/UserHorsesTab/UserHorse/UserHorse';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {Pagination} from 'Common/components/Controls/index';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import HorseProfileForm from 'HorseProfile/components/HorseProfileForm/HorseProfileForm';
import {FormType} from 'Common/constants/FormType';
import Typography from 'Common/constants/Typography';
import {IUserProfileSectionProps} from 'Shared/models/IUserProfileSectionProps';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useSearchBar} from 'Common/helpers/hooks/useSearchBar';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IDispatch, IState, userHorsesVisitorDistributor} from './userDistributor';
import {associationHorsesVisitorDistributor} from './associationUserDistributor';
import {UserProfileType} from 'Shared/models/UserProfileType';
import {PREFIX_USER_PROFILE_TYPE} from 'Common/components/Navigation';
import {IHorse} from 'Horse/models/IHorse';

const UNKNOWN_ERROR = 'Error on loading horse';

const Horses = styled.div`
  margin-top: 16px;
`;

const ErrorMessage = styled.div`
  margin-top: 16px;
  color: ${ColorPalette.red4};
`;

const AddHorseButton = styled(PrimaryButton)`
  width: 160px;
  margin-bottom: 24px;
`;

const ButtonWrapper = styled.div`
  padding: 0 32px 0 24px;
  justify-content: center;

  @media ${breakpoints.sm} {
    justify-content: start;
  }
`;

const EmptyText = styled.div`
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size23};
  line-height: 32px;
  text-align: center;
`;

type AllProps = IUserProfileSectionProps & IState & IDispatch;

function UserHorses(props: AllProps) {
  const {horses, horsesLoading, getHorses, pagination, userId, isCurrentUser, visitorType, userProfileType} = props;
  const isGeneralUser = userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.User];

  console.log('UserHorses render:', {
    horseCount: horses?.length,
    horses: horses?.map((h) => ({
      id: h.id,
      name: h.name,
      animalType: h.animalType,
    })),
  });

  const getHorseList = useCallback(
    (params: IRequestParams) => {
      getHorses(params, userId, isGeneralUser);
    },
    [getHorses, isGeneralUser, userId]
  );

  const {searchBar, reloadItems, setRequestParams, params} = useSearchBar({
    getItems: getHorseList,
    searchBarPlaceholder: 'Search for animals by name',
  });

  const handlePageSelect = useCallback(
    (currentPage: number) => setRequestParams({...params, currentPage}),
    [setRequestParams, params]
  );

  const [isAddHorseOpen, setIsAddHorseOpen] = useState(false);
  const [editHorseId, setEditHorseId] = useState<number | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalProps, setEditModalProps] = useState<any>(null);

  const openModalNewHorse = React.useCallback(() => setIsAddHorseOpen(true), []);
  const closeModalNewHorse = React.useCallback(() => setIsAddHorseOpen(false), []);

  const onSuccessCreateHorse = React.useCallback(() => {
    closeModalNewHorse();
    reloadItems();
  }, [closeModalNewHorse, reloadItems]);

  const isLoading = horsesLoading.isRequesting;

  const error = !!horsesLoading.error && (getCommonErrors(horsesLoading.error) || UNKNOWN_ERROR);

  function renderEmptyPage() {
    return (
      <div className="h-100 d-flex flex-column justify-content-center align-items-center">
        <EmptyText>You have no animals yet.</EmptyText>
        <EmptyText>To add some press "Add a new animal" above.</EmptyText>
      </div>
    );
  }

  const handleEdit = useCallback((horse: IHorse) => {
    setEditHorseId(horse.id);
    setIsEditModalOpen(true);
    setEditModalProps({
      type: FormType.edit,
      horse: {
        ...horse,
        animalType: horse.animalType,
      },
    });
  }, []);

  return (
    <div className="flex-grow-1 d-flex flex-column h-100 position-relative">
      <ModalWindow isOpen={isAddHorseOpen} onClose={closeModalNewHorse}>
        <HorseProfileForm type={FormType.create} onSuccess={onSuccessCreateHorse} isAdmin={false} />
      </ModalWindow>

      <ModalWindow isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
        <HorseProfileForm
          type={FormType.edit}
          horse={editModalProps?.horse}
          onSuccess={() => {
            setIsEditModalOpen(false);
            reloadItems();
          }}
          isAdmin={false}
          animalType={editModalProps?.horse?.animalType}
        />
      </ModalWindow>

      {isCurrentUser && (
        <ButtonWrapper className="d-flex w-100 flex-column">
          <AddHorseButton
            className="d-flex align-self-start"
            variant="outlined"
            size="small"
            onClick={openModalNewHorse}
          >
            + Add a new animal
          </AddHorseButton>
          {searchBar}
        </ButtonWrapper>
      )}
      {(() => {
        if (isLoading) {
          return <Loading />;
        }

        if (error) {
          return <ErrorMessage>{`Error on loading horses: ${error}`}</ErrorMessage>;
        }

        if (horses.length < 1) {
          return renderEmptyPage();
        }

        return (
          <>
            <Horses>
              {horses.map((horse, i) => {
                console.log('Rendering horse:', {
                  id: horse.id,
                  name: horse.name,
                  animalType: horse.animalType,
                });
                return (
                  <UserHorse
                    key={i}
                    horse={horse}
                    onSuccess={reloadItems}
                    isOwnHorse={isCurrentUser}
                    visitorType={visitorType}
                  />
                );
              })}
            </Horses>
            <Pagination pagination={pagination} onPageSelect={handlePageSelect} />
          </>
        );
      })()}
    </div>
  );
}

const mapStateToProps = (state: IAppState, externalProps: IUserProfileSectionProps) => {
  const {userProfileType, visitorType} = externalProps;

  return userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.User] ||
    userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.AssociationOwner]
    ? userHorsesVisitorDistributor[visitorType!].state
    : associationHorsesVisitorDistributor[visitorType!].state;
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, any>,
  externalProps: IUserProfileSectionProps
) => {
  const {visitorType, userProfileType} = externalProps;

  return userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.User] ||
    userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.AssociationOwner]
    ? bindActionCreators(userHorsesVisitorDistributor[visitorType!].dispatch as any, dispatch)
    : bindActionCreators(associationHorsesVisitorDistributor[visitorType!].dispatch as any, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(UserHorses)) as unknown as (props: IUserProfileSectionProps) => JSX.Element;
const Exported = (externalProps: IUserProfileSectionProps) => {
  const {visitorType, userProfileType} = externalProps;

  const modules =
    userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.User] ||
    userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.AssociationOwner]
      ? userHorsesVisitorDistributor[visitorType!].modules || []
      : associationHorsesVisitorDistributor[visitorType!].modules || [];

  return (
    <DynamicModuleLoader modules={[modules]}>
      <Connected {...externalProps} visitorType={externalProps.visitorType!} />
    </DynamicModuleLoader>
  );
};

export default Exported;
