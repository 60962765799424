import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IUserHorse, IUserHorseOrder, IHorseReport} from 'Horse/models/IUserHorse';
import {IServerHorse, IServerUserHorse, IServerUserHorseOrder} from './types/horse';
import {convertHorseToRequest, convertServerUserHorse} from './converters/horse';
import {Gender} from 'Common/constants/Gender';
import IFoundHorse from 'Horse/models/IFoundHorse';
import {IHorseRequestParams} from 'Horse/models/IHorseRequest';
import {IHorseProfileTypeRequest} from 'Horse/store/horse';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {IShortAbility} from 'Dictionaries/models/IAbilities';
import {ProfileType} from 'Common/constants/ProfileType';
import {Nullable} from 'Common/types';
import {convertServerHorseLastOrderToClient} from 'Horse/services/converters/horseLastOrder';
import {IHorseProfile} from 'Horse/models/IHorseProfile';
import {isAdminVisitor} from 'Common/helpers/isAdminVisitor';
import {IRequestParams} from 'Common/models/IRequestParams';
import {getApiByVisitorType} from 'Common/helpers/getApiByVisitorType';
import {VisitorType} from 'Common/constants/VisitorType';
import {IHorseOwner} from 'Horse/models/IHorseOwner';
import {IOwnerHorse} from 'Shared/models/IOwnerHorse';
import {withAvatar} from 'Common/helpers/withAvatar';
import {IServerHorseOwner} from './types/owner';
import {withUserName} from 'Common/helpers/withUserName';
import {withBucketBaseUrl} from 'Common/helpers/withBucketBaseUrl';

const getHorses = async (params: IRequestParams, userId: number): Promise<IListResponse<IUserHorse>> => {
  const response = await axiosWrapper.get<IListResponse<IServerUserHorse>>(
    isAdminVisitor() ? `/UserProfileAnimalsAdmin` : `/UserProfileAnimals`,
    {
      params: {...params, userId},
    }
  );
  return {...response.data, data: response.data.data.map(convertServerUserHorse)};
};

const getHorse = async (id: number): Promise<Nullable<IHorseProfile>> => {
  const response = await axiosWrapper.get<IObjectResponse<Nullable<IServerHorse>>>(
    (isAdminVisitor() ? `/AnimalProfileAdmin` : `/Animals`) + `/${id}`
  );
  return response.data.data && withAvatar(withBirthDate(response.data.data));
};

const createHorse = async (params: IHorseRequestParams): Promise<number> => {
  const response = await axiosWrapper.post<IObjectResponse<number>>(`/AnimalsCreate/`, convertHorseToRequest(params));
  return response.data.data;
};

const updateHorse = async (params: IHorseRequestParams): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<IServerHorse>>(`/AnimalsUpdate/`, convertHorseToRequest(params));
};

const deleteHorse = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<IServerHorse>>(`/AnimalsArchive/${id}`);
};

const searchHorseByGenderAndName = async (name: string, gender?: Gender): Promise<IFoundHorse[]> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/HorseListSearch`,
    [VisitorType.AssociationEmployee]: `/AnimalListSearchAssociation`,
  });

  const response = await axiosWrapper.get<IObjectResponse<IFoundHorse[] | null>>(api, {params: {name, gender}});

  return response.data.data?.map(withBirthDate).map(withAvatar) || [];
};

const updateHorseProfileType = async (request: IHorseProfileTypeRequest): Promise<ProfileType> => {
  const response = await axiosWrapper.patch<IObjectResponse<ProfileType>>(`/AnimalProfileTypes`, {
    animalId: request.animalId,
    profileType: request.profileType,
  });

  return response.data.data;
};

const updateHorseAncestryProfileType = async (request: IHorseProfileTypeRequest): Promise<ProfileType> => {
  const response = await axiosWrapper.patch<IObjectResponse<ProfileType>>(`/AnimalAncestryProfileTypes`, {
    animalId: request.animalId,
    profileType: request.profileType,
  });

  return response.data.data;
};

const updateHorseParentageProfileType = async (request: IHorseProfileTypeRequest): Promise<ProfileType> => {
  const response = await axiosWrapper.patch<IObjectResponse<ProfileType>>(`/AnimalParentageProfileTypes`, {
    animalId: request.animalId,
    profileType: request.profileType,
  });

  return response.data.data;
};

const getHorseAbilities = async (animalId: number): Promise<IListResponse<IShortAbility>> => {
  const response = await axiosWrapper.get<IListResponse<IShortAbility>>(
    (isAdminVisitor() ? `/AnimalAbilitiesAdmin` : `/AnimalAbilities`) + `/${animalId}`
  );
  return response.data;
};

const getHorseParantageReport = async (animalId: number): Promise<IHorseReport> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AnimalParentageReport`,
    [VisitorType.Admin]: `/AnimalParentageReportAdmin`,
  });

  const response = await axiosWrapper.get<IObjectResponse<IHorseReport>>(`${api}/${animalId}`);
  return withBucketBaseUrl(response.data.data);
};

const getHorseLastOrder = async (animalId: number): Promise<IUserHorseOrder> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerUserHorseOrder>>(
    (isAdminVisitor() ? `/AnimalOrdersAdmin` : `/AnimalOrders`) + `/Last/${animalId}`
  );
  return convertServerHorseLastOrderToClient(response.data.data);
};

const searchHorseById = async (animalId: number): Promise<IFoundHorse> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AnimalListItemSearch`,
    [VisitorType.AssociationEmployee]: `/AnimalListItemSearchAssociation`,
  });

  const response = await axiosWrapper.get<IObjectResponse<IFoundHorse>>(`${api}/${animalId}`);

  return withAvatar(withBirthDate(response.data.data));
};

const getHorseOwner = async (animalId: number): Promise<IHorseOwner> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AnimalOwner`,
    [VisitorType.Admin]: `/AnimalOwnerAdmin`,
  });

  const response = await axiosWrapper.get<IObjectResponse<IServerHorseOwner>>(`${api}/${animalId}`);
  return withUserName(withAvatar(response.data.data));
};

const getOwnerHorses = async (animalId: number): Promise<IListResponse<IOwnerHorse>> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AnimalProfileAnimals`,
    [VisitorType.Admin]: `/AnimalProfileAnimalsAdmin`,
  });

  const response = await axiosWrapper.get<IListResponse<IOwnerHorse>>(`${api}/${animalId}`);
  return {...response.data, data: response.data.data.map(withBirthDate)};
};

export default {
  getHorse,
  getHorses,
  createHorse,
  updateHorse,
  deleteHorse,
  searchHorseByGenderAndName,
  updateHorseProfileType,
  updateHorseAncestryProfileType,
  updateHorseParentageProfileType,
  getHorseAbilities,
  getHorseParantageReport,
  getHorseLastOrder,
  searchHorseById,
  getHorseOwner,
  getOwnerHorses,
};
