import React, {memo, useCallback, useEffect, useState} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';

import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {FieldLabel, FieldValue} from 'Admin/common/styled/StyledComponents';
import UserSearchField from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearchField';
import {initialValue, validationSchema} from './validation';
import {IChangeHorseOwner} from 'Admin/AdminDashboard/models/Horse/IChangeHorseOwner';
import {CheckboxField} from 'Common/components/FormFields/index';
import withHorseTransferActions, {
  IWithHorseTransferActionsProps,
} from 'Admin/AdminDashboard/helpers/withHorseTransferActions';
import UserDataService from 'Admin/AdminDashboard/services/UserDataService';
import {IUserOption} from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearch';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import ColorPalette from 'Common/constants/ColorPalette';

const defaultUserOptionProps: IUserOption = {
  value: 0,
  label: '',
  lastName: '',
  email: '',
  id: 0,
  avatar: null,
  firstName: '',
};

const Root = styled.div`
  margin-right: 24px;
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 15px;
`;

const Field = styled.div`
  margin-bottom: 16px;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${ColorPalette.gray10};
  margin-bottom: 16px;
`;

interface IExternalProps {
  transferId: number;
  onSuccess(): void;
}

type OuterProps = IWithHorseTransferActionsProps & IExternalProps;

type AllProps = FormikProps<IChangeHorseOwner> & OuterProps;

function AcceptTransferForm(props: AllProps) {
  const {isSubmitting, setErrors, setFieldValue, setFieldTouched, isValid} = props;
  const {
    transferId,
    horseOwnerChanging,
    getHorseTransferDetails,
    horseTransferDetails,
    horseTransferDetailsLoading,
    resetHorseTransferDetails,
    onSuccess,
  } = props;

  const errorInfo = horseOwnerChanging.error || horseTransferDetailsLoading.error;
  const isRequesting = horseOwnerChanging.isRequesting || horseTransferDetailsLoading.isRequesting;

  const [foundUser, setFoundUser] = useState<IUserOption>();

  useEffect(() => {
    getHorseTransferDetails(transferId);

    return () => resetHorseTransferDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async (email: string) => {
      try {
        const users = await UserDataService.findUser(email);

        if (users.length === 1) {
          const findUser = users[0];
          setFieldValue('newOwnerId', findUser.id);

          setFoundUser({
            ...defaultUserOptionProps,
            avatar: findUser.avatar,
            id: findUser.id || 0,
            value: findUser.id || 0,
            firstName: findUser.firstName,
            lastName: findUser.lastName,
            email: findUser.email,
          });
        } else {
          setFoundUser({
            ...defaultUserOptionProps,
          });
          setFieldTouched('newOwnerId', true);
        }
      } catch (e) {
        console.error(e);
        setFoundUser({
          ...defaultUserOptionProps,
        });
      }
    };

    if (horseTransferDetails) {
      fetch(horseTransferDetails.providedEmail);
    }
  }, [horseTransferDetails, setFieldTouched, setFieldValue]);

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(horseOwnerChanging, onSuccess);
  useOnErrorCommunication(horseOwnerChanging, onError);
  useCommunicationToToast(horseOwnerChanging, 'Horse owner has been changed');

  const isDisabled = !isValid || foundUser?.id === 0;

  return (
    <>
      <ModalWindowHeader>Accept transfer request</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <Root>
          <ModalWindowFormContent scrollable={false}>
            {isRequesting && <Loading />}

            <FormControlContainer title="Transfer details (provided by user)">
              <div className="row">
                <Field className="col-6">
                  <FieldLabel>Horse</FieldLabel>
                  <FieldValue>{horseTransferDetails?.horse.name}</FieldValue>
                </Field>

                <Field className="col-6">
                  <FieldLabel>Current owner</FieldLabel>
                  <FieldValue>{horseTransferDetails?.owner.name}</FieldValue>
                </Field>
              </div>

              <div className="row">
                <Field className="col-6">
                  <FieldLabel>New owner name</FieldLabel>
                  <FieldValue>{horseTransferDetails?.providedName}</FieldValue>
                </Field>

                <Field className="col-6">
                  <FieldLabel>New owner email</FieldLabel>
                  <FieldValue>{horseTransferDetails?.providedEmail}</FieldValue>
                </Field>
              </div>
            </FormControlContainer>

            <Divider />

            {foundUser && (
              <UserSearchField
                name="newOwnerId"
                label="New owner"
                placeholder="New owner name or e-mail"
                isRequired={true}
                defaultValue={foundUser}
              />
            )}

            <CheckboxWrapper>
              <CheckboxField name="clearPreclinicalNotes" label="Clear preclinical notes" />
            </CheckboxWrapper>
          </ModalWindowFormContent>
        </Root>
        <ModalWindowFooter>
          <ModalWindowButton type="submit" isLoading={isSubmitting} disabled={isDisabled}>
            Save
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const AcceptTransferFormFormik = withFormik<OuterProps, IChangeHorseOwner>({
  mapPropsToValues: ({horseTransferDetails}) =>
    horseTransferDetails?.horse.id
      ? {animalId: horseTransferDetails.horse.id, newOwnerId: 0, clearPreclinicalNotes: true}
      : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    if (formikBag.props.horseTransferDetails?.horse.id) {
      await formikBag.props.changeHorseOwner({...values, animalId: formikBag.props.horseTransferDetails?.horse.id});
    }
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(AcceptTransferForm);

export default memo(withHorseTransferActions(AcceptTransferFormFormik));
