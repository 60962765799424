import {IVideo} from 'Video/models/IVideo';

export function avatarGalleryRequestConverter(animalId: number, file: File) {
  const form = new FormData();
  form.append('animalId', animalId.toString());
  form.append('file', file);

  return form;
}

export function imagesGalleryRequestConverter(animalId: number, files: File[]) {
  const form = new FormData();
  form.append('animalId', animalId.toString());
  files.forEach((file) => form.append('files', file));

  return form;
}

export function videoGalleryRequestConverter(animalId: number, video: IVideo) {
  return {animalId, url: video.url};
}
