import {IHorseTransferCreate} from 'Horse/models/IHorseTransfer';
import {IFormValues} from './validation';

export function convertFormValuesToRequest(values: IFormValues): IHorseTransferCreate {
  return {
    animalId: values.horseId,
    providedEmail: values.providedEmail,
    providedFirstName: values.providedFirstName,
    providedLastName: values.providedLastName,
    providedPhone: values.providedPhone,
    signature: values.signature,
  };
}
