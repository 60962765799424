import React from 'react';
import {useFormikContext} from 'formik';
import GalleryField from 'Common/components/FormFields/GalleryField';
import {IconName, IProps as IconProps} from 'Icon/components/Icon';
import {IFormValues} from '../HorseProfileFormInnerValidation';
import {AnimalType} from 'Common/constants/AnimalType';
import ImageUploaderField from 'Common/components/FormFields/ImageUploaderField';
import styled from 'styled-components';

interface IHorseImagesProps {
  animalType?: AnimalType;
}
const Avatar = styled.div`
  margin-right: 16px;
`;

const galleryVideoIconProps: IconProps = {name: IconName.InsertVideo, size: 48};

const HorseImages = ({animalType = AnimalType.Horse}: IHorseImagesProps) => {
  const galleryIconProps: IconProps = {name: IconName.InsertPhoto, size: 48};

  return (
    <div className="d-flex">
      <Avatar>
        <ImageUploaderField
          name="avatar"
          title="Add avatar"
          iconProps={galleryIconProps}
          memoized={true}
          showDownloadButton={true}
        />
      </Avatar>
      <GalleryField
        isEditMode={true}
        name="gallery"
        imageProps={{
          title: 'Add gallery photo',
          iconProps: galleryIconProps,
          showDownloadButton: true,
          showDeleteButton: true,
        }}
        videoProps={{title: 'Add a video', iconProps: galleryVideoIconProps, showDeleteButton: true}}
      />
    </div>
  );
};

export default HorseImages;
