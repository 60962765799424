import {memo} from 'react';
import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import {
  BreedType,
  DogBreedType,
  MAP_BREED_COLOR,
  MAP_BREED_NAME,
  MAP_DOG_BREED_COLOR,
} from 'OnlineReport/constants/BreedType';
import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import {sortBreeds} from 'OnlineReport/helpers/sortBreeds';

const LabelWrapper = styled.div`
  padding: 4px 0;
  width: 260px;
`;

const LabelColor = styled.div<{color: string}>`
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: ${({color}) => color};
`;

const Label = styled.span`
  padding-left: 16px;
  font-size: ${Typography.size.size16};
`;

interface IProps {
  population: IAncestryPopulation[];
  isShowPercentage?: boolean;
}

function CompositionPieLegend(props: IProps) {
  const {population, isShowPercentage} = props;

  const sortedPopulation = sortBreeds<IAncestryPopulation>(population);

  return (
    <div className="d-flex flex-column h-100 justify-content-center align-items-center">
      {sortedPopulation.map((item, i) => (
        <LabelWrapper key={i} className="d-flex">
          <LabelColor
            color={MAP_BREED_COLOR[item.type as BreedType] ?? MAP_DOG_BREED_COLOR[item.type as DogBreedType]}
          />
          <Label>
            {MAP_BREED_NAME[item.type as BreedType] ?? item.type}{' '}
            {isShowPercentage ? `(${item.percentage.toFixed(1)}%)` : ''}
          </Label>
        </LabelWrapper>
      ))}
    </div>
  );
}

export default memo(CompositionPieLegend);
