import React, {useCallback, useState} from 'react';
import {Nullable} from 'Common/types';
import WarningModalWindow, {ModalTypes} from 'Common/components/Modal/WarningModal';
import {IReleaseOrderHorse as IReleaseOrderHorseAdmin} from 'Admin/AdminDashboard/store/adminOrders/types';
import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {IReleaseOrderHorse} from 'Admin/AdminAssociations/store/adminOrders';
import useDistributorStrategy from 'Common/helpers/strategy/useDistributorStrategy';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import {FieldHint} from 'Common/components/StyledComponents/StyledComponents';
import Checkbox from 'Common/components/Controls/Checkbox';

const CONFIRM_RELEASE_ORDER_HORSE = 'Are you sure you want to release this order?';

type ReleaseOrderHorse = IReleaseOrderHorseAdmin | IReleaseOrderHorse;

export interface IReleaseActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(data: ReleaseOrderHorse): void;
  orderId?: Nullable<number>;
  animalId?: Nullable<number>;
}

export function useReleaseOrderHorseAction(props: IReleaseActionProps) {
  const {action, onSuccess, communication} = props;

  const [selectedOrderId, setSelectedOrderId] = useState<Nullable<number>>();
  const [selectedHorseId, setSelectedHorseId] = useState<Nullable<number>>();
  const [sendEmailChecked, setSendEmailChecked] = useState<boolean>();

  const orderId = props.orderId || selectedOrderId;
  const animalId = props.animalId || selectedHorseId;

  const {currentStrategy} = useDistributorStrategy();

  useOnSuccessCommunication(communication, () => {
    if (orderId === selectedOrderId && animalId === selectedHorseId) {
      onSuccess();
    }
  });
  useErrorCommunicationToToast(communication, undefined, 1);

  const [isReleaseOrderHorseModalOpen, setIsReleaseOrderHorseModalOpen] = useState(false);
  const openReleaseOrderHorseModal = useCallback(() => {
    setIsReleaseOrderHorseModalOpen(true);
    setSelectedOrderId(orderId);
    setSendEmailChecked(true);

    if (currentStrategy === SelectedStrategy.Admin) {
      setSelectedHorseId(animalId);
    }
    if (currentStrategy === SelectedStrategy.AdminAssociation) {
      setSelectedHorseId(null);
    }
  }, [animalId, orderId, currentStrategy]);

  const closeReleaseOrderHorseModal = useCallback(() => setIsReleaseOrderHorseModalOpen(false), []);
  const successReleaseOrderHorseModal = useCallback(async () => {
    closeReleaseOrderHorseModal();

    const animalIdParam = selectedHorseId && {animalId: selectedHorseId!};
    const params = {
      orderId: selectedOrderId!,
      ...animalIdParam,
      sendEmailToUser: sendEmailChecked,
    };

    action && (await action(params));
  }, [action, closeReleaseOrderHorseModal, selectedHorseId, selectedOrderId, sendEmailChecked]);

  const releaseOrderHorseModal = (
    <WarningModalWindow
      modalType={ModalTypes.Confirm}
      isOpen={isReleaseOrderHorseModalOpen}
      onClose={closeReleaseOrderHorseModal}
      onSuccess={successReleaseOrderHorseModal}
    >
      {CONFIRM_RELEASE_ORDER_HORSE}
      <div style={{margin: '12px 0 0'}}>
        <Checkbox
          onChange={() => setSendEmailChecked((sendEmailChecked) => !sendEmailChecked)}
          checked={sendEmailChecked}
          label="Send email"
          style={{marginBottom: 4}}
        />
        <FieldHint>Enabling this option, will allow you to notify the customer about releasing order</FieldHint>
      </div>
    </WarningModalWindow>
  );

  return {
    openReleaseOrderHorseModal,
    releaseOrderHorseModal,
  };
}
