import {IModule} from 'redux-dynamic-modules';

import {IAdminDogDiagnosticsUploadState} from './types';
import {dogDiagnosticsUploadReducer} from './reducer';

export interface IAdminDogDiagnosticsUploadModuleState {
  adminDogDiagnosticsUpload: IAdminDogDiagnosticsUploadState;
}

export const AdminDogDiagnosticsUploadModule: IModule<IAdminDogDiagnosticsUploadModuleState> = {
  id: 'adminDogDiagnosticsUpload',
  reducerMap: {
    adminDogDiagnosticsUpload: dogDiagnosticsUploadReducer,
  },
};
