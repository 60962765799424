import React, {memo, useEffect} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {useLocation, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {ThunkDispatch} from 'redux-thunk';
import {bindActionCreators} from 'redux';
import {DynamicModuleLoader} from 'redux-dynamic-modules';

import ColorPalette from 'Common/constants/ColorPalette';
import Tabs from 'Common/components/Tabs/Tabs';
import ProfileInfo from './parts/ProfileInfo/ProfileInfo';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {breakpoints} from 'Common/constants/Breakpoints';
import {ITab} from 'Common/components/Tabs/ITab';
import TabsContent from 'Common/components/Tabs/TabsContent';
import UserHorses from './parts/UserHorsesTab/UserHorses';
import {IUserProfileSectionProps} from 'Shared/models/IUserProfileSectionProps';
import {VisitorType} from 'Common/constants/VisitorType';
import {IDispatch, IState, userProfileVisitorDistributor} from './userDistributor';
import {associationUserProfileVisitorDistributor} from './associationUserDistributor';
import {UserProfileType} from 'Shared/models/UserProfileType';
import {PREFIX_USER_PROFILE_TYPE} from 'Common/components/Navigation';

const publicTabs: ITab<IUserProfileSectionProps>[] = [{section: 'horses', title: 'Animals', Component: UserHorses}];

const Root = styled.div`
  flex-direction: column;
  flex-grow: 1;
  background-color: ${ColorPalette.gray49};

  > :first-child {
    margin-bottom: 16px;
  }

  @media ${breakpoints.sm} {
    > :first-child {
      margin-bottom: 18px;
    }
  }

  @media ${breakpoints.lg} {
    flex-direction: row;
    > :first-child {
      margin-bottom: 0;
      margin-right: 32px;
    }
  }
`;

const ProfileInfoSection = styled.div`
  width: 100%;
  padding: 32px;
  background-color: ${ColorPalette.white0};

  @media ${breakpoints.lg} {
    width: 350px;
  }
`;

const ContentSection = styled.div`
  background-color: ${ColorPalette.white0};
`;

const Content = styled.div`
  padding: 40px 24px;
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${ColorPalette.red1};
`;

interface IExternalProps {
  visitorType: VisitorType;
  additionalTabs?: ITab<IUserProfileSectionProps>[];
  userProfileType: UserProfileType;
}

export type UserProfileRouteProps = {userProfileType: string};

type RouteParams = {id: string};

type AllProps = IExternalProps & IState & IDispatch;

function UserProfile(props: AllProps) {
  const {getUser, user, userLoading, additionalTabs, visitorType, currentUser, userProfileType} = props;

  const {id} = useParams<RouteParams>();
  const location = useLocation();

  const userId = Number(id);
  const isOwnProfile = currentUser?.id === userId;
  const isGeneralUser = userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.User];
  const isAssociationOwnerProfile = userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.AssociationOwner];

  useEffect(() => {
    if (!isOwnProfile) {
      getUser(userId, isGeneralUser, isAssociationOwnerProfile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = userLoading.isRequesting;

  const tabs = additionalTabs && additionalTabs.length > 0 ? publicTabs.concat(additionalTabs) : publicTabs;
  const userProfile = isOwnProfile ? currentUser : user;

  if (isLoading) {
    return <Loading />;
  }
  if (!userProfile) {
    return <ErrorMessage>Error on loading user data</ErrorMessage>;
  }

  // Get last 'word' of url ('horses'? ''?)
  const urlEnd = location.pathname.split('/').at(-1);
  // The basePath passed to TabsContent must be the url without the tab link (-'/horses' or -/'tests')
  const basePath = location.pathname
    .substring(0, location.pathname.lastIndexOf('/'))
    .concat(urlEnd !== '' && !isNaN(+urlEnd!) ? `/${urlEnd}` : '');
  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>

      <Root className="d-flex flex-grow-1 ">
        <ProfileInfoSection className="align-items-stretch flex-shrink-0">
          <ProfileInfo user={userProfile} isCurrentUser={isOwnProfile} />
        </ProfileInfoSection>
        <ContentSection className="flex-grow-1 flex-shrink-1 d-flex flex-column">
          <Tabs tabs={tabs} />
          <Content className="h-100">
            <TabsContent<IUserProfileSectionProps>
              basePath={basePath}
              tabs={tabs}
              isCurrentUser={isOwnProfile}
              userId={userId}
              visitorType={visitorType}
              userProfileType={userProfileType}
            />
          </Content>
        </ContentSection>
      </Root>
    </>
  );
}
const mapStateToProps = (state: IAppState, externalProps: IExternalProps) => {
  const {visitorType, userProfileType} = externalProps;

  return userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.User] ||
    userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.AssociationOwner]
    ? userProfileVisitorDistributor[visitorType!].state
    : associationUserProfileVisitorDistributor[visitorType!].state;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppState, undefined, any>, externalProps: IExternalProps) => {
  const {visitorType, userProfileType} = externalProps;

  return userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.User] ||
    userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.AssociationOwner]
    ? bindActionCreators(userProfileVisitorDistributor[visitorType!].dispatch as any, dispatch)
    : bindActionCreators(associationUserProfileVisitorDistributor[visitorType!].dispatch as any, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(UserProfile)) as unknown as (props: IExternalProps) => JSX.Element;
const Exported = (externalProps: IExternalProps) => {
  const {visitorType, userProfileType} = externalProps;

  const modules =
    userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.User] ||
    userProfileType === PREFIX_USER_PROFILE_TYPE[UserProfileType.AssociationOwner]
      ? userProfileVisitorDistributor[visitorType].modules || []
      : associationUserProfileVisitorDistributor[visitorType].modules || [];

  return (
    <DynamicModuleLoader modules={[modules]}>
      <Connected {...externalProps} visitorType={visitorType!} />
    </DynamicModuleLoader>
  );
};

export default Exported;
