import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IChangeHorseOwner} from '../models/Horse/IChangeHorseOwner';
import {IHorseTransferAdmin} from '../models/Transfer/IHorseTransferAdmin';
import {IHorseTransferDetailsAdmin} from '../models/Transfer/IHorseTransferDetailsAdmin';
import {convertHorseTranserDetailsAdminToClient, convertHorseTransersAdminToClient} from './converters/transfer';
import {
  IServerHorseTransferAdmin,
  IServerHorseTransferDetails,
  IServerHorseTransferRejectAdmin,
  IServerHorseTransferRequestAdmin,
} from './types/transfer';

const getHorseTransfers = async (params?: IRequestParams): Promise<IListResponse<IHorseTransferAdmin>> => {
  const response = await axiosWrapper.get<IListResponse<IServerHorseTransferAdmin>>(`/AnimalTransferRequestsAdmin`, {
    params,
  });
  return {...response.data, data: response.data.data.map(convertHorseTransersAdminToClient)};
};

const getHorseTransferDetails = async (id: number): Promise<IHorseTransferDetailsAdmin> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerHorseTransferDetails>>(
    `/AnimalTransferRequestDetailsAdmin/${id}`
  );
  return convertHorseTranserDetailsAdminToClient(response.data.data);
};

const rejectHorseTransfer = async (params: IServerHorseTransferRejectAdmin): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AnimalTransferRequestRejectedAdmin`, {...params});
};

const changeHorseOwner = async (params: IChangeHorseOwner): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AnimalTransferAdmin`, {...params});
};

const createHorseTransfer = async (params: IServerHorseTransferRequestAdmin): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AnimalTransferRequestsCreateAdmin`, {...params});
};

export default {
  getHorseTransfers,
  rejectHorseTransfer,
  changeHorseOwner,
  getHorseTransferDetails,
  createHorseTransfer,
};
