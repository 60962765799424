import React, {memo, useCallback, useEffect, useState} from 'react';
import {ActionMeta, MultiValue, PropsValue} from 'react-select';

import {Select} from 'Common/components/Controls';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import {IPackageOptionGroup} from './PackageSection';
import {IHorseTest} from 'Admin/AdminDashboard/models/Order/IHorseTest';

interface IProps {
  index: number;
  options: IPackageOptionGroup[];
  tests: IHorseTest[];
  onSelect(packages: (string | number)[]): void;
}

function PackageSelect(props: IProps) {
  const {options, onSelect, tests = [], index} = props;

  const [selectedPackages, setSelectedPackages] = useState<IDictionaryOptionType[]>([]);
  const [uniqueOptions, setUniqueOptions] = useState<IPackageOptionGroup[]>();

  const convertPackageToOption = useCallback(
    (packageOption: IDictionaryOptionType): IDictionaryOptionType => {
      const {value} = packageOption;

      return {
        ...packageOption,
        isInvisible: tests[index]?.packages?.find((test) => test === value) || false,
      };
    },
    [index, tests]
  );

  useEffect(() => {
    if (!options) return;

    const newOptions = [...options].map((x) => ({
      label: x.label,
      options: x.options.map(convertPackageToOption),
    }));
    setUniqueOptions(newOptions);
  }, [convertPackageToOption, options]);

  const onSelectOption = useCallback(
    (packageOption: PropsValue<IDictionaryOptionType>, action: ActionMeta<IDictionaryOptionType>) => {
      if ((action.action === 'select-option' || action.action === 'remove-value') && packageOption) {
        setSelectedPackages(packageOption as IDictionaryOptionType[]);
      }
    },
    []
  );

  return (
    <Select
      isRequired={true}
      isMulti={true}
      options={uniqueOptions}
      onChange={(newValue: MultiValue<IDictionaryOptionType>, actionMeta: ActionMeta<IDictionaryOptionType>) => {
        const newPackages = newValue as IDictionaryOptionType[];
        const addedPackages = newPackages.map((x) => x.value);

        onSelectOption(newValue, actionMeta);
        onSelect(addedPackages);
      }}
      value={selectedPackages}
      label="Packages"
    />
  );
}

export default memo(PackageSelect);
