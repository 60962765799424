import React, {memo} from 'react';

import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {DateTimeField, InputField, SelectField} from 'Common/components/FormFields';
import {IDiscipline} from 'Dictionaries/models/IDiscipline';
import {IBreed} from 'Dictionaries/models/IBreed';
import {IMarking} from 'Dictionaries/models/IMarking';
import {castToOption, getStringKeysOption} from 'Common/helpers/OptionHelper';
import {MergedGenders} from 'Common/constants/Gender';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import IndicatorField from 'Common/components/FormFields/IndicatorField';
import {Hint, MutableField} from 'Common/components/StyledComponents/StyledComponents';
import {IHorseColor} from 'Dictionaries/models/IHorseColor';
import HorseHeightField from 'Common/components/FormFields/HorseHeightField';
import {AnimalType} from '../../../../Common/constants/AnimalType';

interface IGeneralInformationProps {
  disciplines: Array<{
    id: string;
    name: string;
    animalType: AnimalType;
  }>;
  breeds: Array<{
    id: string;
    name: string;
    animalType: AnimalType;
  }>;
  colors: Array<{
    id: string;
    name: string;
    animalType: AnimalType;
  }>;
  markings: Array<{
    id: string;
    name: string;
    animalType: AnimalType;
  }>;
  animalType: AnimalType;
}

export const GeneralInformation = ({disciplines, breeds, colors, markings, animalType}: IGeneralInformationProps) => {
  const filteredDisciplines = disciplines?.filter((d) => d.animalType === animalType) || [];
  const filteredBreeds = breeds?.filter((b) => b.animalType === animalType) || [];
  const filteredColors = colors?.filter((c) => c.animalType === animalType) || [];
  const filteredMarkings = markings?.filter((m) => m.animalType === animalType) || [];

  console.log('GeneralInformation rendering:', {
    animalType,
    rawCounts: {
      disciplines: disciplines?.length,
      breeds: breeds?.length,
      colors: colors?.length,
      markings: markings?.length,
    },
    filteredCounts: {
      disciplines: filteredDisciplines.length,
      breeds: filteredBreeds.length,
      colors: filteredColors.length,
      markings: filteredMarkings.length,
    },
  });

  return (
    <>
      <MutableField width={50}>
        <InputField
          isRequired={true}
          name="name"
          type="text"
          label="Name"
          placeholder="Animal name"
          autoComplete="off"
          memoized={true}
        />
      </MutableField>

      <SelectField
        isRequired={true}
        name="gender"
        label="Sex"
        options={animalType && getStringKeysOption(MergedGenders[animalType])}
        memoized={true}
      />

      <SelectField
        isRequired={true}
        name="breeds"
        label="Breed"
        isMulti={true}
        options={filteredBreeds?.map((b) => ({value: b.id, label: b.name}))}
        isLoading={!breeds?.length}
      />

      <MutableField width={50}>
        <DateTimeField name="dateOfBirth" label="Date of birth" memoized={true} />
      </MutableField>

      <SelectField
        name="disciplines"
        label="Discipline"
        isMulti={true}
        options={filteredDisciplines?.map((d) => ({value: d.id, label: d.name}))}
        isLoading={!disciplines?.length}
      />

      {animalType === AnimalType.Horse && (
        <>
          <SelectField
            name="colors"
            label="Color"
            isMulti={true}
            options={filteredColors?.map((c) => ({value: c.id, label: c.name}))}
          />

          <SelectField
            name="markings"
            label="Markings"
            isMulti={true}
            options={filteredMarkings?.map((m) => ({value: m.id, label: m.name}))}
          />

          <HorseHeightField heightName="height" heightUnitName="heightUnit" />

          <FieldControlContainer label="Temperament" style={{marginTop: '10px'}}>
            <IndicatorField name="temperament" showBottomLabels={true} isResetable={true} />
          </FieldControlContainer>
        </>
      )}
    </>
  );
};

export default memo(GeneralInformation);
